import React from "react";
import { BaseLayout, ForgotPassword } from "../../components";

const ForgotPasswordPage = () => {
	return (
		<BaseLayout>
			<ForgotPassword />
		</BaseLayout>
	);
};

export default ForgotPasswordPage;
