export const apiEndpoints = {
    AUTH_URLS: {
        REFRESH_TOKEN: "/auth/refresh",
        SIGN_UP: {
            GENERATE_OTP: "/auth/generate-signup-otp",
            VERIFY_OTP: "/auth/verify-signup-otp",
            SIGN_UP_REQUEST: "/auth/signup",
            VALIDATE_EMAIL: "/auth/validate-email"
        },
        LOGIN: {
            GENERATE_OTP: "/auth/generate-login-otp",
            VERIFY_OTP: "/auth/verify-login-otp",
            WITH_PASSWORD: "/auth/password-login",
            WITH_OTP: "/auth/login"
        },
        FORGOT_PASSWORD: {
            GENERATE_OTP: "/auth/forgot-password",
            VERIFY_OTP: "/auth/verify-forgot-password-otp",
            RESET: "/auth/reset-password"
        },
        PROFILE: {
            DETAILS: "/user/user",
            CHANGE_PASSWORD: "/user/change-password",
            AVATARS: {
                GET_AVATARS_LIST: "user/profile-avatars",
            },
            BILLING_DETAILS: "user/billing-details"
        },
    },
    PRACTICE: {
        GET_TOPICS_LIST: "/practice/topics",
        GET_SUB_TOPICS_LIST: "/practice/subtopics",
        GET_DIFFICULTY_LEVELS: "/practice/difficulty-levels",
        GET_QUESTION_FORMATS: "/practice/question-formats",
        GET_INSTRUCTIONS: "/practice/instructions",
        SESSION: "/practice/prepare-session",
        GET_QUESTION: "/practice/question",
        END_SESSION: "/practice/end-session",
        SUBMIT_ANSWER: "/practice/submit-answer",
        SKIP_QUESTION: "/practice/skip-question",
        REPORT_ERROR: "/practice/report-error",
        PAUSE_SESSION: "/practice/pause-session",
        RESUME_SESSION: "/practice/resume-session",
        SESSION_QUESTION_STATUS: "/practice/session-question-status",
        GET_SESSION_STATUS: "/practice/session-status"
    },
    DASHBOARD: {
        GET_PRACTICE_SUMMARY: "/report/practice-summary",
        GET_TOP_SUGGESTIONS: "/report/top-suggestions",
        GET_CONCEPT_ANALYSIS: "/report/concept-analysis",
        GET_ABILITY_ANALYSIS: "/report/ability-analysis",
    },
    BOOKMARK: {
        GET_POST_DELETE: "/practice/bookmark",
        GET_BOOKMARKS_LIST: "/practice/bookmarks",
    },
    ANALYSE: {
        GET_SESSION_SUMMARY: "/report/analyse-session-summary",
        GET_TOPIC_SUMMARY: "/report/session-topic-summary",
        GET_SUBTOPIC_SUMMARY: "/report/session-subtopic-summary",
        GET_SUBTOPIC_ABILITY_SCORES: "/report/session-subtopic-ability-scores",
        GET_SESSION_CHART: "/report/session-concepts-chart",
        GET_SESSION_HISTORY: "/report/session-history",
        GET_REPORT_STATUS: "/report/poll-report-status",
        GET_SESSION_ABILITY_SCORES: "/report/session-ability-scores",
        GET_TOPIC_WISE_ANALYSIS: "report/topic-wise-analysis",
        GET_SUBTOPIC_WISE_ANALYSIS: "report/subtopic-wise-analysis",
        GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS: "/report/topic-subtopic-ability-analysis",
    },
    LANDING_SITE: {
        POST_SEND_MESSAGE: "/support/send-message",
    },
    PAYMENT: {
        POST_GENERATE_BILLING: "user/generate-billing",
        POST_PAYMENT_INITIATE: "/user/initiate-payment",
        POST_PAYMENT_VALIDATION: "/user/payment-validation/"
    }
}