import { postGenerateBilling } from '../../api/apiCalls';
import { PAYMENT_CONTEXT as context } from '../context';
import { ShowToast } from './ToastActions';

// POST Generate Billing Actions
const postGenerateBillingRequest = () => ({
    type: context.POST_GENERATE_BILLING_REQUEST,
})

const postGenerateBillingError = error => ({
    type: context.POST_GENERATE_BILLING_ERROR,
    postGenerateBillingError: error
})

const postGenerateBillingSuccess = (data) => ({
    type: context.POST_GENERATE_BILLING_SUCCESS,
    billingDetails: data
})

export const postApiGenerateBilling = (data) => {
    return (dispatch) => {
        dispatch(postGenerateBillingRequest())
        postGenerateBilling(data).then((res) => {
            if (res?.status === 200) {
                dispatch(postGenerateBillingSuccess(res?.data?.data))
                // dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(postGenerateBillingError(res))
            }
        })
    }
}