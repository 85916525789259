export const AUTH_CONTEXT = {
    REST_AUTH_REDUCER: "REST_AUTH_REDUCER",

    OTP_GENERATE_REQUEST: 'OTP_GENERATE_REQUEST',
    OTP_GENERATE_ERROR: 'OTP_GENERATE_ERROR',
    OTP_GENERATE_SUCCESS: 'OTP_GENERATE_SUCCESS',

    RESET_OTP_STATES: 'RESET_OTP_STATES',

    REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
    REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',

    SIGN_IN_REQUEST: 'SIGN_IN_REQUEST',
    SIGN_IN_ERROR: 'SIGN_IN_ERROR',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',

    FORGOT_PASSWORD_OTP_VERIFY_REQUEST: 'FORGOT_PASSWORD_OTP_VERIFY_REQUEST',
    FORGOT_PASSWORD_OTP_VERIFY_ERROR: 'FORGOT_PASSWORD_OTP_VERIFY_ERROR',
    FORGOT_PASSWORD_OTP_VERIFY_SUCCESS: 'FORGOT_PASSWORD_OTP_VERIFY_SUCCESS',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_ERROR: 'LOGOUT_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',


    PUT_PROFILE_REQUEST: 'PUT_PROFILE_REQUEST',
    PUT_PROFILE_ERROR: 'PUT_PROFILE_ERROR',
    PUT_PROFILE_SUCCESS: 'PUT_PROFILE_SUCCESS',

    PATCH_PASSWORD_REQUEST: 'PATCH_PASSWORD_REQUEST',
    PATCH_PASSWORD_ERROR: 'PATCH_PASSWORD_ERROR',
    PATCH_PASSWORD_SUCCESS: 'PATCH_PASSWORD_SUCCESS',

    GET_AVATARS_REQUEST: 'GET_AVATARS_REQUEST',
    GET_AVATARS_ERROR: 'GET_AVATARS_ERROR',
    GET_AVATARS_SUCCESS: 'GET_AVATARS_SUCCESS',

    POST_BILLING_DETAILS_REQUEST: 'POST_BILLING_DETAILS_REQUEST',
    POST_BILLING_DETAILS_ERROR: 'POST_BILLING_DETAILS_ERROR',
    POST_BILLING_DETAILS_SUCCESS: 'POST_BILLING_DETAILS_SUCCESS',

    UPDATE_PAYMENT_PLAN: 'UPDATE_PAYMENT_PLAN'
}

export const SIGNUP_CONTEXT = {
    UPDATE_SIGNUP_FORM: 'UPDATE_SIGNUP_FORM',
    RESET_SIGNUP_STATE: 'RESET_SIGNUP_STATE',
    RESET_OTP_STATES: 'RESET_OTP_STATES',

    OTP_GENERATE_REQUEST: 'OTP_GENERATE_REQUEST',
    OTP_GENERATE_ERROR: 'OTP_GENERATE_ERROR',
    OTP_GENERATE_SUCCESS: 'OTP_GENERATE_SUCCESS',

    OTP_VERIFY_REQUEST: 'OTP_VERIFY_REQUEST',
    OTP_VERIFY_ERROR: 'OTP_VERIFY_ERROR',
    OTP_VERIFY_SUCCESS: 'OTP_VERIFY_SUCCESS',

    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_ERROR: 'SIGN_UP_ERROR',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS'
}

export const UI_CONTEXT = {
    SET_HEADER1: 'SET_HEADER1',
    SELECT_TOPICS: 'SELECT_TOPICS',
    SELECT_SUBTOPICS: 'SELECT_SUBTOPICS',
    SELECT_DIFFICULTY: 'SELECT_DIFFICULTY',
    SELECT_QUESTION_FORMAT: 'SELECT_QUESTION_FORMAT',
    SHOW_HINT: 'SHOW_HINT',
    SHOW_SOLUTON: 'SHOW_SOLUTON',
    RESET_HINT_SOLUTION: 'RESET_HINT_SOLUTION',
    RESET_UI_REDUCER: 'RESET_UI_REDUCER'
}

export const TOAST_CONTEXT = {
    TOAST_SHOW: 'TOAST_SHOW',
    TOAST_HIDE: 'TOAST_HIDE'
}

export const PRACTICE_CONTEXT = {
    RESET_PRACTICE_REDUCER: 'RESET_PRACTICE_REDUCER',

    SESSION_IN_PROGRESS: 'SESSION_IN_PROGRESS',
    SESSION_NOT_FOUND: 'SESSION_NOT_FOUND',
    PAYMENT_REQUIRED: 'PAYMENT_REQUIRED',

    GET_TOPICS_LIST_REQUEST: 'GET_TOPICS_LIST_REQUEST',
    GET_TOPICS_LIST_ERROR: 'GET_TOPICS_LIST_ERROR',
    GET_TOPICS_LIST_SUCCESS: 'GET_TOPICS_LIST_SUCCESS',

    GET_SUB_TOPICS_LIST_REQUEST: 'GET_SUB_TOPICS_LIST_REQUEST',
    GET_SUB_TOPICS_LIST_ERROR: 'GET_SUB_TOPICS_LIST_ERROR',
    GET_SUB_TOPICS_LIST_SUCCESS: 'GET_SUB_TOPICS_LIST_SUCCESS',

    GET_DIFFICULTY_LEVELS_REQUEST: 'GET_DIFFICULTY_LEVELS_REQUEST',
    GET_DIFFICULTY_LEVELS_ERROR: 'GET_DIFFICULTY_LEVELS_ERROR',
    GET_DIFFICULTY_LEVELS_SUCCESS: 'GET_DIFFICULTY_LEVELS_SUCCESS',

    GET_QUESTION_FORMATS_REQUEST: 'GET_QUESTION_FORMATS_REQUEST',
    GET_QUESTION_FORMATS_ERROR: 'GET_QUESTION_FORMATS_ERROR',
    GET_QUESTION_FORMATS_SUCCESS: 'GET_QUESTION_FORMATS_SUCCESS',

    GET_INSTRUCTIONS_REQUEST: 'GET_INSTRUCTIONS_REQUEST',
    GET_INSTRUCTIONS_ERROR: 'GET_INSTRUCTIONS_ERROR',
    GET_INSTRUCTIONS_SUCCESS: 'GET_INSTRUCTIONS_SUCCESS',

    PREPARE_SESSION_REQUEST: 'PREPARE_SESSION_REQUEST',
    PREPARE_SESSION_ERROR: 'PREPARE_SESSION_ERROR',
    PREPARE_SESSION_SUCCESS: 'PREPARE_SESSION_SUCCESS',

    GET_QUESTION_REQUEST: 'GET_QUESTION_REQUEST',
    GET_QUESTION_ERROR: 'GET_QUESTION_ERROR',
    GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',

    END_SESSION_REQUEST: 'END_SESSION_REQUEST',
    END_SESSION_ERROR: 'END_SESSION_ERROR',
    END_SESSION_SUCCESS: 'END_SESSION_SUCCESS',

    SELECT_ANSWER: 'SELECT_ANSWER',

    SUBMIT_ANSWER_REQUEST: 'SUBMIT_ANSWER_REQUEST',
    SUBMIT_ANSWER_ERROR: 'SUBMIT_ANSWER_ERROR',
    SUBMIT_ANSWER_SUCCESS: 'SUBMIT_ANSWER_SUCCESS',

    SET_QUESTION_PROPERTIES: 'SET_QUESTION_PROPERTIES',
    SET_SESSION_STATUS: 'SET_SESSION_STATUS',
    SET_BOOKMARK_STATUS: 'SET_BOOKMARK_STATUS',

    SKIP_QUESTION_REQUEST: 'SKIP_QUESTION_REQUEST',
    SKIP_QUESTION_ERROR: 'SKIP_QUESTION_ERROR',
    SKIP_QUESTION_SUCCESS: 'SKIP_QUESTION_SUCCESS',

    REPORT_ERROR_REQUEST: 'REPORT_ERROR_REQUEST',
    REPORT_ERROR_ERROR: 'REPORT_ERROR_ERROR',
    REPORT_ERROR_SUCCESS: 'REPORT_ERROR_SUCCESS',

    PAUSE_SESSION_REQUEST: 'PAUSE_SESSION_REQUEST',
    PAUSE_SESSION_ERROR: 'PAUSE_SESSION_ERROR',
    PAUSE_SESSION_SUCCESS: 'PAUSE_SESSION_SUCCESS',

    RESUME_SESSION_REQUEST: 'RESUME_SESSION_REQUEST',
    RESUME_SESSION_ERROR: 'RESUME_SESSION_ERROR',
    RESUME_SESSION_SUCCESS: 'RESUME_SESSION_SUCCESS',

    SET_PAUSE_TIMER: "SET_PAUSE_TIMER",
    SET_PAUSE_TIME: "SET_PAUSE_TIME",
    SET_TIMER_DATE: "SET_TIMER_DATE",

    GET_SESSION_QUESTION_STATUS_REQUEST: "GET_SESSION_QUESTION_STATUS_REQUEST",
    GET_SESSION_QUESTION_STATUS_ERROR: "GET_SESSION_QUESTION_STATUS_ERROR",
    GET_SESSION_QUESTION_STATUS_SUCCESS: "GET_SESSION_QUESTION_STATUS_SUCCESS",

    UPDATE_SESSION_PROGRESS_MODAL_STATE: "UPDATE_SESSION_PROGRESS_MODAL_STATE",
    UPDATE_SESSION_ID: "UPDATE_SESSION_ID"
}

export const DASHBOARD_CONTEXT = {
    RESET_DASHBOARD_REDUCER: 'RESET_DASHBOARD_REDUCER',

    GET_PRACTICE_SUMMARY_REQUEST: 'GET_PRACTICE_SUMMARY_REQUEST',
    GET_PRACTICE_SUMMARY_ERROR: 'GET_PRACTICE_SUMMARY_ERROR',
    GET_PRACTICE_SUMMARY_SUCCESS: 'GET_PRACTICE_SUMMARY_SUCCESS',

    GET_TOP_SUGGESTIONS_REQUEST: 'GET_TOP_SUGGESTIONS_REQUEST',
    GET_TOP_SUGGESTIONS_ERROR: 'GET_TOP_SUGGESTIONS_ERROR',
    GET_TOP_SUGGESTIONS_SUCCESS: 'GET_TOP_SUGGESTIONS_SUCCESS',

    GET_CONCEPT_ANALYSIS_REQUEST: 'GET_CONCEPT_ANALYSIS_REQUEST',
    GET_CONCEPT_ANALYSIS_ERROR: 'GET_CONCEPT_ANALYSIS_ERROR',
    GET_CONCEPT_ANALYSIS_SUCCESS: 'GET_CONCEPT_ANALYSIS_SUCCESS',

    GET_ABILITY_ANALYSIS_REQUEST: 'GET_ABILITY_ANALYSIS_REQUEST',
    GET_ABILITY_ANALYSIS_ERROR: 'GET_ABILITY_ANALYSIS_ERROR',
    GET_ABILITY_ANALYSIS_SUCCESS: 'GET_ABILITY_ANALYSIS_SUCCESS',

    WELCOME_MODAL_CLOSED: 'WELCOME_MODAL_CLOSED'
}

export const BOOKMARK_CONTEXT = {
    RESET_BOOKMARK_REDUCER: 'RESET_BOOKMARK_REDUCER',

    POST_BOOKMARK_REQUEST: 'POST_BOOKMARK_REQUEST',
    POST_BOOKMARK_ERROR: 'POST_BOOKMARK_ERROR',
    POST_BOOKMARK_SUCCESS: 'POST_BOOKMARK_SUCCESS',

    DEL_BOOKMARK_REQUEST: 'DEL_BOOKMARK_REQUEST',
    DEL_BOOKMARK_ERROR: 'DEL_BOOKMARK_ERROR',
    DEL_BOOKMARK_SUCCESS: 'DEL_BOOKMARK_SUCCESS',

    GET_BOOKMARKS_LIST_REQUEST: 'GET_BOOKMARKS_LIST_REQUEST',
    GET_BOOKMARKS_LIST_ERROR: 'GET_BOOKMARKS_LIST_ERROR',
    GET_BOOKMARKS_LIST_SUCCESS: 'GET_BOOKMARKS_LIST_SUCCESS',

    GET_BOOKMARK_DETAIL_REQUEST: 'GET_BOOKMARK_DETAIL_REQUEST',
    GET_BOOKMARK_DETAIL_ERROR: 'GET_BOOKMARK_DETAIL_ERROR',
    GET_BOOKMARK_DETAIL_SUCCESS: 'GET_BOOKMARK_DETAIL_SUCCESS',
}

export const ANALYSE_CONTEXT = {
    RESET_ANALYSE_REDUCER: "RESET_ANALYSE_REDUCER",

    GET_SESSION_SUMMARY_REQUEST: "GET_SESSION_SUMMARY_REQUEST",
    GET_SESSION_SUMMARY_ERROR: "GET_SESSION_SUMMARY_ERROR",
    GET_SESSION_SUMMARY_SUCCESS: "GET_SESSION_SUMMARY_SUCCESS",

    GET_TOPIC_SUMMARY_REQUEST: "GET_TOPIC_SUMMARY_REQUEST",
    GET_TOPIC_SUMMARY_ERROR: "GET_TOPIC_SUMMARY_ERROR",
    GET_TOPIC_SUMMARY_SUCCESS: "GET_TOPIC_SUMMARY_SUCCESS",

    GET_SUBTOPIC_SUMMARY_REQUEST: "GET_SUBTOPIC_SUMMARY_REQUEST",
    GET_SUBTOPIC_SUMMARY_ERROR: "GET_SUBTOPIC_SUMMARY_ERROR",
    GET_SUBTOPIC_SUMMARY_SUCCESS: "GET_SUBTOPIC_SUMMARY_SUCCESS",

    GET_SESSION_SUBTOPIC_ABILITY_SCORES_REQUEST: "GET_SESSION_SUBTOPIC_ABILITY_SCORES_REQUEST",
    GET_SESSION_SUBTOPIC_ABILITY_SCORES_ERROR: "GET_SESSION_SUBTOPIC_ABILITY_SCORES_ERROR",
    GET_SESSION_SUBTOPIC_ABILITY_SCORES_SUCCESS: "GET_SESSION_SUBTOPIC_ABILITY_SCORES_SUCCESS",

    GET_SESSION_CHART_REQUEST: "GET_SESSION_CHART_REQUEST",
    GET_SESSION_CHART_ERROR: "GET_SESSION_CHART_ERROR",
    GET_SESSION_CHART_SUCCESS: "GET_SESSION_CHART_SUCCESS",

    GET_SESSION_HISTORY_REQUEST: "GET_SESSION_HISTORY_REQUEST",
    GET_SESSION_HISTORY_ERROR: "GET_SESSION_HISTORY_ERROR",
    GET_SESSION_HISTORY_SUCCESS: "GET_SESSION_HISTORY_SUCCESS",

    GET_ANALYSE_CHART_REQUEST: "GET_ANALYSE_CHART_REQUEST",
    GET_ANALYSE_CHART_ERROR: "GET_ANALYSE_CHART_ERROR",
    GET_ANALYSE_CHART_SUCCESS: "GET_ANALYSE_CHART_SUCCESS",

    GET_REPORT_STATUS_REQUEST: "GET_REPORT_STATUS_REQUEST",
    GET_REPORT_STATUS_ERROR: "GET_REPORT_STATUS_ERROR",
    GET_REPORT_STATUS_SUCCESS: "GET_REPORT_STATUS_SUCCESS",
    SET_REPORT_STATUS_SUCCESS: "SET_REPORT_STATUS_SUCCESS",

    GET_SESSION_ABILITY_SCORES_REQUEST: "GET_SESSION_ABILITY_SCORES_REQUEST",
    GET_SESSION_ABILITY_SCORES_ERROR: "GET_SESSION_ABILITY_SCORES_ERROR",
    GET_SESSION_ABILITY_SCORES_SUCCESS: "GET_SESSION_ABILITY_SCORES_SUCCESS",

    GET_TOPIC_WISE_ANALYSIS_REQUEST: "GET_TOPIC_WISE_ANALYSIS_REQUEST",
    GET_TOPIC_WISE_ANALYSIS_ERROR: "GET_TOPIC_WISE_ANALYSIS_ERROR",
    GET_TOPIC_WISE_ANALYSIS_SUCCESS: "GET_TOPIC_WISE_ANALYSIS_SUCCESS",

    GET_SUBTOPIC_WISE_ANALYSIS_REQUEST: "GET_SUBTOPIC_WISE_ANALYSIS_REQUEST",
    GET_SUBTOPIC_WISE_ANALYSIS_ERROR: "GET_SUBTOPIC_WISE_ANALYSIS_ERROR",
    GET_SUBTOPIC_WISE_ANALYSIS_SUCCESS: "GET_SUBTOPIC_WISE_ANALYSIS_SUCCESS",

    GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_REQUEST: "GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_REQUEST",
    GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_ERROR: "GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_ERROR",
    GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_SUCCESS: "GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_SUCCESS",
}

export const LANDING_SITE_CONTEXT = {
    POST_SEND_MESSAGE_REQUEST: 'POST_SEND_MESSAGE_REQUEST',
    POST_SEND_MESSAGE_ERROR: 'POST_SEND_MESSAGE_ERROR',
    POST_SEND_MESSAGE_SUCCESS: 'POST_SEND_MESSAGE_SUCCESS',
}

export const PAYMENT_CONTEXT = {
    POST_GENERATE_BILLING_REQUEST: 'POST_GENERATE_BILLING_REQUEST',
    POST_GENERATE_BILLING_ERROR: 'POST_GENERATE_BILLING_ERROR',
    POST_GENERATE_BILLING_SUCCESS: 'POST_GENERATE_BILLING_SUCCESS',
}

export const TIMER_CONTEXT = {
    SET_SESSION_TIME: "SET_SESSION_TIME",
}