import { Skeleton } from "@mui/material";

export const columnSkeletons = ({ height = 30, width = 100 }) => ({
    renderHeader: () => {
        return (
            <Skeleton height={height} width={width} />
        )
    },
    renderCell: () => {
        return (
            <Skeleton height={height} width={width} />
        )
    }
})

const useDataGridSkeletonHelper = ({ rows = 3, columns = 6, height = 30, width = 100 }) => {
    let loadingRows = [];
    let loadingColumns = [];

    Array(columns).fill().map((item, index) =>
        loadingColumns.push({
            field: index,
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            ...columnSkeletons(height, width)
        })
    )

    Array(rows).fill().map((item, index) =>
        loadingRows.push({ "id": index })
    )

    return ({ loadingRows, loadingColumns })
}

export default useDataGridSkeletonHelper;