import React from "react";
import { Field } from "formik";
import { TextField } from "@mui/material";

let otp = [];
const FormikOTPField = props => {
	const { variant, name, className, otpLength, onChange, disabled, resetOTPFields } = props;

	React.useEffect(() => {
		otp = []
	}, [resetOTPFields])

	const handleOTPEntryChange = (evt, position) => {
		const value = evt.target.value;
		otp[position] = value;
		if (value && position < otpLength - 1) {
			// Get the next input field using it's name
			const nextfield = document.querySelector(
				`input[name=${name + "subfield" + (position + 1)}]`
			);

			// If found, focus the next field
			if (nextfield !== null) {
				nextfield.focus();
			}
		}
		let otpStr = otp.join("");
		if (onChange) {
			onChange(otpStr);
		}
	};

	const handleBackspace = (evt, position) => {
		if (evt.key === "Backspace" || evt.which === 8) {
			// Get the current input field using it's name
			const currentfield = document.querySelector(
				`input[name=${name + "subfield" + position}]`
			);
			// Get the previous input field using it's name
			const previousfield = document.querySelector(
				`input[name=${name + "subfield" + (position - 1)}]`
			);
			// If found and currentField is empty, focus the previous field
			if (previousfield !== null && !currentfield.value) {
				previousfield.focus();
			}
		}
		else return
	}

	const Content = ({ form }) => {

		return (
			<React.Fragment>
				<div className="otp-field-root">
					{[...Array(Number(otpLength) || 6)].map((o, i) => (
						<TextField
							value={form?.values[name + "subfield" + i] || ""}
							id={name + "subfield" + i}
							name={name + "subfield" + i}
							key={i}
							label=""
							disabled={disabled}
							onChange={e => handleOTPEntryChange(e, i)}
							onKeyDown={e => handleBackspace(e, i)}
							inputProps={{ maxLength: 1 }}
							variant={variant || "outlined"}
							className={className || ""}
						/>
					))}
				</div>
			</React.Fragment>
		);
	};
	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default FormikOTPField;
