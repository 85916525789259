import { Box, Button, FormControl, Grid, MenuItem, Select, Skeleton } from "@mui/material";
import React from "react";
import { AbilityAnalysis, ConceptAnalysis, Header, MenuLayout, Suggestions } from "../../components";
import styles from "./DashboardPage.module.scss"
import ReactApexChart from "react-apexcharts";
import { chartConfig } from "../../components/assets";
import { SummaryBox } from "../../components";
import { WelcomeModal } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
import { DashboardFilterOptions } from "../../components/assets";
import { useNavigate } from "react-router-dom";
import { SiteMap } from "../../Routes";
import { useMediaQuery } from "react-responsive";

const DashboardPage = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
    const DashboardState = useSelector(state => state.dashboard)
    const [filter, setFilter] = React.useState('this_week');
    const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);
    const dispatch = useDispatch()
    const authState = useSelector(state => state.auth)
    const { payment_plan } = authState || {}
    const { first_name, last_name, is_first_session = false } = authState.user || {}
    const { summary, chart } = DashboardState.practiceSummary || {}
    const {
        overall_practice_accuary = 0,
        practice_sessions_done = 0,
        total_hours_practiced = 0,
        total_questions_practiced = 0 } = summary || {}

    const { abilityAnalysisLoading, conceptAnalysisLoading, practiceSummaryLoading, topSuggestionsLoading, welcomeModalClosed } = DashboardState

    const { getApiPracticeSummary, getApiTopSuggestions, getApiConceptAnalysis, getApiAbilityAnalysis, welcomeModalClose } = bindActionCreators(
        actions.dashboard,
        dispatch
    );

    React.useEffect(() => {
        if (is_first_session && !welcomeModalClosed) {
            setShowWelcomeModal(true)
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_first_session])

    React.useEffect(() => {
        getApiPracticeSummary(filter)
        getApiTopSuggestions(filter)
        getApiConceptAnalysis(filter)
        getApiAbilityAnalysis(filter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const handleChange = (event) => {
        setFilter(event.target.value);
    };

    const [options, setOptions] = React.useState({
        ...chartConfig, xaxis: { categories: [], labels: { show: false } },
        yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
                show: true,
                formatter: (value) => { return `${value}%` },
            },
        },
        title: {
            text: "Accuracy",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#8A8A8A",
                fontSize: '10px',
                fontFamily: 'Poppins, Arial, sans-serif',
                fontWeight: 500,
                cssClass: 'apexcharts-xaxis-title',
            },
        },
    })

    const [series, setSeries] = React.useState([{ name: "Accuracy", data: [] }])

    React.useEffect(() => {
        if (chart && chart.hasOwnProperty("dates") && chart.hasOwnProperty("accuracies")) {
            setOptions({ ...options, xaxis: { categories: [...chart?.dates], labels: { show: true } } })
            setSeries([{ data: [...chart?.accuracies] }])
        }  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart])

    React.useEffect(() => {
        let barOption = { ...options.plotOptions.bar }
        if (isMobile) {
            barOption['dataLabels'] = { orientation: 'vertical' }
        } else {
            barOption['dataLabels'] = { orientation: 'horizontal' }
        }
        setOptions({ ...options, plotOptions: { bar: barOption } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    return (
        <MenuLayout>
            <Header welcomeMessage={`Welcome, ${first_name} ${last_name}!`} />
            <Box className={styles.dashboard_page}>
                <Grid container spacing={4} alignItems="center" className={styles.dashboard_title_row}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <h1>
                            {
                                practiceSummaryLoading ?
                                    <Skeleton variant="rounded" height={35} width={300} />
                                    : "Practice Summary"
                            }
                        </h1>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Box className={styles.dashboard_sorting}>
                            {
                                practiceSummaryLoading ?
                                    <Skeleton className="rounded" variant="rounded" height={35} width={'100%'} />
                                    :
                                    <FormControl className="sorting_dropdown">
                                        <Select value={filter} onChange={handleChange} className="dropdown-select">
                                            <MenuItem disabled value="" id="placeholder">Sort By</MenuItem>
                                            {
                                                DashboardFilterOptions.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                            }

                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={styles.dashboard_number_row}>
                    <SummaryBox
                        loading={practiceSummaryLoading}
                        title="Total Hours Practiced"
                        value={total_hours_practiced}
                        styleClass={styles.dashboard_number_green} />
                    <SummaryBox
                        loading={practiceSummaryLoading}
                        title="Total Questions Practiced"
                        value={total_questions_practiced}
                        styleClass={styles.dashboard_number_red} />
                    <SummaryBox
                        loading={practiceSummaryLoading}
                        title="Practice Sessions Done"
                        value={practice_sessions_done}
                        styleClass={styles.dashboard_number_yellow} />
                    <SummaryBox
                        loading={practiceSummaryLoading}
                        title="Overall Practice Accuracy"
                        value={`${overall_practice_accuary}%`}
                        styleClass={styles.dashboard_number_blue} />
                </Grid>

                {
                    practiceSummaryLoading ?
                        <Skeleton className="rounded mt3" variant="rounded" height={400} />
                        :
                        <Box className={styles.box_detail_sections}>
                            <Box className={styles.sessions_chart}>
                                <h4 className={styles.sessions_chart_title}>
                                    Last 5 Practice Sessions
                                </h4>
                                <Box className={styles.sessions_chart_box}>
                                    <ReactApexChart options={options} series={series} type="bar" height={350} />
                                </Box>
                            </Box>
                        </Box>
                }


                <Box className={styles.box_detail_sections}>
                    {
                        topSuggestionsLoading ?
                            <React.Fragment>
                                <Skeleton className="center-align mb1" variant="rounded" height={35} width={300} />
                                <Skeleton className="rounded" variant="rounded" height={350} />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <h2 className={styles.box_detail_title} >
                                    Top Suggestions
                                </h2>
                                <Suggestions loading={topSuggestionsLoading} />
                            </React.Fragment>
                    }


                </Box>

                <Box className={styles.box_detail_sections}>
                    <h2 className={styles.box_detail_title} >
                        {
                            conceptAnalysisLoading ?
                                <Skeleton className="center-align" variant="rounded" height={35} width={300} />
                                : "Concept-wise Analysis"
                        }
                    </h2>
                    <Grid container spacing={2}>
                        <ConceptAnalysis />
                    </Grid>
                </Box>
                <Box className={styles.box_detail_sections}>
                    <h2 className={styles.box_detail_title} >
                        {
                            abilityAnalysisLoading ?
                                <Skeleton className="center-align" variant="rounded" height={35} width={300} />
                                :
                                "Ability-wise Analysis"
                        }
                    </h2>
                    <Grid container spacing={4} >
                        <AbilityAnalysis
                            data={DashboardState.abilityAnalysis || {}}
                            loading={abilityAnalysisLoading || false}
                        />
                    </Grid>
                </Box>
            </Box>
            <Box className={`bottom_btn_box ${styles.footer_button}`}>
                <Button className="bottom_btn" onClick={() => navigate(SiteMap.PracticePage.path)}>Start a practice session</Button>
                {payment_plan !== "Paid" && <Button className={`bottom_btn ${styles.upgrade_button}`} onClick={() => navigate(SiteMap.PaymentPage.path)}>Upgrade now</Button>}
                <Button className="bottom_btn_back" onClick={() => navigate(SiteMap.PracticeHistoryPage.path)}>Analyze your perfomance</Button>
            </Box>
            <WelcomeModal open={showWelcomeModal} handleClose={() => { setShowWelcomeModal(false); welcomeModalClose() }} />
        </MenuLayout >
    );
};

export default DashboardPage;
