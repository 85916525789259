import { SIGNUP_CONTEXT as context } from '../context';

const INITIAL_STATE = {
    isOtpVerified: false,
    isOtpSent: false,
}
const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case context.RESET_SIGNUP_STATE:
            return INITIAL_STATE

        case context.RESET_OTP_STATES:
            return {
                ...state,
                isOtpSent: false,
                isOtpVerified: false,
                currentAction: action.type
            }

        case context.UPDATE_SIGNUP_FORM:
            return {
                ...state,
                signUpForm: action.signUpForm,
                currentAction: action.type
            }

        case context.OTP_GENERATE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.OTP_GENERATE_ERROR:
            return {
                ...state,
                loading: false,
                otpGenerateError: action.otpGenerateError,
                currentAction: action.type
            }

        case context.OTP_GENERATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isOtpSent: true,
                otpGenerateError: "",
                currentAction: action.type
            }

        case context.OTP_VERIFY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                verifyingOTP: true,
                currentAction: action.type
            }

        case context.OTP_VERIFY_ERROR:
            return {
                ...state,
                loading: false,
                verifyingOTP: false,
                otpVerificationError: action.otpVerificationError,
                currentAction: action.type
            }

        case context.OTP_VERIFY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isOtpVerified: true,
                verifyingOTP: false,
                otpVerificationError: "",
                currentAction: action.type
            }

        case context.SIGN_UP_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.SIGN_UP_ERROR:
            return {
                ...state,
                loading: false,
                signUpError: action.signUpError,
                currentAction: action.type
            }

        case context.SIGN_UP_SUCCESS:
            return {
                ...state,
                success: true,
                data: action.data,
                currentAction: action.type
            }

        default:
            return state;
    }
};

export default reducer;