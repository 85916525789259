export const ProfileFormInitialTouched = {
    "first_name": true,
    "last_name": true,
    "mobile_number": true,
    "email_id": true,
    "parent_name": true,
    "parent_mobile": true,
    "school": true,
    "city": true,
    "gender": true,
    "student_class": true,
    "branch": true,
    "parent_relation": true,
    "date_of_birth": true,
}