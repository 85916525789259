import { BOOKMARK_CONTEXT as context } from '../context';

const BookmarkInintialState = {
    loading: false,
    success: false,
    BookmarksList: [],
    currentAction: "INITIAL_STATE"
};

export const BookmarkReducer = (state = BookmarkInintialState, action) => {
    switch (action.type) {
        case context.RESET_BOOKMARK_REDUCER:
            return BookmarkInintialState;

        case context.POST_BOOKMARK_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            };

        case context.POST_BOOKMARK_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                postBookmarkError: action.postBookmarkError,
                currentAction: action.type
            };

        case context.POST_BOOKMARK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                bookmarkStaus: action.bookmarkStaus,
                postBookmarkError: undefined,
                currentAction: action.type
            };

        case context.DEL_BOOKMARK_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.DEL_BOOKMARK_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                deleteBookmarkError: action.deleteBookmarkError,
                currentAction: action.type
            };

        case context.DEL_BOOKMARK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                bookmarkStaus: action.bookmarkStaus,
                deleteBookmarkError: undefined,
                currentAction: action.type
            };


        case context.GET_BOOKMARKS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            };

        case context.GET_BOOKMARKS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getBookmarkListError: action.getBookmarkListError,
                currentAction: action.type
            };

        case context.GET_BOOKMARKS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                BookmarksList: action.BookmarksList,
                getBookmarkListError: undefined,
                currentAction: action.type
            };

        case context.GET_BOOKMARK_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            };

        case context.GET_BOOKMARK_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getBookmarkedQuestionError: action.getBookmarkedQuestionError,
                currentAction: action.type
            };

        case context.GET_BOOKMARK_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                BookmarkedQuestion: action.BookmarkedQuestion,
                getBookmarkedQuestionError: undefined,
                currentAction: action.type
            };

        default:
            return state;
    }
};