export const GenderList = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' }
];

export const ClassList = [
    { label: '10', value: '10' },
]

export const ParentsRelationship = [
    { label: 'Father', value: 'Father' },
    { label: 'Mother', value: 'Mother' },
    { label: 'Guardian', value: 'Guardian' },
]

export const DashboardFilterOptions = [
    { value: "this_week", label: "This week" },
    { value: "last_week", label: "Last week" },
    { value: "this_month", label: "This month" },
    { value: "last_month", label: "Last month" },
    { value: "all_time", label: "All Time" },
]

export const CountriesOption = [{
    value: "India", label: "India",
},]

export const IndianStatesOption = [
    {
        value: "Andaman and Nicobar Islands",
        label: "Andaman and Nicobar Islands"
    },
    {
        value: "Andhra Pradesh",
        label: "Andhra Pradesh"
    },
    {
        value: "Arunachal Pradesh",
        label: "Arunachal Pradesh"
    },
    {
        value: "Assam",
        label: "Assam"
    },
    {
        value: "Bihar",
        label: "Bihar"
    },
    {
        value: "Chandigarh",
        label: "Chandigarh"
    },
    {
        value: "Chhattisgarh",
        label: "Chhattisgarh"
    },
    {
        value: "Dadra and Nagar Haveli",
        label: "Dadra and Nagar Haveli"
    },
    {
        value: "Daman and Diu",
        label: "Daman and Diu"
    },
    {
        value: "Delhi",
        label: "Delhi"
    },
    {
        value: "Goa",
        label: "Goa"
    },
    {
        value: "Gujarat",
        label: "Gujarat"
    },
    {
        value: "Haryana",
        label: "Haryana"
    },
    {
        value: "Himachal Pradesh",
        label: "Himachal Pradesh"
    },
    {
        value: "Jammu and Kashmir",
        label: "Jammu and Kashmir"
    },
    {
        value: "Jharkhand",
        label: "Jharkhand"
    },
    {
        value: "Karnataka",
        label: "Karnataka"
    },
    {
        value: "Kerala",
        label: "Kerala"
    },
    {
        value: "Lakshadweep",
        label: "Lakshadweep"
    },
    {
        value: "Madhya Pradesh",
        label: "Madhya Pradesh"
    },
    {
        value: "Maharashtra",
        label: "Maharashtra"
    },
    {
        value: "Manipur",
        label: "Manipur"
    },
    {
        value: "Meghalaya",
        label: "Meghalaya"
    },
    {
        value: "Mizoram",
        label: "Mizoram"
    },
    {
        value: "Nagaland",
        label: "Nagaland"
    },
    {
        value: "Orissa",
        label: "Orissa"
    },
    {
        value: "Pondicherry",
        label: "Pondicherry"
    },
    {
        value: "Punjab",
        label: "Punjab"
    },
    {
        value: "Rajasthan",
        label: "Rajasthan"
    },
    {
        value: "Sikkim",
        label: "Sikkim"
    },
    {
        value: "Tamil Nadu",
        label: "Tamil Nadu"
    },
    {
        value: "Tripura",
        label: "Tripura"
    },
    {
        value: "Uttaranchal",
        label: "Uttaranchal"
    },
    {
        value: "Uttar Pradesh",
        label: "Uttar Pradesh"
    },
    {
        value: "West Bengal",
        label: "West Bengal"
    }
]