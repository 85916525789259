import React from "react";
import { Box, Button, Typography } from "@mui/material";
import styles from "./Error404.module.scss"
import { Error404Logo } from "../../components/assets";
import { useNavigate } from "react-router-dom";
import { SiteMap } from "../../Routes";

const Error404 = () => {
	const navigate = useNavigate()
	return (
		<Box className={styles.error_404_box}>
			<Box className={styles.error}>
				<Box className={styles.error_image}>
					<img src={Error404Logo} alt="Error404Logo" />
				</Box>
				<Typography variant="h3">Oops! Page Not Found</Typography>
				<Typography variant="h6">This page doesn't exist or was removed! We suggest you go back home.</Typography>
				<Button onClick={() => {
					navigate(SiteMap.HomePage.path);
				}}>Go back to Home</Button>
			</Box>
		</Box>
	);
};

export default Error404;
