import React from "react";
import { Skeleton } from "@mui/material";

const SessionStatusItem = ({ icon, label, value, className, loading }) => {
    return (
        <React.Fragment>
            <li className={className}>
                <p>
                    {loading ? <Skeleton height={30} width={20} /> : icon}
                    {loading ? <Skeleton className="ml1" height={20} width={150} /> : label}
                </p>
                <span>{loading ? <Skeleton height={20} width={20} /> : value}</span>
            </li>
        </React.Fragment>
    )
}

export default SessionStatusItem;