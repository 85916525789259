import React from "react";
import { MenuLayout, SessionStatusItem } from "../../components";
import styles from './AnalysePage.module.scss'
import { Box, Button, CircularProgress, Grid, Skeleton, LinearProgress, Typography, Tooltip } from "@mui/material";
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { chartConfig, SkipIcon, SolidCheckCorrect, clockIcon } from '../../components/assets';
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import { useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SiteMap } from "../../Routes";
import { DialogBox } from "../../components/";
import { useMediaQuery } from "react-responsive";
import { useDataGridSkeletonHelper } from "../../components";

const DifficultyWiseStatusItem = ({ loading, accuracy, difficulty, time_spent, questions_attempted, className }) => {
    return (
        <Grid item sm={6} md={6} lg={4} xl={4}>
            <Box className={styles.progressBarBox}>
                {
                    loading ?
                        <Box className={`${styles.progressBar}`}>
                            <Skeleton variant="circular" width={110} height={110} />
                        </Box>
                        :
                        <Box className={`${className} ${styles.progressBar}`}>
                            <CircularProgress variant="determinate" value={accuracy} />
                            <Box className={styles.progressBarCount}>
                                <p>{difficulty}</p>
                                <h2>{accuracy}%</h2>
                            </Box>
                        </Box>
                }
                <Box className={styles.progressBarText}>
                    <Box>
                        {
                            loading ?
                                <React.Fragment>
                                    <Skeleton width={100} />
                                    <Skeleton width={80} />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <p>Questions Attempted</p>
                                    <h6>{questions_attempted}</h6>
                                </React.Fragment>
                        }
                    </Box>
                    <Box>
                        {
                            loading ?
                                <React.Fragment>
                                    <Skeleton width={100} />
                                    <Skeleton width={80} />
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <p>Time Spent</p>
                                    <h6>{time_spent}</h6>
                                </React.Fragment>
                        }
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

const AnalysePage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
    const { getApiSubTopicList } = bindActionCreators(
        actions.practice,
        dispatch
    );
    const { loadingRows, loadingColumns } = useDataGridSkeletonHelper({ row: 3, columns: 6, height: 30, width: 100 })

    const { selectTopics, selectSubTopics } = bindActionCreators(
        actions.ui,
        dispatch
    );

    const {
        getApiSessionSummary,
        getApiTopicSummary,
        getApiSubTopicSummary,
        getApiSessionSubtopicAbilityScores,
        getApiSessionChart,
        getApiReportStatus,
        setReportStatusSuccess,
        getApiSessionAbilityScores
    } = bindActionCreators(
        actions.analyse,
        dispatch
    );

    const analyseState = useSelector(state => state.analyse)
    const { session_summary = {},
        topic_summary = [],
        subtopic_summary = [],
        sessionSubtopicAbilityScores = [],
        chart = {},
        // sessionAbilityScores = [],
        session_summary_loading,
        subtopic_summary_loading,
        sessionSubtopicAbilityScoresLoading,
        topic_summary_loading,
        concept_chart_loading,
        // sessionAbilityScoresLoading,
        //report_status = false 
    } = analyseState || {}
    const { session_accuracy, session_status, time_taken, total_questions_attempted, difficulty_wise_attempts } = session_summary || {}
    const { incorrect_attempts, partially_correct, perfect_attempts, skipped_questions } = session_status || {}
    const { easy, medium, hard } = difficulty_wise_attempts || {}
let report_status = true
    const handleClick = (selected) => {
        let { topic_id, topic } = selected
        selectTopics([{ "topic": topic, "id": topic_id }])
        selectSubTopics([])
        getApiSubTopicList(topic_id)
        navigate(SiteMap.SelectSubTopicPage.path)
    }

    const handleSuptopicClick = (selected) => {
        let { subtopic_id, subtopic } = selected
        selectTopics([{ "topic": selectedTopic, "id": selectedTopicID }])
        selectSubTopics([{ "subtopic": subtopic, "id": subtopic_id }])
        getApiSubTopicList(selectedTopicID)
        navigate(SiteMap.SelectDifficultyPage.path)
    }

    const columns = [
        {
            field: 'topic',
            headerName: 'Topics Attempted',
            flex: 1,
            align: 'left',
            disableColumnMenu: true,
            renderCell: (params) => (<Box className="underline">{params.value}</Box>)
        },
        {
            field: 'smart_score',
            headerName: 'Smart Score',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true,
            sortComparator: (v1, v2) => (Number(v1) - Number(v2))
        },
        {
            field: 'topic_accuracy',
            headerName: 'Topic Accuracy',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true,
            sortComparator: (v1, v2) => (Number(v1) - Number(v2))
        },
        {
            field: 'time_spent',
            headerName: 'Time Spent',
            flex: 0.6,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'questions_attempted',
            headerName: 'Question Attempted',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'action',
            headerName: 'Practice',
            flex: 1,
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box className="button_list_center bottom_btn_box">
                        <Button className="bottom_btn bottom_btn_back" onClick={() => handleClick(params.row)}>
                            Practice
                        </Button>
                    </Box>
                );
            },
        }
    ];

    const columnsForSubtopic = [
        {
            field: 'subtopic',
            headerName: 'Subtopics Attempted',
            flex: 1,
            align: 'left',
            disableColumnMenu: true,
            renderCell: (params) => (<Box className="underline">{params.value}</Box>)
        },
        {
            field: 'smart_score',
            headerName: 'Smart Score',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'subtopic_accuracy',
            headerName: 'Subtopic Accuracy',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'time_spent',
            headerName: 'Time Spent',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'questions_attempted',
            headerName: 'Question Attempted',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'action',
            headerName: 'Practice',
            flex: 1,
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box className="button_list_center bottom_btn_box">
                        <Button className="bottom_btn bottom_btn_back"
                            onClick={() => handleSuptopicClick(params.row)}
                        >
                            Practice
                        </Button>
                    </Box>
                );
            },
        }
    ];

    const [options, setOptions] = React.useState({
        ...chartConfig,
        grid: { show: false },
        yaxis: { show: false },
        colors: ["#FFB20080", "#4339F280", "#02A0FC80", "#FF3A2980", "#00893780"],
        xaxis: { categories: [], labels: { show: false } }
    })

    const [series, setSeries] = React.useState([{ name: "Accuracy", data: [] }])
    const [showWaitDialogBox, setShowWaitDialogBox] = React.useState(false)

    const fetchAPIData = (session_id) => {
        getApiSessionSummary(session_id);
        getApiTopicSummary(session_id);
        getApiSessionChart(session_id);
        getApiSessionAbilityScores(session_id);
    }

    const [breadcrumbs, setBreadcrumbs] = React.useState("all")
    const [selectedTopic, setSelectedTopic] = React.useState("")
    const [selectedTopicID, setSelectedTopicID] = React.useState("")
    const [selectedSubtopic, setSelectedSubtopic] = React.useState("")

    React.useEffect(() => {
        if (chart.hasOwnProperty("xaxis") && chart.hasOwnProperty("yaxis")) {
            setOptions({ ...options, xaxis: { categories: [...chart?.xaxis], labels: { show: chart?.xaxis?.length > 0 } } })
            setSeries([{ data: [...chart?.yaxis] }])
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart])

    const handleTopicsRowClick = (params) => {
        let { session_id } = location.state || {}
        let { id } = params || {}
        setBreadcrumbs("subtopic")
        setSelectedTopic(params.row.topic)
        setSelectedTopicID(id)
        getApiSubTopicSummary(session_id, id)
    };

    const handleSubtopicsRowClick = (params) => {
        let { session_id } = location.state || {}
        let { id } = params || {}
        getApiSessionSubtopicAbilityScores(session_id, selectedTopicID, id)
        setBreadcrumbs("ability")
        setSelectedSubtopic(params.row.subtopic)
    };

    React.useEffect(() => {
        let barOption = { ...options.plotOptions.bar }
        if (isMobile) {
            barOption['dataLabels'] = { orientation: 'vertical' }
        } else {
            barOption['dataLabels'] = { orientation: 'horizontal' }
        }
        setOptions({ ...options, plotOptions: { bar: barOption } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    React.useEffect(() => {
        let pollingReportStatusId

        if (!location.state) {
            navigate(SiteMap.PracticeHistoryPage.path)
            return
        }

        let { session_id, needPolling } = location.state || {}

        if (needPolling) {
            getApiReportStatus(session_id)
            if (!report_status) {
                setShowWaitDialogBox(true)
                pollingReportStatusId = setInterval(() => {
                    getApiReportStatus(session_id)
                }, 2000)
            } else {
                setShowWaitDialogBox(false)
                clearInterval(pollingReportStatusId)
                fetchAPIData(session_id)
            }
        } else {
            setReportStatusSuccess();
            fetchAPIData(session_id);
        }

        return () => clearInterval(pollingReportStatusId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state, report_status])

    const getTopicsPracticed = () => (
        topic_summary.length > 0 ?
            topic_summary.map((item, index) => (
                `${item?.topic}${index === topic_summary.length - 1 ? "" : ","}  `
            )) :
            "N/A"
    )

    return (
        <MenuLayout>
            <DialogBox
                open={showWaitDialogBox}
                message={"We are evaluating your responses and generating final report for you. Please sit back and relax while we generate it."}
            />
            <div className={styles.AnalyzeWrp}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box className={styles.analyzeTitle}>
                            <h3>Great job! Practice Session complete.</h3>
                            {
                                topic_summary_loading ?
                                    <Skeleton height={42} width={250} />
                                    :
                                    <Tooltip title={getTopicsPracticed()} placement="bottom">
                                        <h6>
                                            <span>Topic practiced: </span>
                                            <span>{getTopicsPracticed()}</span>
                                        </h6>
                                    </Tooltip>
                            }
                        </Box>
                    </Grid>
                    <Grid container spacing={2} className={`${styles.analyze_container} ${styles.session_container}`}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} md={6} lg={3} order={{ xs: 1, lg: 1 }}>
                                <Box className={styles.topicPracticedBox}>
                                    <Box className={styles.PracticedBoxLeft}>
                                        {
                                            session_summary_loading || !report_status ?
                                                <Skeleton variant="circular" width={90} height={90} />
                                                :
                                                <Box className={styles.PracticedBoxIcon}>
                                                    <img src={clockIcon} alt="" />
                                                    <Box className={styles.clock_time}>
                                                        <span>{time_taken?.time} {time_taken?.unit}</span>
                                                    </Box>
                                                </Box>
                                        }

                                    </Box>
                                    <Box className={styles.PracticedBoxRight}>
                                        {
                                            session_summary_loading || !report_status ?
                                                <React.Fragment>
                                                    <Skeleton width={80} />
                                                </React.Fragment>
                                                :
                                                <h5>Duration of practice</h5>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} order={{ xs: 3, lg: 2 }}>
                                <Box className={styles.topicPracticedBox}>
                                    <Box className={styles.PracticedBoxLeft}>
                                        <Box className={styles.question_update}>
                                            <ul>
                                                <SessionStatusItem
                                                    loading={session_summary_loading || !report_status}
                                                    className={styles.text_sky}
                                                    icon={<SkipIcon />}
                                                    label="Skipped Questions"
                                                    value={skipped_questions} />
                                                <SessionStatusItem
                                                    loading={session_summary_loading || !report_status}
                                                    className={styles.text_green}
                                                    icon={<SolidCheckCorrect />}
                                                    label="Perfect Attempts"
                                                    value={perfect_attempts} />
                                                <SessionStatusItem
                                                    loading={session_summary_loading || !report_status}
                                                    className={styles.text_yellow}
                                                    icon={<GppMaybeOutlinedIcon />}
                                                    label="Partially Correct"
                                                    value={partially_correct} />
                                                <SessionStatusItem
                                                    loading={session_summary_loading || !report_status}
                                                    className={styles.text_red}
                                                    icon={<CancelOutlinedIcon />}
                                                    label="Incorrect Attempt"
                                                    value={incorrect_attempts} />
                                            </ul>
                                        </Box>
                                    </Box>
                                    <Box className={styles.PracticedBoxRight}>
                                        {
                                            session_summary_loading || !report_status ?
                                                <React.Fragment>
                                                    <Skeleton width={100} />
                                                    <Skeleton width={100} />
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <h5>Total Questions Attempted</h5>
                                                    <h4>{total_questions_attempted}</h4>
                                                </React.Fragment>
                                        }

                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3} order={{ xs: 2, lg: 3 }}>
                                <Box className={styles.topicPracticedBox}>
                                    <Box className={styles.PracticedBoxLeft}>
                                        {
                                            session_summary_loading || !report_status ?
                                                <Skeleton variant="circular" width={90} height={90} />
                                                :
                                                <Box className={styles.circularProgressBar}>
                                                    <Box className={styles.circular_progress_bar}>
                                                        <CircularProgress variant="determinate" value={session_accuracy} />
                                                    </Box>
                                                    <Box className={styles.session_time}>
                                                        <span>{session_accuracy}%</span>
                                                    </Box>
                                                </Box>
                                        }
                                    </Box>
                                    <Box className={styles.PracticedBoxRight}>
                                        {
                                            session_summary_loading || !report_status ?
                                                <React.Fragment>
                                                    <Skeleton width={90} />
                                                </React.Fragment>
                                                :
                                                <h5>Session Accuracy</h5>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={styles.attemptBox}>
                                <Box className={styles.boxTopTitle}>
                                    {
                                        session_summary_loading || !report_status ?
                                            <Skeleton height={40} width={250} />
                                            :
                                            <h6>Difficulty-wise Attempt Distribution</h6>
                                    }
                                </Box>
                                <Grid container spacing={10}>
                                    <DifficultyWiseStatusItem
                                        loading={session_summary_loading || !report_status}
                                        accuracy={easy?.accuracy}
                                        difficulty="Easy"
                                        time_spent={easy?.time_spent}
                                        questions_attempted={easy?.questions_attempted}
                                        className={styles.progressBarEasy} />
                                    <DifficultyWiseStatusItem
                                        loading={session_summary_loading || !report_status}
                                        accuracy={medium?.accuracy}
                                        difficulty="Medium"
                                        time_spent={medium?.time_spent}
                                        questions_attempted={medium?.questions_attempted}
                                        className={styles.progressBarMedium} />
                                    <DifficultyWiseStatusItem
                                        loading={session_summary_loading || !report_status}
                                        accuracy={hard?.accuracy}
                                        difficulty="Hard"
                                        time_spent={hard?.time_spent}
                                        questions_attempted={hard?.questions_attempted}
                                        className={styles.progressBarDifficult} />
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={styles.AnalyseTableBox}>
                                <Box className={styles.boxTitle}>
                                    <h6>Topics Practiced</h6>
                                    <Box className={styles.Breadcrumbs}>
                                        {breadcrumbs !== "all" &&
                                            <Box className={styles.BreadcrumbsList}>
                                                <Typography onClick={() => { setBreadcrumbs("all"); setSelectedTopic(""); setSelectedSubtopic("") }}
                                                    className={styles.BreadcrumbsPageActive}>
                                                    All Topics |
                                                </Typography>
                                                <Typography onClick={() => { setBreadcrumbs("subtopic"); setSelectedSubtopic("") }}
                                                    className={selectedSubtopic ? styles.BreadcrumbsPageActive : ''}>
                                                    <span>{selectedTopic}</span>
                                                    <span> </span>
                                                    <span>{selectedSubtopic && `|`}</span>
                                                </Typography>
                                                {selectedSubtopic &&
                                                    <Typography>{selectedSubtopic}</Typography>}
                                            </Box>
                                        }
                                        {topic_summary.length > 0 &&
                                            <Box className={styles.BreadcrumbsText}>
                                                {breadcrumbs === "all" ?
                                                    <Typography color="error">• Click on any topic row to see its subtopic-wise analysis</Typography> :
                                                    breadcrumbs === "subtopic" ? <Typography color="error">• Click on any subtopic row to see its ability-wise analysis</Typography> : <></>
                                                }
                                            </Box>
                                        }
                                    </Box>
                                </Box>

                                <Box className={styles.PracticeWrp}>
                                    {breadcrumbs === "all" &&
                                        < Box className={styles.PracticeTopics}>
                                            {topic_summary.length > 0 &&
                                                <Box className={styles.AnalyseDataTable}>
                                                    <DataGrid
                                                        onRowClick={handleTopicsRowClick}
                                                        className="analytics_data_grid"
                                                        components={{
                                                            // Pagination: CustomPagination,
                                                            ColumnUnsortedIcon: () => (<ImportExportOutlinedIcon />),
                                                            ColumnSortedAscendingIcon: () => (<ArrowUpwardOutlinedIcon />),
                                                            ColumnSortedDescendingIcon: () => (<ArrowDownwardOutlinedIcon />)
                                                        }}
                                                        classes={{ row: "analytics_data_row" }}
                                                        autoHeight
                                                        rows={topic_summary}
                                                        getRowId={(row) => row.topic_id}
                                                        columns={columns}
                                                        disableSelectionOnClick
                                                    />
                                                </Box>}
                                        </Box>
                                    }
                                    {breadcrumbs === "subtopic" &&
                                        <Box className={styles.PracticeSubtopics}>
                                            <Box className={styles.AnalyseDataTable}>
                                                {subtopic_summary_loading || !report_status ?
                                                    <DataGrid
                                                        className="analytics_data_grid"
                                                        classes={{ row: "analytics_data_row" }}
                                                        autoHeight
                                                        rows={loadingRows}
                                                        columns={loadingColumns}
                                                    /> :
                                                    subtopic_summary.length > 0 ?
                                                        <DataGrid
                                                            onRowClick={handleSubtopicsRowClick}
                                                            className="analytics_data_grid"
                                                            components={{
                                                                ColumnUnsortedIcon: () => (<ImportExportOutlinedIcon />),
                                                                ColumnSortedAscendingIcon: () => (<ArrowUpwardOutlinedIcon />),
                                                                ColumnSortedDescendingIcon: () => (<ArrowDownwardOutlinedIcon />)
                                                            }}
                                                            classes={{ row: "analytics_data_row" }}
                                                            autoHeight
                                                            rows={subtopic_summary}
                                                            getRowId={(row) => row.subtopic_id}
                                                            columns={columnsForSubtopic}
                                                            disableSelectionOnClick
                                                        /> : <></>}
                                            </Box>
                                        </Box>
                                    }
                                    {breadcrumbs === "ability" &&
                                        <>
                                            {(sessionSubtopicAbilityScoresLoading || !report_status) &&
                                                <Box className={styles.PracticeAbilities}>
                                                    <Box className={styles.Ability0}>
                                                        <Box className={styles.AbilityBox} >
                                                            <Box className={styles.ProgressBarLine}>
                                                                <LinearProgress className={styles.AbilityProgress} variant="indeterminate" value={35} />
                                                            </Box>
                                                            <Box className={styles.ProgressTop}>
                                                                <Skeleton variant="text" height={20} width={100} />
                                                                <Skeleton variant="text" height={20} width={20} className={styles.ProgressPercentage} />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>}

                                            {!sessionSubtopicAbilityScoresLoading && sessionSubtopicAbilityScores.length > 0 &&
                                                <Box className={styles.PracticeAbilities}>
                                                    {
                                                        sessionSubtopicAbilityScores.map((ability, index) => {
                                                            return (
                                                                <Box key={index} className={styles[`Ability${((index % 6).toString())}`]}>
                                                                    <Box className={styles.AbilityBox} >
                                                                        <Box className={styles.ProgressBarLine}>
                                                                            <LinearProgress className={styles.AbilityProgress} variant="determinate" value={Number(ability.percentage_score)} />
                                                                        </Box>
                                                                        <Box className={styles.ProgressTop}>
                                                                            <div dangerouslySetInnerHTML={{ __html: ability.ability }} />
                                                                            <Typography className={styles.ProgressPercentage}>{ability.percentage_score}%</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            }
                                        </>
                                    }
                                </Box>
                            </Box>
                        </Grid>

                        {/* this commented code is for session-ability-scores API implementation */}

                        {/* <Grid item xs={12}>
                            <Box className={styles.AnalyseTableBox}>
                                <Box className={styles.boxTitle}>
                                    {
                                        sessionAbilityScoresLoading || !report_status ?
                                            <Skeleton className="mb1" width={250} height={40} />
                                            :
                                            <h6>Abilities Practiced</h6>
                                    }
                                </Box>
                                {(sessionAbilityScoresLoading || !report_status) &&
                                    <Box className={styles.PracticeAbilities}>
                                        <Box className={styles.Ability0}>
                                            <Box className={styles.AbilityBox} >
                                                <Box className={styles.ProgressBarLine}>
                                                    <LinearProgress className={styles.AbilityProgress} variant="indeterminate" value={35} />
                                                </Box>
                                                <Box className={styles.ProgressTop}>
                                                    <Skeleton variant="text" height={20} width={100} />
                                                    <Skeleton variant="text" height={20} width={20} className={styles.ProgressPercentage} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                {!sessionAbilityScoresLoading && sessionAbilityScores.length > 0 &&

                                    <Box className={styles.PracticeWrp}>
                                        <Box className={styles.PracticeAbilities}>
                                            {
                                                sessionAbilityScores.map((ability, index) => {
                                                    return (
                                                        <Box key={index} className={styles[`Ability${(index.toString())}`]}>
                                                            <Box className={styles.AbilityBox} >
                                                                <Box className={styles.ProgressBarLine}>
                                                                    <LinearProgress className={styles.AbilityProgress} variant="determinate" value={Number(ability.percentage_score)} />
                                                                </Box>
                                                                <Box className={styles.ProgressTop}>
                                                                    <div dangerouslySetInnerHTML={{ __html: ability.ability }} />
                                                                    <Typography className={styles.ProgressPercentage}>{ability.percentage_score}%</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Grid> */}


                        <Grid item xs={12}>
                            <Box className={styles.chartBox}>
                                <Box className={styles.pageTitle}>
                                    {
                                        concept_chart_loading || !report_status ?
                                            <Skeleton width={250} height={40} />
                                            :
                                            <h6>Top Concepts Practiced</h6>
                                    }

                                </Box>
                                <Box className={styles.chartView}>
                                    {
                                        concept_chart_loading || !report_status ?
                                            <Skeleton variant="rounded" height={300} />
                                            :
                                            <ReactApexChart options={options} series={series} type="bar" height={350} />
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={`bottom_btn_box footer_button_list ${styles.footer_button}`}>
                        <Button className="bottom_btn" onClick={() => navigate(SiteMap.PracticePage.path)}>Start another practice session</Button>
                        <Button className="bottom_btn_back" onClick={() => navigate(SiteMap.PracticeHistoryPage.path)}>Analyse overall performance</Button>
                    </Box>
                </Grid>
            </div>
        </MenuLayout >
    )
}

export default AnalysePage;