import axios from "axios";
import { store } from "../redux/store";
import { apiEndpoints } from "./apiConstants";
import { getApiRefreshToken } from "../redux/actions/authActions";
import { ShowToast } from "../redux/actions/ToastActions";

function getToken() {
    let token = store.getState().auth.token.access_token;
    return token;
}

function getRefreshToken() {
    let refreshToken = store.getState().auth.token.refresh_token;
    return refreshToken
}

function checkSession() {
    let isValid = store.getState().auth.isSessionValid
    return isValid
}
//apply base url for axios
// const API_URL = "http://192.168.0.41:8000/studdicted-learner/v1beta1";
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
    baseURL: API_URL,
});

axiosApi.interceptors.request.use(async (config) => {
    if (new Date() > new Date(store.getState().auth.token.expires_at)) {
        await store.dispatch(getApiRefreshToken())
    }
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
});

const axiosPrivateApi = axios.create({
    baseURL: API_URL,
});

axiosPrivateApi.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getRefreshToken()}`;
    return config;
});

export async function refreshToken() {
    return await axiosPrivateApi.post(apiEndpoints.AUTH_URLS.REFRESH_TOKEN)
        .then(response => response).catch(err => err.response);
}

axiosApi.interceptors.response.use(
    response => response,
    async (error) => {
        if (error.response) {
            const originalConfig = error.config;
            if (checkSession() && error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                await store.dispatch(getApiRefreshToken())
                return axiosApi(originalConfig);
            }
            if (error.response.status === 0) {
                store.dispatch(ShowToast({ "message": error.message, "severity": "error" }))
            }
        }
        return Promise.reject(error);
    }
);

export async function get(url) {
    return await axiosApi.get(url).then(response => response).catch(err => err.response);
}

export async function post(url, data) {
    return await axiosApi
        .post(url, { ...data })
        .then(response => response).catch(err => err.response);
}

export async function patch(url, data) {
    return await axiosApi
        .patch(url, { ...data })
        .then(response => response).catch(err => err.response);
}

export async function put(url, data) {
    return await axiosApi
        .put(url, { ...data })
        .then(response => response).catch(err => err.response);
}

export async function del(url) {
    return await axiosApi
        .delete(url)
        .then(response => response).catch(err => err.response);
}