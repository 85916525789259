import { Box, Modal } from '@mui/material';
import React from 'react';
import styles from './ZoomableImage.module.scss'

const ZoomableImage = ({ src = '', alt = '', captions = <></> }) => {
    const [zoom, setZoom] = React.useState(false)
    return (
        <Box className={styles.image_model}>
            <img onClick={() => setZoom(true)} id={styles.myImg} src={src} alt={alt} />
            <Modal
                open={zoom}
                onClose={() => setZoom(false)}
                aria-describedby="ZoomableImageModal"
            >
                <Box id={styles.myModal} className={styles.modal}>
                    <span className={styles.close} onClick={() => setZoom(false)}>&times;</span>
                    <img className={styles.modal_content} src={src} alt={alt} />
                    <div id={styles.caption}>{captions}</div>
                </Box>
            </Modal>
        </Box>
    )
}

export default ZoomableImage;