import React from "react";
import { Box, Button, Skeleton } from "@mui/material";

const OptionSelector = ({ isLoading, list = [], currentSelected = "", setSelector = () => { } }) => {
  return (
    <React.Fragment>
      <Box className="button_list justify-content-flex-start">
        {
          isLoading ?
            Array(3).fill().map((item, key) => (
              <Skeleton key={key} className="btn" variant="rounded" height={60} />
            ))
            :
            list.map((item) => {
              return (
                <Button key={item.id} className={`btn ${currentSelected.id === item.id ? "active" : ""}`} onClick={() => setSelector(item)}>{item.label}</Button>
              )
            })
        }
      </Box>
    </React.Fragment>
  );
};

export default OptionSelector;
