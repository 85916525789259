import React from "react";
import { Button, Box, ListItem, List, IconButton, Skeleton } from "@mui/material"
import { Header, MenuLayout, NoRecords, } from "../../components";
import styles from "./PracticePage.module.scss"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
// import LockIcon from '@mui/icons-material/Lock';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { SiteMap } from "../../Routes";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
import { useDataGridSkeletonHelper, columnSkeletons } from "../../components";
import moment from "moment";

const PracticePage = ({ openUpgradeModal = () => { } }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const practiceState = useSelector(state => state.practice)
	const isLoading = practiceState.currentAction === "GET_TOPICS_LIST_REQUEST"
	const { topicList } = practiceState || {}
	const [disableSelectionOnClick, setDisableSelectionOnClick] = React.useState(false);
	// const [pageSize, setPageSize] = React.useState(5);
	const [selectedTopics, setSelectedTopics] = React.useState([])
	const { loadingRows, loadingColumns } = useDataGridSkeletonHelper({ row: 3, columns: 5, height: 30, width: 100 })

	const { getApiTopicList, getApiSubTopicList } = bindActionCreators(
		actions.practice,
		dispatch
	);

	const { selectTopics, selectSubTopics } = bindActionCreators(
		actions.ui,
		dispatch
	);

	React.useEffect(() => {
		getApiTopicList()
		selectSubTopics([]) // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const CustomTopicSort = (v1, v2, param1, param2) => {
		if (sortModel[0]['sort'] === 'asc') {
			if (param1.api.getRow(param1.id).is_locked && param2.api.getRow(param2.id).is_locked) {
				return (v1 < v2 ? -1 : 1)
			}
			return (
				(param1.api.getRow(param1.id).is_locked - param2.api.getRow(param2.id).is_locked) ||
				(param2.api.getRow(param2.id).is_available - param1.api.getRow(param1.id).is_available) ||
				(v1 < v2 ? -1 : 1))
		} else {
			if (param1.api.getRow(param1.id).is_locked && param2.api.getRow(param2.id).is_locked) {
				return (v1 < v2 ? -1 : 1)
			}
			return (
				(param2.api.getRow(param2.id).is_locked - param1.api.getRow(param1.id).is_locked) ||
				(param1.api.getRow(param1.id).is_available - param2.api.getRow(param2.id).is_available) ||
				(v1 < v2 ? -1 : 1))
		}
	}

	const CustomNumberSort = (v1, v2, param1, param2) => {
		if (sortModel[0]['sort'] === 'asc') {
			return (
				(param1.api.getRow(param1.id).is_locked - param2.api.getRow(param2.id).is_locked) ||
				(param2.api.getRow(param2.id).is_available - param1.api.getRow(param1.id).is_available) ||
				(Number(v1) - Number(v2)))
		} else {
			return (
				(param2.api.getRow(param2.id).is_locked - param1.api.getRow(param1.id).is_locked) ||
				(param1.api.getRow(param1.id).is_available - param2.api.getRow(param2.id).is_available) ||
				(Number(v1) - Number(v2)))
		}
	}

	const CustomDateSort = (v1, v2, param1, param2) => {
		if (sortModel[0]['sort'] === 'asc') {
			return (
				(param1.api.getRow(param1.id).is_locked - param2.api.getRow(param2.id).is_locked) ||
				(param2.api.getRow(param2.id).is_available - param1.api.getRow(param1.id).is_available) ||
				(moment(v1, 'DD-MMM-YYYY') - moment(v2, 'DD-MMM-YYYY')))
		} else {
			return (
				(param2.api.getRow(param2.id).is_locked - param1.api.getRow(param1.id).is_locked) ||
				(param1.api.getRow(param1.id).is_available - param2.api.getRow(param2.id).is_available) ||
				(moment(v1, 'DD-MMM-YYYY') - moment(v2, 'DD-MMM-YYYY')))
		}
	}

	const columns = [
		{
			field: 'topic',
			headerName: 'Topics',
			flex: 1,
			align: 'left',
			disableColumnMenu: true,
			sortComparator: CustomTopicSort
		},
		{
			field: 'accuracy',
			headerName: 'Accuracy',
			flex: 0.5,
			align: 'center',
			disableColumnMenu: true,
			sortComparator: CustomNumberSort
		},
		{
			field: 'total_practice_sessions_attended',
			headerName: 'Sessions Done',
			flex: 0.5,
			align: 'center',
			disableColumnMenu: true,
			sortComparator: CustomNumberSort
		},
		{
			field: 'last_practice_on',
			headerName: 'Last Session',
			flex: 1,
			align: 'center',
			disableColumnMenu: true,
			sortComparator: CustomDateSort
		},
		{
			field: 'action',
			headerName: 'Other Actions',
			flex: 1,
			disableColumnMenu: true,
			sortable: false,
			renderCell: (params) => {
				return (
					<Box className={styles.topics_table_col}
						onMouseEnter={() => setDisableSelectionOnClick(true)} onMouseLeave={() => setDisableSelectionOnClick(false)}
					>
						<List className={styles.topics_icon_list}>
							{/* <ListItem className={styles.topics_icon_item}>
								<IconButton className={styles.topics_icon}><PlagiarismIcon /><h5>Analyze<br />this topic</h5></IconButton>
							</ListItem> */}
							<ListItem className={styles.topics_icon_item}>
								<IconButton disabled={!params.row?.is_available} onClick={() => {
									if (!params.row?.is_available) return
									navigate({ pathname: SiteMap.BookmarkPage.path },
										{ state: { topic: params.row?.topic } });
								}}
									className={styles.topics_icon}><BookmarkIcon /><h5>Bookmarked<br />questions</h5></IconButton>
							</ListItem>
						</List>
						{/* <Button className={styles.topics_icon_modal_btn} onClick={handleOpen}>
							<MoreVertIcon />
						</Button> */}
					</Box>
				);
			},
		}
	];

	const columnLoading = [
		{
			field: 5,
			flex: 0.1,
			sortable: false,
			disableColumnMenu: true,
			...columnSkeletons(30, 18)
		},
		...loadingColumns
	];


	const handlePracticeNow = () => {
		if (practiceState.paymentRequired) {
			openUpgradeModal()
			return
		}
		selectTopics(topicList.filter((topic => selectedTopics.includes(topic.id))))
		if (selectedTopics.length > 1) {
			navigate(SiteMap.SelectDifficultyPage.path)
		} else {
			getApiSubTopicList(selectedTopics[0])
			navigate(SiteMap.SelectSubTopicPage.path)
		}
	}

	const [sortModel, setSortModel] = React.useState([
		{
			field: 'topic',
			sort: 'asc',
		},
	]);

	return (
		<MenuLayout>
			{topicList.length === 0 && !isLoading ?
				<NoRecords heading="No Topics Found !" />
				:
				<>
					<Header header1={"Select Topic(s) to practice"} loading={isLoading} />

					<Box className={styles.topics_section}>
						<Box className={styles.topics_section_wrap}>
							<Box className={styles.topics_table}>
								{isLoading ?
									<DataGrid
										className="select_topic_data_grid"
										components={{
											ColumnUnsortedIcon: () => (<ImportExportOutlinedIcon />),
											ColumnSortedAscendingIcon: () => (<ArrowUpwardOutlinedIcon />),
											ColumnSortedDescendingIcon: () => (<ArrowDownwardOutlinedIcon />)
										}}
										rows={loadingRows}
										columns={columnLoading}
										autoHeight
									/>
									:
									<DataGrid
										className="select_topic_data_grid"
										components={{
											// BaseCheckbox: BaseCheckBox,
											// Pagination: CustomPagination,
											ColumnUnsortedIcon: () => (<ImportExportOutlinedIcon />),
											ColumnSortedAscendingIcon: () => (<ArrowUpwardOutlinedIcon />),
											ColumnSortedDescendingIcon: () => (<ArrowDownwardOutlinedIcon />)
										}}
										sortingOrder={['desc', 'asc']}
										sortModel={sortModel}
										onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
										isRowSelectable={(params) => params.row.is_available && !params.row.is_locked}
										getRowClassName={(params) => params.row.is_locked ? "locked_row" : !params.row.is_available ? "disabled_row" : ""}
										selectionModel={selectedTopics}
										onSelectionModelChange={(ids) => setSelectedTopics(ids)}
										classes={{ row: `${styles.topics_table_row} topics_table_row` }}
										autoHeight
										rows={(topicList || [])}
										columns={columns}
										disableSelectionOnClick={disableSelectionOnClick}
										// rowsPerPageOptions={[5, 10, 15]}
										// pageSize={pageSize}
										// onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
										checkboxSelection={!isLoading}
									/>
								}
							</Box>
						</Box>
						<Box className='button_list'>
							<Box className="bottom_btn_box">
								{
									isLoading ?
										<Skeleton variant="rounded" className="rounded" width={250} height={50} />
										:
										<Button disabled={selectedTopics.length === 0 || practiceState.loading} className="bottom_btn" onClick={() => handlePracticeNow()}>Practice Now </Button>
								}
							</Box>
						</Box>
					</Box>
				</>
			}
			{/* <Modal
				open={open}
				onClose={handleClose}
				aria-describedby="topics_icon_modal_modal"
			>
				<Box className={styles.mobile_options_modal_box}>
					<Button className={styles.topics_icon_modal_close} onClick={handleClose}>
						<CloseIcon />
					</Button>
					<List className={styles.modal_topics_icon_list}>
						<ListItem className={styles.modal_topics_icon_item}>
							<IconButton className={styles.modal_topics_icon}><PlagiarismIcon /><span>Analyse This Topic</span></IconButton>
						</ListItem>
						<ListItem className={styles.modal_topics_icon_item}>
							<IconButton className={styles.modal_topics_icon}><BookmarkIcon /><span>Bookmarked Questions</span></IconButton>
						</ListItem>
					</List>
				</Box>
			</Modal> */}
		</MenuLayout>
	);
};

export default PracticePage;
