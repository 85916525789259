import { Grid } from "@mui/material";
import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import style from "./MenuLayout.module.scss"

const MenuLayout = props => {
    return (
        <React.Fragment>
            <Grid className={style.Menu_layout} container spacing={1}>
                <Grid>
                    <Sidebar />
                </Grid>
                <Grid className={style.children}>
                    {props.children}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default MenuLayout;
