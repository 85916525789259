import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import authReducer from './authReducer';
import signUpReducer from './signUpReducer';
import UIReducer from './UIReducer'
import { ToastReducer } from "./ToastReducer";
import { PracticeReducer } from "./practiceReducer";
import { BookmarkReducer } from "./bookmarkReducer";
import { DashboardReducer } from "./dashboardReducer";
import { LandingSiteReducer } from "./landingSiteReducer";
import { AnalyseReducer } from "./analyseReducer";
import { PaymentReducer } from "./paymentReducer";
import { TimerRedcuer } from "./timerRedcuer";

const persistConfig = {
    key: 'counter',
    storage,
    blacklist: ['toast', 'signup', 'payment']
};

const reducers = combineReducers({
    auth: authReducer,
    signup: signUpReducer,
    practice: PracticeReducer,
    bookmark: BookmarkReducer,
    UI: UIReducer,
    dashboard: DashboardReducer,
    landingSite: LandingSiteReducer,
    analyse: AnalyseReducer,
    toast: ToastReducer,
    payment: PaymentReducer,
    timer: TimerRedcuer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default persistedReducer;