import { Box, Button, Skeleton } from "@mui/material";
import React from "react";

const SelectTopics = ({ isLoading, list = [], currentSelected = [], setSelector = () => { } }) => {
    return (
        <React.Fragment>
            <Box className="page_box">
                <Box className="practice_page">
                    {
                        isLoading ?
                            <Box className='button_list'>
                                {Array(6).fill().map((item, index) => (
                                    <Skeleton key={index} className="btn" variant="rounded" height={60} />
                                ))}
                            </Box>
                            :
                            <Box className='button_list full_width'>
                                <Button
                                    key="all"
                                    className={`btn  
                                ${list.length === currentSelected.length ? "active" : ""}`
                                    }
                                    onClick={() => setSelector("All Subtopic")}>
                                    All Subtopics
                                </Button>
                                {
                                    list.map((item, index) => {
                                        return (
                                            <Button
                                                key={item.subtopic + index}
                                                className={`btn  
                                        ${currentSelected.includes(item) ? "active" : ""}
                                        ${item === "All Subtopic" ? list.length - 1 === currentSelected.length ? "active" : "" : ""}`
                                                }
                                                onClick={() => setSelector(item)}>
                                                {item.subtopic}
                                            </Button>
                                        )
                                    })
                                }
                            </Box>
                    }
                </Box>
            </Box>
        </React.Fragment >
    )
}

export default SelectTopics;