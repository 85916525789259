import ForwardIcon from '@mui/icons-material/Forward';
import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';
//import Latex from 'react-latex';
//import { stripHtml } from '../assets';
import { renderToHtmlString } from '../assets/utils/utils';

const AnalysisBox = ({ title, data, styleClass, message, loading }) => {

    return (

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={styleClass}>
                {
                    loading ?
                        <React.Fragment>
                            <Skeleton className='custom-rounded-skeleton' variant="rounded" height={40} />
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton width={'60%'} animation={false} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h4>
                                {title}:
                            </h4>
                            {
                                data ?
                                    <ul>
                                        {
                                            data.map((item, index) => (
                                                <li key={index}>
                                                    <div>
                                                        <ForwardIcon />
                                                        <p className='ability-item' dangerouslySetInnerHTML={{ __html: renderToHtmlString(item) }}>
                                                            {/* <Latex displayMode>{stripHtml(item)}</Latex> */}
                                                        </p>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    :
                                    <p>{message || "No data available here"}</p>
                            }

                        </React.Fragment>
                }
            </Box>
        </Grid>
    )
}

export default AnalysisBox