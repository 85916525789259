import React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import styles from './CustomSelectDropDown.module.scss'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function useOutsideAlerter(ref, setOpenDropDown) {
    React.useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpenDropDown(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);
}

const CustomSelectDropDown = ({ label, placeholder, options, selected = [], setSelected = () => { } }) => {

    const [openDropDown, setOpenDropDown] = React.useState(false)

    const [filteredOptions, setFilteredOptions] = React.useState(options)
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, setOpenDropDown);

    React.useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const handleFilter = (e) => {
        let search = e.target.value.toLowerCase();
        let filterOption = options.filter((item) => item.toLowerCase().includes(search))
        setFilteredOptions(filterOption)
    }

    const handleSelect = (item) => {
        let optionList = [...selected]
        if (item === "All") {
            if (optionList.length === options.length) {
                optionList = []
            } else {
                optionList = options
            }
        }
        else if (selected.includes(item)) {
            let index = optionList.indexOf(item)
            optionList.splice(index, 1)
        } else {
            optionList.push(item)
        }
        setSelected(optionList)
    }

    return (
        <Box className={styles.bookmarked_field}>
            <label htmlFor="">{label}</label>
            <Box className={styles.custom_field} ref={wrapperRef}>
                <Box className={styles.TextField_box}>
                    <TextField
                        className={styles.TextField}
                        fullWidth
                        inputProps={{ readOnly: true }}
                        value={selected.length === 0 ? placeholder
                            : selected.length > 1 ? `${selected.length} ${placeholder} Selected`
                                : selected[0]}
                        placeholder={placeholder}
                        variant="outlined"
                        onClick={() => setOpenDropDown(!openDropDown)}
                    />
                    <Box className={styles.textField_icon}>
                        {selected.length > 0 && <CloseIcon className={styles.textField_close_icon} onClick={() => setSelected([])} />}
                        <KeyboardArrowUpIcon className={openDropDown ? "" : styles.animate} onClick={() => setOpenDropDown(!openDropDown)} />
                    </Box>
                </Box>
                {openDropDown && options.length > 0 &&
                    <Box className={styles.custom_box}>
                        <Box className={styles.box_search}>
                            <input type="text" onChange={(e) => handleFilter(e)} placeholder="Search" />
                            <SearchIcon className={styles.box_search_icon} />
                        </Box>
                        <FormGroup className={styles.select_all_input}>
                            <FormControlLabel control={<Checkbox checked={selected.length === options.length && selected.length !== 0} onClick={() => handleSelect("All")} />} label="Select all" />
                        </FormGroup>
                        <Box className={styles.select_option_list}>
                            <FormGroup>
                                {
                                    filteredOptions.map((option) =>
                                        <FormControlLabel
                                            key={option}
                                            checked={selected.includes(option)}
                                            control={< Checkbox onClick={() => handleSelect(option)} />}
                                            label={option}
                                        />
                                    )
                                }
                            </FormGroup>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default CustomSelectDropDown;