import React from "react";
import { Grid, Box } from "@mui/material";
import QuestionContainer from "../../components/QuestionContainer/QuestionContainer";
import QuestionSidebar from "../../components/QuestionSidebar/QuestionSidebar";
import QuestionFooter from "../../components/QuestionFooter/QuestionFooter";
import styles from "./PracticePage.module.scss"
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../components";
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
import { SiteMap } from "../../Routes";
import { useNavigate } from "react-router-dom";

const QuestionPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const UI = useSelector(state => state.UI)
    const practiceState = useSelector(state => state.practice)
    const { getApiQuestion, getApiEndSession, getApiSessionQuestionStatus } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const { selectTopics } = bindActionCreators(
        actions.ui,
        dispatch
    )

    React.useEffect(() => {
        if (practiceState.currentAction === "END_SESSION_SUCCESS") {
            selectTopics([])
            navigate({ pathname: SiteMap.AnalysePage.path },
                { state: { session_id: practiceState.session_id, needPolling: true } });
        }

        if (practiceState.currentAction === "GET_SESSION_QUESTION_STATUS_ERROR") {
            if (practiceState?.getSessionQuestionStatusError?.status === 412) {
                selectTopics([])
                navigate({ pathname: SiteMap.DashboardPage.path });
                getApiEndSession()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceState.currentAction])

    React.useEffect(() => {
        let pollingQuestionStatusId
        getApiSessionQuestionStatus()
        if (!practiceState?.is_question_ready) {
            pollingQuestionStatusId = setInterval(() => {
                getApiSessionQuestionStatus()
            }, 2000)
        } else clearInterval(pollingQuestionStatusId)

        if (!practiceState?.sessionPaused && practiceState?.is_question_ready) getApiQuestion()

        return () => clearInterval(pollingQuestionStatusId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceState?.is_question_ready])

    React.useEffect(() => {
        if (!practiceState?.sessionInProgress && practiceState.currentAction !== "END_SESSION_SUCCESS") {
            navigate(SiteMap.PracticePage.path)
            return
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceState?.sessionInProgress])

    const handleEndSession = () => {
        if (practiceState.loading) return
        getApiEndSession()
    }

    return (
        <Grid container>
            <Grid item xs={12} className={styles.grid_p0}>
                <Header
                    timer
                    sessionButtons
                    header1={UI.header?.topics.length > 1 ? `${UI.header?.topics[0]?.topic} and ${UI.header?.topics.length - 1} more` : UI.header?.topics[0]?.topic}
                    subtopics={UI.header.subtopics}
                    difficulty={UI.header.difficulty.label}
                    typeOfQuestions={UI.header.typeOfQuestions.label}
                    handleEndSession={handleEndSession}
                />
            </Grid>
            <Box className={styles.questionWrapOuter}>
                <Grid container className={styles.questionWrapOuterInner}>
                    <Grid item sm={12} md={7} lg={8} xl={9} order={{ xs: 3, md: 2 }}>
                        <QuestionContainer />
                    </Grid>
                    <Grid item sm={12} md={4} lg={4} xl={3} order={{ xs: 2, md: 3 }} className={styles.grid_row_w100}>
                        <QuestionSidebar />
                    </Grid>
                    <Grid item xs={12} order={4}>
                        <QuestionFooter />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default QuestionPage