import { TIMER_CONTEXT as context } from '../context';

const TimerInitialState = {
    sessionTime: 0
}

export const TimerRedcuer = (state = TimerInitialState, action) => {
    switch (action.type) {
        case context.SET_SESSION_TIME:
            return {
                ...state,
                sessionTime: action.sessionTime
            }
        default:
            return state;
    }
}