import { postGenerateOTP, postSignUp, postVerifyOTP } from '../../api/apiCalls';
import { ShowToast } from './ToastActions';
import { SIGNUP_CONTEXT as context } from '../context';
import { SignInSuccess } from './authActions';

export const updateSignUpForm = payload => ({
    type: context.UPDATE_SIGNUP_FORM,
    signUpForm: payload
})

export const resetOTPStates = () => ({
    type: context.RESET_OTP_STATES,
})

export const resetSignUpState = () => ({
    type: context.RESET_SIGNUP_STATE,
})

//OTP GENRATION ACTIONS
const OtpGenerateRequest = () => ({
    type: context.OTP_GENERATE_REQUEST,
})

const OtpGenerateError = error => ({
    type: context.OTP_GENERATE_ERROR,
    otpGenerateError: error
})

const OtpGenerateSuccess = () => ({
    type: context.OTP_GENERATE_SUCCESS,
})

export const getApiOtpGenerate = data => {
    return (dispatch) => {
        dispatch(OtpGenerateRequest())
        postGenerateOTP(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(OtpGenerateSuccess())
                dispatch(ShowToast({ "message": res?.data.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data.message, "severity": "error" }))
                dispatch(OtpGenerateError(res))
            }
        })
    }
}

//OTP VERIFICATION ACTIONS
const OtpVerificationRequest = () => ({
    type: context.OTP_VERIFY_REQUEST,
})

const OtpVerificationError = error => ({
    type: context.OTP_VERIFY_ERROR,
    otpVerificationError: error
})

const OtpVerificationSuccess = () => ({
    type: context.OTP_VERIFY_SUCCESS,
})

export const getApiOtpVerification = data => {
    return (dispatch) => {
        dispatch(OtpVerificationRequest())
        postVerifyOTP(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(OtpVerificationSuccess())
                dispatch(ShowToast({ "message": res?.data.message }))
            }
            else {
                dispatch(OtpVerificationError(res))
            }
        })
    }
}

//SIGN UP ACTIONS
const SignUpRequest = () => ({
    type: context.SIGN_UP_REQUEST,
})

const SignUpError = error => ({
    type: context.SIGN_UP_ERROR,
    signUpError: error
})

const SignUpSuccess = data => ({
    type: context.SIGN_UP_SUCCESS,
    data: data
})

export const getApiSignUp = data => {
    return (dispatch) => {
        dispatch(SignUpRequest())
        postSignUp(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(SignUpSuccess(res?.data.message))
                let { token } = res?.data.data || {}
                let expireTime = new Date()
                expireTime.setSeconds(expireTime.getSeconds() + Number(token.expires_in));
                token['expires_at'] = expireTime
                dispatch(SignInSuccess(res?.data.data))
            }
            else {
                dispatch(SignUpError(res))
                dispatch(ShowToast({ "message": res?.data.message || "Something went wrong !", "severity": "error" }))
            }
        })
    }
}
