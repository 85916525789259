import { Grid } from "@mui/material";
import React from "react";
import { MenuLayout, PaymentForm } from "../../components";
import styles from "./PaymentPage.module.scss"

const PaymentPage = () => {

    return (
        <MenuLayout>
            <Grid container className={styles.payment_page}>
                <Grid item sm={12} md={7} className={styles.payment_page_grid}>
                    <PaymentForm />
                </Grid>
            </Grid>
        </MenuLayout>
    )
}

export default PaymentPage