import React from "react"
import { Button, Grid, Box } from "@mui/material"
import styles from "./QuestionFooter.module.scss"
import WarningIcon from '@mui/icons-material/Warning';
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux";
import ReportModal from "../Overlays/ReportModal";

const QuestionFooter = () => {

    const UI = useSelector(state => state.UI)
    const dispatch = useDispatch()
    const practiceState = useSelector(state => state.practice)

    const {
        hint,
        solution,
        question_id,
    } = practiceState.question || {}

    const { submitAnswerStatus, sessionPaused, loading } = practiceState || {}

    const {
        displaySideMenuHint = false,
        displaySideMenuSolution } = UI.question || {}

    const { toggleHint, toggleSolution } = bindActionCreators(
        actions.ui,
        dispatch
    );

    const { getApiSubmitAnswer, getApiQuestion, getApiSkipAnswer } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const { ShowToast } = bindActionCreators(
        actions.toast,
        dispatch
    );

    const handleSubmit = () => {
        if (sessionPaused) return
        if (practiceState.selectedAnswer.length === 0 && submitAnswerStatus?.answer_correctness !== "Skipped") {
            ShowToast({ "message": "You must select answer", "severity": "error" })
            return
        }
        if (submitAnswerStatus?.answer_correctness) {
            getApiQuestion()
        } else {
            let submitApiPayload = {
                "question_id": question_id,
                "answers": [...practiceState.selectedAnswer]
            }
            getApiSubmitAnswer(submitApiPayload)
        }
    }

    const handleSkip = () => {
        if (sessionPaused || !question_id) return
        getApiSkipAnswer(question_id)
    }

    const handleHintSolution = () => {
        if (sessionPaused) return
        if (!hint && !solution) return
        if (Boolean(submitAnswerStatus?.answer_correctness)) {
            toggleSolution(true);
            return
        }
        if (displaySideMenuHint || !hint) {
            toggleSolution(true);
            if (!Boolean(submitAnswerStatus?.answer_correctness)) handleSkip();
        } else toggleHint(true);
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box className={styles.question_footer}>
            <Grid container>
                <Grid item md={7} lg={8} xl={9} className="bottom_btn_box">
                    {!displaySideMenuSolution && (hint || solution) && submitAnswerStatus?.answer_correctness !== "Skipped" &&
                        <Button disabled={loading || !Boolean(question_id)} hidden={(!hint && !solution)} className="bottom_btn bottom_btn_back" onClick={handleHintSolution}>{displaySideMenuHint || Boolean(submitAnswerStatus?.answer_correctness) || !hint ? "See Solution" : "View Hint"}</Button>
                    }
                    <Button className="bottom_btn_simple" disabled={Boolean(submitAnswerStatus?.answer_correctness) || !Boolean(question_id) || loading} onClick={handleSkip}>Skip For Now</Button>
                    <Button disabled={loading || !Boolean(question_id)} className="bottom_btn" onClick={() => handleSubmit()}>{(displaySideMenuSolution || submitAnswerStatus?.answer_correctness) ? "Next Question" : "Submit Question"}</Button>
                </Grid>
                <Grid item md={5} lg={4} xl={3} className={styles.report_button}>
                    <Button onClick={handleOpen}> <WarningIcon /> Report an Error</Button>
                </Grid>
            </Grid>
            <ReportModal open={open} handleClose={handleClose} />
        </Box>
    )
}

export default QuestionFooter