import { ANALYSE_CONTEXT as context } from '../context';

const AnalyseInintialState = {
    loading: false,
    success: false,
    session_summary: {},
    session_history_loading: false,
    session_history_chart_loading: false,
    session_summary_loading: false,
    topic_summary_loading: false,
    concept_chart_loading: false,
    report_status: false,
    currentAction: "INITIAL_STATE"
};

export const AnalyseReducer = (state = AnalyseInintialState, action) => {
    switch (action.type) {
        case context.RESET_ANALYSE_REDUCER: {
            return AnalyseInintialState
        }

        case context.GET_SESSION_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                session_summary: {},
                session_summary_loading: true,
                currentAction: action.type
            };

        case context.GET_SESSION_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                session_summary_loading: false,
                getSessionSummaryError: action.getSessionSummaryError,
                currentAction: action.type
            };

        case context.GET_SESSION_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                session_summary_loading: false,
                session_summary: action.session_summary,
                getSessionSummaryError: undefined,
                currentAction: action.type
            };

        case context.GET_TOPIC_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                topic_summary: [],
                topic_summary_loading: true,
                currentAction: action.type
            };

        case context.GET_TOPIC_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                topic_summary_loading: false,
                getTopicSummaryError: action.getTopicSummaryError,
                currentAction: action.type
            };

        case context.GET_TOPIC_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                topic_summary_loading: false,
                topic_summary: action.topic_summary,
                getTopicSummaryError: undefined,
                currentAction: action.type
            };

        case context.GET_SUBTOPIC_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                subtopic_summary: [],
                subtopic_summary_loading: true,
                currentAction: action.type
            };

        case context.GET_SUBTOPIC_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                subtopic_summary_loading: false,
                getSubTopicSummaryError: action.getSubTopicSummaryError,
                currentAction: action.type
            };

        case context.GET_SUBTOPIC_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                subtopic_summary_loading: false,
                subtopic_summary: action.subtopic_summary,
                getSubTopicSummaryError: undefined,
                currentAction: action.type
            };

        case context.GET_SESSION_SUBTOPIC_ABILITY_SCORES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                sessionSubtopicAbilityScoresLoading: true,
                sessionSubtopicAbilityScores: [],
                currentAction: action.type
            };

        case context.GET_SESSION_SUBTOPIC_ABILITY_SCORES_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                sessionSubtopicAbilityScoresLoading: false,
                getSessionSubtopicAbilityScoresError: action.getSessionSubtopicAbilityScoresError,
                currentAction: action.type
            };

        case context.GET_SESSION_SUBTOPIC_ABILITY_SCORES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                sessionSubtopicAbilityScoresLoading: false,
                sessionSubtopicAbilityScores: action.sessionSubtopicAbilityScores,
                getSessionSubtopicAbilityScoresError: undefined,
                currentAction: action.type
            };

        case context.GET_SESSION_CHART_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                chart: {},
                concept_chart_loading: true,
                currentAction: action.type
            };

        case context.GET_SESSION_CHART_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                concept_chart_loading: false,
                getSessionChartError: action.getSessionChartError,
                currentAction: action.type
            };

        case context.GET_SESSION_CHART_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                chart: action.chart,
                concept_chart_loading: false,
                getSessionChartError: undefined,
                currentAction: action.type
            };

        case context.GET_SESSION_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                session_history: [],
                session_history_loading: true,
                currentAction: action.type
            };

        case context.GET_SESSION_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                session_history_loading: false,
                getSessionHistoryError: action.getSessionHistoryError,
                currentAction: action.type
            };

        case context.GET_SESSION_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                session_history_loading: false,
                session_history: action.session_history,
                getSessionHistoryError: undefined,
                currentAction: action.type
            };

        case context.GET_ANALYSE_CHART_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                session_history_chart_loading: true,
                session_history_chart: {},
                currentAction: action.type
            };

        case context.GET_ANALYSE_CHART_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                session_history_chart_loading: false,
                getAnalyseChartError: action.getAnalyseChartError,
                currentAction: action.type
            };

        case context.GET_ANALYSE_CHART_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                session_history_chart_loading: false,
                session_history_chart: action.analyseChart,
                getAnalyseChartError: undefined,
                currentAction: action.type
            };


        case context.GET_SESSION_ABILITY_SCORES_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                sessionAbilityScoresLoading: true,
                sessionAbilityScores: [],
                currentAction: action.type
            };

        case context.GET_SESSION_ABILITY_SCORES_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                sessionAbilityScoresLoading: false,
                getSessionAbilityScoresError: action.getSessionAbilityScoresError,
                currentAction: action.type
            };

        case context.GET_SESSION_ABILITY_SCORES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                sessionAbilityScoresLoading: false,
                sessionAbilityScores: action.sessionAbilityScores,
                getSessionAbilityScoresError: undefined,
                currentAction: action.type
            };

        case context.GET_REPORT_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            };

        case context.GET_REPORT_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getReportStatusError: action.getReportStatusError,
                currentAction: action.type
            };

        case context.GET_REPORT_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                report_status: action.status,
                getReportStatusError: undefined,
                currentAction: action.type
            };

        case context.GET_TOPIC_WISE_ANALYSIS_REQUEST:
            return {
                ...state,
                loading: true,
                topicWiseAnalysisLoading: true,
                success: false,
                topicWiseAnalysis: [],
                currentAction: action.type
            };

        case context.GET_TOPIC_WISE_ANALYSIS_ERROR:
            return {
                ...state,
                loading: false,
                topicWiseAnalysisLoading: false,
                success: false,
                getTopicWiseAnalysisError: action.getTopicWiseAnalysisError,
                currentAction: action.type
            };

        case context.GET_TOPIC_WISE_ANALYSIS_SUCCESS:
            return {
                ...state,
                loading: false,
                topicWiseAnalysisLoading: false,
                success: true,
                topicWiseAnalysis: action.topicWiseAnalysis,
                getTopicWiseAnalysisError: undefined,
                currentAction: action.type
            };

        case context.GET_SUBTOPIC_WISE_ANALYSIS_REQUEST:
            return {
                ...state,
                loading: true,
                subtopicWiseAnalysisLoading: true,
                subtopicWiseAnalysis: [],
                success: false,
                currentAction: action.type
            };

        case context.GET_SUBTOPIC_WISE_ANALYSIS_ERROR:
            return {
                ...state,
                loading: false,
                subtopicWiseAnalysisLoading: false,
                success: false,
                getSubtopicWiseAnalysisError: action.getSubtopicWiseAnalysisError,
                currentAction: action.type
            };

        case context.GET_SUBTOPIC_WISE_ANALYSIS_SUCCESS:
            return {
                ...state,
                loading: false,
                subtopicWiseAnalysisLoading: false,
                success: true,
                subtopicWiseAnalysis: action.subtopicWiseAnalysis,
                getSubtopicWiseAnalysisError: undefined,
                currentAction: action.type
            };

        case context.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_REQUEST:
            return {
                ...state,
                loading: true,
                topicSubtopicWiseAbilityAnalysisLoading: true,
                topicSubtopicWiseAbilityAnalysis: [],
                success: false,
                currentAction: action.type
            };

        case context.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_ERROR:
            return {
                ...state,
                loading: false,
                topicSubtopicWiseAbilityAnalysisLoading: false,
                success: false,
                getTopicSubtopicWiseAbilityAnalysisError: action.getTopicSubtopicWiseAbilityAnalysisError,
                currentAction: action.type
            };

        case context.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_SUCCESS:
            return {
                ...state,
                loading: false,
                topicSubtopicWiseAbilityAnalysisLoading: false,
                success: true,
                topicSubtopicWiseAbilityAnalysis: action.topicSubtopicWiseAbilityAnalysis,
                getTopicSubtopicWiseAbilityAnalysisError: undefined,
                currentAction: action.type
            };

        case context.SET_REPORT_STATUS_SUCCESS:
            return {
                ...state,
                report_status: true,
            };


        default:
            return state;
    }
};