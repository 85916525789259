import { PRACTICE_CONTEXT as context } from '../context';

const PracticeInintialState = {
    loading: false,
    success: false,
    topicList: [],
    subTopicList: [],
    difficultyLevels: [],
    questionFormats: [],
    instructions: [],
    question: {},
    sessionPaused: false,
    timer: "00:00",
    selectedTime: "2 minutes",
    pauseTimerDate: null,
    paymentRequired: false,
    isSessionInProgressModalClosed: false,
    currentAction: "INITIAL_STATE"
};

export const PracticeReducer = (state = PracticeInintialState, action) => {
    switch (action.type) {
        case context.RESET_PRACTICE_REDUCER:
            return PracticeInintialState;

        case context.SESSION_IN_PROGRESS:
            return {
                ...state,
                sessionInProgress: true,
                currentAction: action.type
            };

        case context.SESSION_NOT_FOUND:
            return {
                ...state,
                sessionInProgress: false,
                currentAction: action.type
            };

        case context.PAYMENT_REQUIRED:
            return {
                ...state,
                paymentRequired: action.payload,
                currentAction: action.type
            }

        case context.GET_TOPICS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                topicList: [],
                currentAction: action.type
            };

        case context.GET_TOPICS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getTopicListError: action.getTopicListError,
                currentAction: action.type
            };

        case context.GET_TOPICS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                getTopicListError: "",
                topicList: action.topicList,
                currentAction: action.type
            };

        case context.GET_SUB_TOPICS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                subTopicList: [],
                currentAction: action.type
            };

        case context.GET_SUB_TOPICS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getSubTopicListError: action.getSubTopicListError,
                currentAction: action.type
            };

        case context.GET_SUB_TOPICS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                getSubTopicListError: "",
                subTopicList: action.subTopicList,
                currentAction: action.type
            };

        case context.GET_DIFFICULTY_LEVELS_REQUEST:
            return {
                ...state,
                loadingDifficultyLevels: true,
                loading: true,
                difficultyLevels: [],
                currentAction: action.type
            };

        case context.GET_DIFFICULTY_LEVELS_ERROR:
            return {
                ...state,
                loadingDifficultyLevels: false,
                loading: false,
                success: false,
                getDifficultyLevelError: action.getDifficultyLevelError,
                currentAction: action.type
            };

        case context.GET_DIFFICULTY_LEVELS_SUCCESS:
            return {
                ...state,
                loadingDifficultyLevels: false,
                loading: false,
                success: false,
                getDifficultyLevelError: "",
                difficultyLevels: action.difficultyLevels,
                currentAction: action.type
            };

        case context.GET_QUESTION_FORMATS_REQUEST:
            return {
                ...state,
                loadingQuestionFormat: true,
                loading: true,
                questionFormats: [],
                currentAction: action.type
            };

        case context.GET_QUESTION_FORMATS_ERROR:
            return {
                ...state,
                loadingQuestionFormat: false,
                loading: false,
                success: false,
                getQuestionFormatsError: action.getQuestionFormatsError,
                currentAction: action.type
            };

        case context.GET_QUESTION_FORMATS_SUCCESS:
            return {
                ...state,
                loadingQuestionFormat: false,
                loading: false,
                success: false,
                getQuestionFormatsError: "",
                questionFormats: action.questionFormats,
                currentAction: action.type
            };

        case context.GET_INSTRUCTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.GET_INSTRUCTIONS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getInstructionError: action.getInstructionError,
                currentAction: action.type
            };

        case context.GET_INSTRUCTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                getInstructionError: "",
                instructions: action.instructions,
                currentAction: action.type
            };

        case context.PREPARE_SESSION_REQUEST:
            return {
                ...state,
                loading: true,
                is_question_ready: false,
                sessionStatus: {},
                sessionStatusLoading: true,
                questionProperties: {},
                question: {},
                currentAction: action.type
            };

        case context.PREPARE_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                sessionStatusLoading: false,
                prepareSessionError: action.prepareSessionError,
                currentAction: action.type
            };

        case context.PREPARE_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                sessionPaused: false,
                sessionInProgress: true,
                prepareSessionError: "",
                session_id: action.session_id,
                currentAction: action.type
            };

        case context.GET_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                question: {},
                currentAction: action.type
            };

        case context.GET_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getQuestionError: action.getQuestionError,
                currentAction: action.type
            };

        case context.GET_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                sessionPaused: false,
                getQuestionError: "",
                question: action.question,
                currentAction: action.type
            };

        case context.SET_QUESTION_PROPERTIES:
            return {
                ...state,
                questionProperties: action.questionProperties,
            }

        case context.SET_SESSION_STATUS:
            return {
                ...state,
                sessionStatus: action.sessionStatus,
                sessionStatusLoading: false
            }

        case context.SET_BOOKMARK_STATUS:
            return {
                ...state,
                bookmarkStatus: action.bookmarkStatus,
            }

        case context.END_SESSION_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.END_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                endSessionError: action.endSessionError,
                currentAction: action.type
            };

        case context.END_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                sessionInProgress: false,
                sessionPaused: false,
                timer: "00:00",
                pauseTimerDate: null,
                endSessionError: undefined,
                currentAction: action.type
            };

        case context.SELECT_ANSWER:
            return {
                ...state,
                selectedAnswer: [...action.selectedAnswer],
                currentAction: action.type
            }


        case context.SUBMIT_ANSWER_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.SUBMIT_ANSWER_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                submitAnswerError: action.submitAnswerError,
                currentAction: action.type
            };

        case context.SUBMIT_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                submitAnswerStatus: { ...action.data },
                submitAnswerError: undefined,
                currentAction: action.type
            };

        case context.SKIP_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.SKIP_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                skipAnswerError: action.skipAnswerError,
                currentAction: action.type
            };

        case context.SKIP_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                submitAnswerStatus: { ...action.data },
                skipAnswerError: undefined,
                currentAction: action.type
            };

        case context.REPORT_ERROR_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.REPORT_ERROR_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                reportErrorError: action.reportErrorError,
                currentAction: action.type
            };

        case context.REPORT_ERROR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                reportErrorError: undefined,
                currentAction: action.type
            };

        case context.PAUSE_SESSION_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.PAUSE_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                pauseSessionError: action.pauseSessionError,
                currentAction: action.type
            };

        case context.PAUSE_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                pauseSessionError: undefined,
                sessionPaused: true,
                currentAction: action.type
            };

        case context.RESUME_SESSION_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.RESUME_SESSION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                resumeSessionError: action.resumeSessionError,
                currentAction: action.type
            };

        case context.RESUME_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                sessionPaused: false,
                timer: "00:00",
                pauseTimerDate: null,
                resumeSessionError: undefined,
                currentAction: action.type
            };

        case context.SET_PAUSE_TIMER:
            return {
                ...state,
                timer: action.timer
            }

        case context.SET_PAUSE_TIME:
            return {
                ...state,
                selectedTime: action.selectedTime
            }

        case context.SET_TIMER_DATE:
            return {
                ...state,
                pauseTimerDate: action.pauseTimerDate
            }

        case context.GET_SESSION_QUESTION_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            };

        case context.GET_SESSION_QUESTION_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                getSessionQuestionStatusError: action.getSessionQuestionStatusError,
                currentAction: action.type
            };

        case context.GET_SESSION_QUESTION_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: false,
                getSessionQuestionStatusError: undefined,
                is_question_ready: action.is_question_ready,
                currentAction: action.type
            };

        case context.UPDATE_SESSION_PROGRESS_MODAL_STATE:
            return {
                ...state,
                isSessionInProgressModalClosed: action.isSessionInProgressModalClosed
            }

        case context.UPDATE_SESSION_ID:
            return {
                ...state,
                session_id: action.session_id,
            }

        default:
            return state;
    }
};