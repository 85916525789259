import { getDifficultyLevels, getInstructions, getQuestion, getQuestionFormats, getSessionQuestionStatus, getSubTopicsList, getTopicsList, postEndSession, postPauseSession, postPrepareSession, postReportError, postResumeSession, postSkipQuestion, postSubmitAnswer } from '../../api/apiCalls';
import { PRACTICE_CONTEXT as context } from '../context';
import { restAnalyseReducer } from './analyseActions';
import { UpdatePaymentPlan } from './authActions';
import { ShowToast } from './ToastActions';

// RESET PRACTICE STATE
export const resetPracticeReducer = () => ({
    type: context.RESET_PRACTICE_REDUCER
})

// SESSION_IN_PROGRESS
export const sessionInProgress = () => ({
    type: context.SESSION_IN_PROGRESS
})

// SESSION_NOT_FOUND
export const sessionNotFound = () => ({
    type: context.SESSION_NOT_FOUND
})

// Completed Free Practice Session
export const paymentRequired = (payload) => ({
    type: context.PAYMENT_REQUIRED,
    payload: payload,
})

// GET Topic Lists Actions
const getTopicRequest = () => ({
    type: context.GET_TOPICS_LIST_REQUEST,
})

const getTopicError = error => ({
    type: context.GET_TOPICS_LIST_ERROR,
    getTopicListError: error
})

const getTopicSuccess = (data) => ({
    type: context.GET_TOPICS_LIST_SUCCESS,
    topicList: data
})

export const getApiTopicList = () => {
    return (dispatch) => {
        dispatch(getTopicRequest())
        getTopicsList().then((res) => {
            if (res?.status === 402) {
                dispatch(paymentRequired(true))
                dispatch(getTopicSuccess(res?.data?.data))
                dispatch(UpdatePaymentPlan(res?.data?.payment_plan))
            } else {
                dispatch(paymentRequired(false))
            }
            if (res?.status === 200) {
                if (res?.data?.is_session_in_progress) {
                    dispatch(sessionInProgress())
                }
                dispatch(UpdatePaymentPlan(res?.data?.payment_plan))
                dispatch(getTopicSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getTopicError(res))
            }
        })
    }
}

// GET SubTopic Lists Actions
const getSubTopicRequest = () => ({
    type: context.GET_SUB_TOPICS_LIST_REQUEST,
})

const getSubTopicError = error => ({
    type: context.GET_SUB_TOPICS_LIST_ERROR,
    getSubTopicListError: error
})

const getSubTopicSuccess = (data) => ({
    type: context.GET_SUB_TOPICS_LIST_SUCCESS,
    subTopicList: data
})

export const getApiSubTopicList = (topic_id) => {
    return (dispatch) => {
        dispatch(getSubTopicRequest())
        getSubTopicsList(topic_id).then((res) => {
            if (res?.status === 402) {
                dispatch(UpdatePaymentPlan(res?.data?.payment_plan))
                dispatch(paymentRequired(true))
                dispatch(getSubTopicSuccess(res?.data?.data))
            } else {
                dispatch(paymentRequired(false))
            }
            if (res?.status === 200) {
                dispatch(UpdatePaymentPlan(res?.data?.payment_plan))
                dispatch(getSubTopicSuccess(res?.data?.data))
                if (res?.data?.is_session_in_progress) {
                    dispatch(sessionInProgress())
                }
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSubTopicError(res))
            }
        })
    }
}

// GET Difficulty Levels Actions
const getDifficultyLevelRequest = () => ({
    type: context.GET_DIFFICULTY_LEVELS_REQUEST,
})

const getDifficultyLevelError = error => ({
    type: context.GET_DIFFICULTY_LEVELS_ERROR,
    getDifficultyLevelError: error
})

const getDifficultyLevelSuccess = (data) => ({
    type: context.GET_DIFFICULTY_LEVELS_SUCCESS,
    difficultyLevels: data
})

export const getApiDifficultyLevels = () => {
    return (dispatch) => {
        dispatch(getDifficultyLevelRequest())
        getDifficultyLevels().then((res) => {
            if (res?.status === 200) {
                dispatch(getDifficultyLevelSuccess(res?.data?.data))
                if (res?.data?.is_session_in_progress) {
                    dispatch(sessionInProgress())
                }
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getDifficultyLevelError(res))
            }
        })
    }
}

// GET Question Formats Actions
const getQuestionFormatsRequest = () => ({
    type: context.GET_QUESTION_FORMATS_REQUEST,
})

const getQuestionFormatsError = error => ({
    type: context.GET_QUESTION_FORMATS_ERROR,
    getQuestionFormatsError: error
})

const getQuestionFormatsSuccess = (data) => ({
    type: context.GET_QUESTION_FORMATS_SUCCESS,
    questionFormats: data
})

export const getApiQuestionFormatsLevels = () => {
    return (dispatch) => {
        dispatch(getQuestionFormatsRequest())
        getQuestionFormats().then((res) => {
            if (res?.status === 200) {
                dispatch(getQuestionFormatsSuccess(res?.data?.data))
                if (res?.data?.is_session_in_progress) {
                    dispatch(sessionInProgress())
                }
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getQuestionFormatsError(res))
            }
        })
    }
}

// GET Instruction Actions
const getInstructionsRequest = () => ({
    type: context.GET_INSTRUCTIONS_REQUEST,
})

const getInstructionsError = error => ({
    type: context.GET_INSTRUCTIONS_ERROR,
    getInstructionError: error
})

const getInstructionsSuccess = (data) => ({
    type: context.GET_INSTRUCTIONS_SUCCESS,
    instructions: data
})

export const getApiInstructions = () => {
    return (dispatch) => {
        dispatch(getInstructionsRequest())
        getInstructions().then((res) => {
            if (res?.status === 200) {
                dispatch(getInstructionsSuccess(res?.data?.data))
                if (res?.data?.is_session_in_progress) {
                    dispatch(sessionInProgress())
                }
            }
            else {
                dispatch(getInstructionsError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}


// POST Prepare Session Actions
const prepareSessionRequest = () => ({
    type: context.PREPARE_SESSION_REQUEST,
})

const prepareSessionError = error => ({
    type: context.PREPARE_SESSION_ERROR,
    prepareSessionError: error
})

const prepareSessionSuccess = (session_id) => ({
    type: context.PREPARE_SESSION_SUCCESS,
    session_id: session_id
})

export const getApiPrepareSession = (data) => {
    return (dispatch) => {
        dispatch(prepareSessionRequest())
        postPrepareSession(data).then((res) => {
            if (res?.status === 200) {
                dispatch(prepareSessionSuccess(res?.data?.data?.session_id))
                dispatch(restAnalyseReducer())
            }
            else {
                dispatch(prepareSessionError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

const getQuestionRequest = () => ({
    type: context.GET_QUESTION_REQUEST,
})

const getQuestionError = error => ({
    type: context.GET_QUESTION_ERROR,
    getQuestionError: error
})

const getQuestionSuccess = (data) => ({
    type: context.GET_QUESTION_SUCCESS,
    question: data
})

const setQuestionProperties = (data) => ({
    type: context.SET_QUESTION_PROPERTIES,
    questionProperties: data
})

const setSessionStatus = (data) => ({
    type: context.SET_SESSION_STATUS,
    sessionStatus: data
})

export const setBookmarkStatus = (data) => ({
    type: context.SET_BOOKMARK_STATUS,
    bookmarkStatus: data
})

export const getApiQuestion = () => {
    return (dispatch) => {
        dispatch(getQuestionRequest())
        getQuestion().then((res) => {
            if (res?.status === 412) {
                dispatch(getApiEndSession())
            }
            if (res?.status === 200) {
                dispatch(getQuestionSuccess(res?.data?.data?.question))
                dispatch(setQuestionProperties(res?.data?.data?.question_properties))
                dispatch(setSessionStatus(res?.data?.data?.practice_session_status))
                dispatch(setBookmarkStatus(res?.data?.data?.bookmark))
            }
            //
            else {
                if (res?.data.message === "Session not found") {
                    dispatch(sessionNotFound())
                }
                dispatch(getQuestionError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

// POST End Session Actions
const endSessionRequest = () => ({
    type: context.END_SESSION_REQUEST,
})

const endSessionError = error => ({
    type: context.END_SESSION_ERROR,
    endSessionError: error
})

const endSessionSuccess = () => ({
    type: context.END_SESSION_SUCCESS,
})

export const getApiEndSession = () => {
    return (dispatch) => {
        dispatch(endSessionRequest())
        dispatch(restAnalyseReducer())
        postEndSession().then((res) => {
            if (res?.status === 200) {
                dispatch(endSessionSuccess())
            }
            else {
                dispatch(endSessionError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

export const selectAnswer = (data) => ({
    type: context.SELECT_ANSWER,
    selectedAnswer: data
})

// POST Submit Answer Actions
const submitAnswerRequest = () => ({
    type: context.SUBMIT_ANSWER_REQUEST,
})

const submitAnswerError = error => ({
    type: context.SUBMIT_ANSWER_ERROR,
    submitAnswerError: error
})

export const submitAnswerSuccess = (data) => ({
    type: context.SUBMIT_ANSWER_SUCCESS,
    data: data
})

export const getApiSubmitAnswer = (data) => {
    return (dispatch) => {
        dispatch(submitAnswerRequest())
        postSubmitAnswer(data).then((res) => {
            if (res?.status === 200) {
                dispatch(submitAnswerSuccess(res?.data?.data))
                dispatch(setSessionStatus(res?.data?.data?.practice_session_status))
            }
            else {
                if (res?.data?.message === "No active practice session found. Start a session first to answer questions.") {
                    dispatch(sessionNotFound())
                }
                dispatch(submitAnswerError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

// POST Skip Answer Actions
const skipAnswerRequest = () => ({
    type: context.SKIP_QUESTION_REQUEST,
})

const skipAnswerError = error => ({
    type: context.SKIP_QUESTION_ERROR,
    skipAnswerError: error
})

export const skipAnswerSuccess = (data) => ({
    type: context.SKIP_QUESTION_SUCCESS,
    data: data
})

export const getApiSkipAnswer = (question_id) => {
    return (dispatch) => {
        dispatch(skipAnswerRequest())
        postSkipQuestion(question_id).then((res) => {
            if (res?.status === 200) {
                dispatch(skipAnswerSuccess(res?.data?.data))
                dispatch(setSessionStatus(res?.data?.data?.practice_session_status))
            }
            else {
                if (res?.data?.message === "No active practice session found. Start a session first to answer questions.") {
                    dispatch(sessionNotFound())
                }
                dispatch(skipAnswerError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

// POST Report Error Actions
const reportErrorRequest = () => ({
    type: context.REPORT_ERROR_REQUEST,
})

const reportErrorError = error => ({
    type: context.REPORT_ERROR_ERROR,
    reportErrorError: error
})

export const reportErrorSuccess = () => ({
    type: context.REPORT_ERROR_SUCCESS,
})

export const getApiReportError = (data) => {
    return (dispatch) => {
        dispatch(reportErrorRequest())
        postReportError(data).then((res) => {
            if (res?.status === 200) {
                dispatch(reportErrorSuccess())
            }
            else {
                dispatch(reportErrorError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

// POST PAUSE SESSION Actions
const pauseSessionRequest = () => ({
    type: context.PAUSE_SESSION_REQUEST,
})

const pauseSessionError = error => ({
    type: context.PAUSE_SESSION_ERROR,
    pauseSessionError: error
})

export const pauseSessionSuccess = () => ({
    type: context.PAUSE_SESSION_SUCCESS,
})

export const getApiPauseSession = (time) => {
    return (dispatch) => {
        dispatch(pauseSessionRequest())
        postPauseSession(time).then((res) => {
            if (res?.status === 200) {
                dispatch(pauseSessionSuccess())
            }
            else {
                dispatch(pauseSessionError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

// POST RESUME SESSION Actions
const resumeSessionRequest = () => ({
    type: context.RESUME_SESSION_REQUEST,
})

const resumeSessionError = error => ({
    type: context.RESUME_SESSION_ERROR,
    resumeSessionError: error
})

export const resumeSessionSuccess = () => ({
    type: context.RESUME_SESSION_SUCCESS,
})

export const getApiResumeSession = () => {
    return (dispatch) => {
        dispatch(resumeSessionRequest())
        postResumeSession().then((res) => {
            if (res?.status === 200) {
                dispatch(resumeSessionSuccess())
                dispatch(ShowToast({ "message": res?.data?.message ?? "Session Resumed !" }))
            }
            else {
                dispatch(resumeSessionError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

export const setPauseTimer = (timer) => ({
    type: context.SET_PAUSE_TIMER,
    timer: timer
})

export const setPauseTime = (time) => ({
    type: context.SET_PAUSE_TIME,
    selectedTime: time
})

export const setPauseTimerDate = (date) => ({
    type: context.SET_TIMER_DATE,
    pauseTimerDate: date
})

// GET SESSION QUESTION STATUS
const getSessionQuestionStatusRequest = () => ({
    type: context.GET_SESSION_QUESTION_STATUS_REQUEST,
})

const getSessionQuestionStatusError = error => ({
    type: context.GET_SESSION_QUESTION_STATUS_ERROR,
    getSessionQuestionStatusError: error
})

const getSessionQuestionStatusSuccess = (data) => ({
    type: context.GET_SESSION_QUESTION_STATUS_SUCCESS,
    is_question_ready: data?.is_question_ready
})

export const getApiSessionQuestionStatus = () => {
    return (dispatch) => {
        dispatch(getSessionQuestionStatusRequest())
        getSessionQuestionStatus().then((res) => {
            if (res?.status === 412) {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
            if (res?.status === 200) {
                dispatch(getSessionQuestionStatusSuccess(res?.data?.data))
            }
            else {
                if (res?.data?.message === "No active session found.") {
                    dispatch(sessionNotFound())
                }
                dispatch(getSessionQuestionStatusError(res))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
            }
        })
    }
}

export const updateSessionInProgessModalState = (data) => ({
    type: context.UPDATE_SESSION_PROGRESS_MODAL_STATE,
    isSessionInProgressModalClosed: data
})

export const updateSessionId = (id) => ({
    type: context.UPDATE_SESSION_ID,
    session_id: id,
})