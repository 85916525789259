import React from 'react'
import { Checkbox, FormGroup, Grid, Skeleton } from "@mui/material"
import styles from "./QuestionContainer.module.scss"
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material/';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
//import { stripHtml } from '../assets';
import OptionSkeleton from '../Skeletons/OptionSkeleton';
//import Latex from 'react-latex';
import { renderToHtmlString } from '../assets/utils/utils';
import { ZoomableImage } from '..';

const QuestionContainer = () => {
    const dispatch = useDispatch()
    const practiceState = useSelector(state => state.practice)
    const { selectedAnswer, submitAnswerStatus } = practiceState || {}
    const { correct_answers = [], wrong_attempts = [], answer_correctness } = submitAnswerStatus || {}

    const { after_image_text,
        image_url,
        options = [],
        question_text,
        question_no,
        question_id,
        question_type } = practiceState.question || {}

    const isLoading = !Boolean(question_id)

    const { selectAnswer, submitAnswerSuccess } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const { resetHintSolution } = bindActionCreators(
        actions.ui,
        dispatch
    );

    React.useEffect(() => {
        selectAnswer([])
        submitAnswerSuccess({})
        resetHintSolution() // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question_id])

    const handleAnswerSelection = (item) => {
        if (answer_correctness) {
            return
        }
        if (question_type === "single_correct") {
            selectAnswer([{ ...item }])
        } else {
            let selectedAnswers = [...selectedAnswer]
            if (selectedAnswers.includes(item)) {
                let index = selectedAnswers.indexOf(item)
                selectedAnswers.splice(index, 1)
            } else {
                selectedAnswers.push(item)
            }
            selectAnswer(selectedAnswers)
        }
    }

    return (
        <Grid container>
            <div className={styles.question_container}>
                <div className={styles.question}>
                    <div className={styles.question_heading}>
                        <div className={styles.question_heading_details}>
                            <div className={styles.question_id}>
                                {
                                    !isLoading ?
                                        <span className="notranslate">Q{question_no}</span>
                                        :
                                        <Skeleton variant="text" width={25} height={30} />
                                }
                            </div>
                            {
                                !isLoading ?
                                    <div className="scrollable-katex" dangerouslySetInnerHTML={{ __html: renderToHtmlString(question_text) }}>
                                        {/* <p><Latex displayMode>{stripHtml(question_text)}</Latex></p> */}
                                    </div>
                                    :
                                    <div>
                                        <Skeleton variant="text" width={500} height={30} />
                                        <Skeleton variant="text" width={500} height={30} />
                                    </div>
                            }
                        </div>
                        <div className={styles.question_img}>
                            {
                                !isLoading ?
                                    // image_url && <img src={image_url} alt="QuestionImage"></img>
                                    image_url && <ZoomableImage src={image_url} alt="QuestionImage" />
                                    :
                                    <Skeleton variant="rectangle" width={500} height={260} />
                            }
                        </div>
                        <div className={styles.question_heading_details}>
                            <div></div>
                            <div className={styles.text_after_image}>
                                {
                                    !isLoading ?
                                        <div dangerouslySetInnerHTML={{ __html: renderToHtmlString(after_image_text) }} />
                                        :
                                        <div>
                                            <Skeleton variant="text" width={450} height={30} />
                                            <Skeleton variant="text" width={400} height={30} />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.radio_list} scrollable-katex`}>
                        <FormControl>

                            {
                                isLoading &&
                                <div>
                                    <OptionSkeleton />
                                    <OptionSkeleton />
                                    <OptionSkeleton />
                                    <OptionSkeleton />
                                </div>
                            }

                            {!isLoading && options.length > 0 && (
                                question_type === "single_correct" ?
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        className={styles.questionLabels}
                                    >
                                        {
                                            options.map((option) => (
                                                <FormControlLabel
                                                    htmlFor={option.id}
                                                    id={option.id}
                                                    className={correct_answers?.some((item) => item.id === option.id) ? `${styles.correct} ${styles.option_entry}`
                                                        : wrong_attempts?.some((item) => item.id === option.id) ? `${styles.incorrect} ${styles.option_entry}`
                                                            : styles.option_entry}
                                                    onClick={() => handleAnswerSelection(option)}
                                                    key={option.id} value={option.id}
                                                    control={<Radio checked={selectedAnswer?.some((item) => item.id === option.id)} color="default" />}
                                                    label={<span className={`${styles.label}`}><span dangerouslySetInnerHTML={{ __html: renderToHtmlString(option.label) }} />{/* <Latex>{stripHtml(option.label)}</Latex> */}</span>}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                    :
                                    <FormGroup>
                                        {
                                            options.map((option) => (
                                                <FormControlLabel
                                                    htmlFor={option.id}
                                                    id={option.id}
                                                    className={correct_answers?.some((item) => item.id === option.id) ? `${styles.correct} ${styles.option_entry}`
                                                        : wrong_attempts?.some((item) => item.id === option.id) ? `${styles.incorrect} ${styles.option_entry}`
                                                            : styles.option_entry}
                                                    onClick={() => handleAnswerSelection(option)}
                                                    key={option.id}
                                                    value={option.id}
                                                    control={<Checkbox color="default" checked={selectedAnswer?.some((item) => item.id === option.id)} />}
                                                    label={<span className={`${styles.label}`}><span dangerouslySetInnerHTML={{ __html: renderToHtmlString(option.label) }} />{/* <Latex>{stripHtml(option.label)}</Latex> */}</span>}
                                                />
                                            ))
                                        }
                                    </FormGroup>)

                            }

                        </FormControl>
                    </div>
                </div>
            </div>
        </Grid >
    )
}

export default QuestionContainer
