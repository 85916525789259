import React from "react";
import { Routes, Route, Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import SignUpPage from "./pages/authenticate/SignUpPage";
import LoginWithOTPPage from "./pages/authenticate/LoginWithOTPPage";
import LoginWithPasswordPage from "./pages/authenticate/LoginWithPasswordPage";
import ForgotPasswordPage from "./pages/authenticate/ForgotPasswordPage";
import Error404 from "./pages/error/Error404";
import PracticePage from "./pages/practice/PracticePage";
import QuestionPage from "./pages/practice/QuestionPage";
import SelectSubTopicPage from "./pages/practice/SelectSubTopicPage";
import SelectDifficultyPage from "./pages/practice/SelectDifficultyPage";
import InstructionPage from "./pages/practice/InstructionPage";
import DashboardPage from "./pages/dashboard/DashboardPage";
import AnalysePage from "./pages/analyse/AnalysePage";
import BookmarksPage from "./pages/bookmarks/BookmarksPage";
import ProfilePage from "./pages/profile/ProfilePage";
import PracticeHistoryPage from "./pages/analyse/PracticeHistoryPage";
import { useDispatch, useSelector } from "react-redux";
import SignOutPage from "./pages/authenticate/SignOutPage";
import ConfirmationDialog from "./components/Overlays/ConfirmationDialog";
import { UpgradeModal } from "./components";
import { bindActionCreators } from "redux";
import { actions } from "./redux";
import Website from "./pages/landingSite/websitePage";
import { getSessionStatus } from "./api/apiCalls";
import Terms from "./pages/landingSite/terms";
import PrivacyPolicy from "./pages/landingSite/privacyPolicy";
import RazorpayDemo from './pages/razorpayDemo/razorpayDemo'
import PaymentPage from "./pages/payment/PaymentPage";

export const SiteMap = {
	HomePage: {
		title: "StuddictEd",
		path: "/auth",
		public_url: process.env.REACT_APP_LEARNER_SITE_URL,
		description: "StuddictEd Home Page",
	},
	PasswordLoginPage: {
		title: "Login with Password",
		path: "/login",
		description: "User login page using precreated password",
	},
	OTPLoginPage: {
		title: "Login with OTP",
		path: "/login/otp",
		description: "User login page using One-time password",
	},
	SignupPage: {
		title: "Sign Up",
		path: "/signup",
		description: "Create new account",
	},
	ForgotPasswordPage: {
		title: "Forgot Password",
		path: "/forgot-password",
		description: "Forgot Password",
	},
	SignOutPage: {
		title: "Sign Out",
		path: "/signout",
		description: "Force Sign Out",
	},
	PracticePage: {
		title: "Practice",
		path: "/practice/topics",
		description: "Practice Page",
	},
	SelectSubTopicPage: {
		title: "Select Sub Topics",
		path: "/practice/subtopics",
		description: "Select Sub Topics for Practice Session",
	},
	SelectDifficultyPage: {
		title: "Select Difficulty",
		path: "/practice/difficulty",
		description: "Select Difficulty and Questions format for Practice Session",
	},
	InstructionPage: {
		title: "Instruction Page",
		path: "/practice/instructions",
		description: "Read instructions for Practice Session",
	},
	QuestionPage: {
		title: "Question",
		path: "/practice",
		description: "Question Page",
	},
	DashboardPage: {
		title: "Dashboard",
		path: "/dashboard",
		description: "Dashboard Page",
	},
	ProfilePage: {
		title: "Profile",
		path: "/profile",
		description: "Profile Page",
	},
	AnalysePage: {
		title: "Analyse",
		path: "/analytics/session",
		description: "Analyse Page",
	},
	PracticeHistoryPage: {
		title: "Practice History Page",
		path: "/analytics",
		description: "Practice History Page",
	},
	BookmarkPage: {
		title: "Bookmark Page",
		path: "/bookmarks",
		description: "Bookmarks Page"
	},
	LandingWebsite: {
		title: "Landing Website",
		path: "/",
		public_url: process.env.REACT_APP_STATIC_SITE_URL,
		description: "Landing Website",
	},
	Terms: {
		title: "Terms of services",
		path: "/term-of-services",
		description: "Terms of services Page",
	},
	PrivacyPolicy: {
		title: "Privacy Policy",
		path: "/privacy-policy",
		description: "Privacy Policy Page",
	},
	PaymentPage: {
		title: "Payment",
		path: "/payment",
		description: "Payment Page",
	}
};

const PrivateRoutesWrapper = ({ isUser }) => {
	return isUser ? <Outlet /> : <Navigate to={SiteMap.HomePage.path} />;
}

const PublicRoutesWrapper = ({ isUser }) => {
	return !isUser ? <Outlet /> : <Navigate to={SiteMap.DashboardPage.path} />;
}

const RouteEntries = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const authState = useSelector(state => state.auth)
	const practiceState = useSelector(state => state.practice)
	const { isSessionInProgressModalClosed = false } = practiceState
	const { isSessionValid, payment_plan } = authState

	const { getApiEndSession, sessionInProgress, sessionNotFound, updateSessionInProgessModalState, updateSessionId } = bindActionCreators(
		actions.practice,
		dispatch
	);

	const { selectTopics } = bindActionCreators(
		actions.ui,
		dispatch
	)

	const [confirmationDialog, setConfirmationDialog] = React.useState(false)
	const [showFreeSessionModal, setShowFreeSessionModal] = React.useState(false);

	const handleEndSession = () => {
		updateSessionInProgessModalState(false)
		getApiEndSession()
		selectTopics([])
		setConfirmationDialog(false)
		navigate({ pathname: SiteMap.AnalysePage.path }, { state: { session_id: practiceState.session_id, needPolling: true } });
	}

	const handleResumeSession = () => {
		updateSessionInProgessModalState(false)
		navigate(SiteMap.QuestionPage.path)
		setConfirmationDialog(false)
	}

	const checkSessionInProgress = () => {
		getSessionStatus().then((res) => {
			let { is_session_in_progress, session_id } = res?.data?.data || {}
			if (is_session_in_progress) {
				updateSessionId(session_id)
				setConfirmationDialog(true)
				sessionInProgress()
			} else {
				sessionNotFound()
				setConfirmationDialog(false)
			}
		})
	}

	React.useEffect(() => {
		if (isSessionValid && !isSessionInProgressModalClosed && location.pathname !== SiteMap.QuestionPage.path) {
			checkSessionInProgress()
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSessionValid])

	React.useEffect(() => {
		if (isSessionValid
			&& location.pathname.includes(SiteMap.QuestionPage.path)
			&& location.pathname !== SiteMap.QuestionPage.path) {
			checkSessionInProgress()
		}

		if (isSessionValid && ((payment_plan !== "Paid" && payment_plan !== "Free") || practiceState.paymentRequired) &&
			location.pathname.includes(SiteMap.QuestionPage.path)) {
			setShowFreeSessionModal(true)
		} else { setShowFreeSessionModal(false) }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, payment_plan])

	return (
		<>
			<ConfirmationDialog
				loading={practiceState.loading}
				open={confirmationDialog}
				handleClose={() => handleResumeSession()}
				handleCloseIcon={() => { setConfirmationDialog(false); updateSessionInProgessModalState(true) }}
				handleOk={() => handleEndSession()}
				message={"A session is in progress. You can resume the session or end the session to start a new one."}
				buttonOneText={"Resume Practice Session"}
				buttonTwoText={"End Ongoing Practice Session"}
			/>
			<UpgradeModal open={showFreeSessionModal} handleClose={() => setShowFreeSessionModal(false)} />

			<Routes>

				<Route exact path={SiteMap.LandingWebsite.path} element={<Website />} />
				<Route exact path={SiteMap.Terms.path} element={<Terms />} />
				<Route exact path={SiteMap.PrivacyPolicy.path} element={<PrivacyPolicy />} />

				{/* Force SignOut Clearing All States For Developement Purpose */}
				<Route path={SiteMap.SignOutPage.path} element={<SignOutPage />} />

				{/* Public Routes */}
				<Route path="/" element={<PublicRoutesWrapper isUser={isSessionValid} />}>
					<Route path={SiteMap.HomePage.path} element={<HomePage />} />
					<Route path={SiteMap.SignupPage.path} element={<SignUpPage />} />
					<Route path={SiteMap.PasswordLoginPage.path} element={<LoginWithPasswordPage />} />
					<Route path={SiteMap.OTPLoginPage.path} element={<LoginWithOTPPage />} />
					<Route path={SiteMap.ForgotPasswordPage.path} element={<ForgotPasswordPage />} />
				</Route>
				{/* Private Routes */}
				<Route path="/" element={<PrivateRoutesWrapper isUser={isSessionValid} />}>
					<Route path={SiteMap.DashboardPage.path} element={<DashboardPage />} />
					<Route path={SiteMap.PracticePage.path} element={<PracticePage openUpgradeModal={() => setShowFreeSessionModal(true)} />} />
					<Route path={SiteMap.SelectSubTopicPage.path} element={<SelectSubTopicPage />} />
					<Route path={SiteMap.SelectDifficultyPage.path} element={<SelectDifficultyPage />} />
					<Route path={SiteMap.BookmarkPage.path} element={<BookmarksPage />} />
					<Route path={SiteMap.InstructionPage.path}
						element={<InstructionPage
							openSessionInProgressModal={() => setConfirmationDialog(true)}
							openFreeSessionModal={() => setShowFreeSessionModal(true)}
						/>}
					/>
					<Route path={SiteMap.QuestionPage.path} element={<QuestionPage />} />
					<Route path={SiteMap.AnalysePage.path} element={<AnalysePage />} />
					<Route path={SiteMap.PracticeHistoryPage.path} element={<PracticeHistoryPage />} />
					<Route path={SiteMap.ProfilePage.path} element={<ProfilePage />} />

					<Route exact path="/razopay-demo" element={<RazorpayDemo />} />
					<Route exact path={SiteMap.PaymentPage.path} element={<PaymentPage />} />
				</Route>

				<Route path="*" element={<Error404 />} />
			</Routes >
		</>
	);
};

export default RouteEntries;
