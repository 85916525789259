import { DASHBOARD_CONTEXT as context } from '../context'

const DashboardInitialState = {
    loading: false,
    practiceSummaryLoading: false,
    topSuggestionsLoading: false,
    abilityAnalysisLoading: false,
    conceptAnalysisLoading: false,
    success: false,
    practiseSummary: {},
    topSuggestions: {},
    conceptAnalysis: {},
    abilityAnalysis: {},
    welcomeModalClosed: false,
    currentAction: "INITIAL_STATE"
};

export const DashboardReducer = (state = DashboardInitialState, action) => {
    switch (action.type) {
        case context.RESET_DASHBOARD_REDUCER:
            return DashboardInitialState;

        case context.WELCOME_MODAL_CLOSED:
            return {
                ...state,
                welcomeModalClosed: true,
            }

        case context.GET_PRACTICE_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                practiceSummary: {},
                practiceSummaryLoading: true,
                currentAction: action.type
            };

        case context.GET_PRACTICE_SUMMARY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                practiceSummaryLoading: false,
                getPracticeSummaryError: action.getPracticeSummaryError,
                currentAction: action.type
            };

        case context.GET_PRACTICE_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                practiceSummaryLoading: false,
                getPracticeSummaryError: undefined,
                practiceSummary: action.practiceSummary,
                currentAction: action.type
            };

        case context.GET_TOP_SUGGESTIONS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                topSuggestions: {},
                topSuggestionsLoading: true,
                currentAction: action.type
            };

        case context.GET_TOP_SUGGESTIONS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                topSuggestionsLoading: false,
                getTopSuggestionError: action.getTopSuggestionError,
                currentAction: action.type
            };

        case context.GET_TOP_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                topSuggestionsLoading: false,
                getTopSuggestionError: undefined,
                topSuggestions: action.topSuggestions,
                currentAction: action.type
            };

        case context.GET_CONCEPT_ANALYSIS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                conceptAnalysis: {},
                conceptAnalysisLoading: true,
                currentAction: action.type
            };

        case context.GET_CONCEPT_ANALYSIS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                conceptAnalysisLoading: false,
                getConceptAnalysisError: action.getConceptAnalysisError,
                currentAction: action.type
            };

        case context.GET_CONCEPT_ANALYSIS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                conceptAnalysisLoading: false,
                getConceptAnalysisError: undefined,
                conceptAnalysis: action.conceptAnalysis,
                currentAction: action.type
            };

        case context.GET_ABILITY_ANALYSIS_REQUEST:
            return {
                ...state,
                loading: true,
                abilityAnalysis: {},
                abilityAnalysisLoading: true,
                success: false,
                currentAction: action.type
            };

        case context.GET_ABILITY_ANALYSIS_ERROR:
            return {
                ...state,
                loading: false,
                abilityAnalysisLoading: false,
                success: false,
                getAbilityAnalysisError: action.getAbilityAnalysisError,
                currentAction: action.type
            };

        case context.GET_ABILITY_ANALYSIS_SUCCESS:
            return {
                ...state,
                loading: false,
                abilityAnalysisLoading: false,
                success: true,
                getAbilityAnalysisError: undefined,
                abilityAnalysis: action.abilityAnalysis,
                currentAction: action.type
            };

        default:
            return state;
    }
};