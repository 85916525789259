import React from "react";
import { Grid, Box, Button, Skeleton } from "@mui/material"
import styles from "./QuestionSidebar.module.scss"
import { useDispatch, useSelector } from "react-redux/";
import { SkipIcon, SolidCheckCorrect } from '../../components/assets';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
import { SessionStatusItem } from "..";
import { renderToHtmlString } from "../assets/utils/utils";

const QuestionSidebar = () => {
    const dispatch = useDispatch()
    const UI = useSelector(state => state.UI)
    const practiceState = useSelector(state => state.practice)
    const SessionStatusBoxRef = React.useRef(null)
    const {
        showHint = false,
        showSolution = false,
        displaySideMenuHint = false,
        displaySideMenuSolution = false } = UI.question || {}
    const { question, questionProperties = [], sessionStatus, sessionStatusLoading } = practiceState || {}

    const {
        question_id,
        hint,
        solution,
    } = question || {}

    const { toggleHint, toggleSolution, resetHintSolution } = bindActionCreators(
        actions.ui,
        dispatch
    );

    React.useEffect(() => {
        resetHintSolution() // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!showHint) {
            SessionStatusBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
    }, [showHint])

    React.useEffect(() => {
        if (!showSolution) {
            SessionStatusBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }
    }, [showSolution])

    return (
        <Grid container>
            <Box className={styles.question_sidebar}>
                <Box className={styles.question_properties}>
                    <h4>Question Properties</h4>
                    <Box className={styles.question_properties_inner}>
                        {
                            question_id ?
                                Object.values(questionProperties).map((item, index) => (
                                    Object.keys(questionProperties)[index] === "difficulty" ? <p key={index}>Difficulty: {item}</p>
                                        : <p key={index}>{item}</p>
                                ))
                                :
                                <>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="text" />
                                </>
                        }
                    </Box>
                </Box>
                <Box className={styles.question_update} ref={SessionStatusBoxRef}>
                    <ul>
                        <SessionStatusItem
                            loading={sessionStatusLoading}
                            className={styles.text_sky}
                            icon={<SkipIcon />}
                            label="Skipped Questions"
                            value={sessionStatus?.skipped_questions} />
                        <SessionStatusItem
                            loading={sessionStatusLoading}
                            className={styles.text_green}
                            icon={<SolidCheckCorrect />}
                            label="Perfect Attempts"
                            value={sessionStatus?.perfect_attempts} />
                        <SessionStatusItem
                            loading={sessionStatusLoading}
                            className={styles.text_yellow}
                            icon={<GppMaybeOutlinedIcon />}
                            label="Partially Correct"
                            value={sessionStatus?.partially_correct} />
                        <SessionStatusItem
                            loading={sessionStatusLoading}
                            className={styles.text_red}
                            icon={<CancelOutlinedIcon />}
                            label="Incorrect Attempt"
                            value={sessionStatus?.incorrect_attempts} />
                    </ul>
                </Box>
                {
                    displaySideMenuHint &&
                    <Box className={`${styles.right_animated_box} ${showHint ? styles.animated_box_active : ""}`} >
                        <Button className={styles.right_animated_box_btn} onClick={() => toggleHint(true)}>
                            <LightbulbIcon />
                            Hint
                        </Button>
                        <Box className={styles.backdrop} onClick={() => toggleHint(false)}></Box>
                        <Box className={styles.right_animated_box_inner}>
                            <h4>
                                <LightbulbIcon />
                                Hint

                            </h4>
                            <Button disabled={!showHint} className={styles.right_animated_box_close} onClick={() => toggleHint(false)}>
                                <HighlightOffOutlinedIcon />
                            </Button>
                            <Box className={styles.content}>
                                {/* <Latex>{stripHtml(hint)}</Latex> */}
                                <div className="scrollable-katex" dangerouslySetInnerHTML={{ __html: renderToHtmlString(hint) }}></div>
                            </Box>
                            {/* <p dangerouslySetInnerHTML={{ __html: hint }}></p> */}
                        </Box>
                    </Box>
                }
                {
                    displaySideMenuSolution &&
                    <Box className={`${styles.right_animated_box} ${showSolution ? styles.animated_box_active : ""}`} >
                        <Button className={styles.right_animated_box_btn} onClick={() => toggleSolution(true)}>
                            <WbIncandescentIcon className="rotate-180D" />
                            Solution
                        </Button>
                        <Box className={styles.backdrop} onClick={() => toggleSolution(false)}></Box>
                        <Box className={styles.right_animated_box_inner}>
                            <h4>
                                <WbIncandescentIcon className="rotate-180D" />
                                Solution

                            </h4>
                            <Button disabled={!showSolution} className={styles.right_animated_box_close} onClick={() => toggleSolution(false)}>
                                <HighlightOffOutlinedIcon />
                            </Button>
                            <Box className={styles.content}>
                                <div className="scrollable-katex" dangerouslySetInnerHTML={{ __html: renderToHtmlString(solution) }}></div>
                                {/* <Latex>{stripHtml(solution)}</Latex> */}
                            </Box>
                            {/* <p dangerouslySetInnerHTML={{ __html: solution }}></p> */}
                        </Box>
                    </Box>
                }
            </Box>
        </Grid >
    )
}

export default QuestionSidebar