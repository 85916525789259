import * as Yup from "yup";
import { DefaultMessagesForRegx, Regx } from "..";

const SignUpFormStepOneValidator = Yup.object().shape({
    first_name: Yup.string().trim()
        .required("First Name is required")
        .min(1, "Too Short")
        .max(50, "Too long")
        .matches(Regx.textWithSpclChar, DefaultMessagesForRegx.noSpclCharAllowed)
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noNumbersAllowed),
    last_name: Yup.string().trim()
        .required("Last Name is required")
        .min(1, "Too Short")
        .max(50, "Too long")
        .matches(Regx.textWithSpclChar, DefaultMessagesForRegx.noSpclCharAllowed)
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noNumbersAllowed),
    mobile_number: Yup.string()
        .required("Mobile Number is required")
        .matches(Regx.mobile, "Invalid Mobile Number"),
    email_id: Yup.string()
        .required("Email is required")
        .max(60, "Too long")
        .matches(Regx.email, "Invalid Email"),
});

const SignUpFormStepTwoValidator = Yup.object().shape({
    parent_name: Yup.string().nullable()
        .min(1, "Too Short")
        .max(60, "Too long")
        .matches(Regx.textWithSpclChar, DefaultMessagesForRegx.noSpclCharAllowed)
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noNumbersAllowed),
    parent_mobile: Yup.string()
        .required("Parent's Mobile is required")
        .matches(Regx.mobile, "Invalid Mobile Number"),
    school: Yup.string().trim()
        .required("School is required")
        .min(1, "Too Short")
        .max(100, "Too long")
        .matches(Regx.textWithMoreSpclChar, DefaultMessagesForRegx.textWithMoreSpclChar),
    city: Yup.string().trim()
        .required("City is required")
        .min(1, "Too Short")
        .max(50, "Too long")
        .matches(Regx.textWithSpclChar, DefaultMessagesForRegx.textWithSpclChar),
    gender: Yup.string()
        .required("Gender is required")
        .min(1, "Too Short")
        .max(10, "Too long")
        .matches(Regx.plainText, "Invalid Gender type"),
    student_class: Yup.string()
        .required("Class is required")
        .max(5, "Too long")
        .matches(Regx.number, "Invalid Class"),
});

const LoginWithPasswordValidator = Yup.object().shape({
    user_contact: Yup.string()
        .required("Email/Mobile is required")
        .max(60, "Too long")
        .matches(Regx.customEmailOrPhone, "Invalid Email or Mobile"),
    password: Yup.string()
        .required("Password is required")
        .max(60, "Too long")
})

const EmailOrPhoneValidator = Yup.object().shape({
    user_contact: Yup.string()
        .required("Email/Mobile is required")
        .max(60, "Too long")
        .matches(Regx.emailOrPhone, "Invalid Email or Mobile")
})

const ProfileFormValidator = SignUpFormStepOneValidator.concat(SignUpFormStepTwoValidator).concat(
    Yup.object().shape({
        branch: Yup.string().nullable()
            .max(60, "Too long"),
        parent_relation: Yup.string().nullable()
            .max(60, "Too long"),
        date_of_birth: Yup.string()
            .nullable()
    })
)

const PasswordValidator = Yup.object({
    n_password: Yup.string().required('Password is required'),
    password: Yup.string().required('Password is required')
        .oneOf([Yup.ref('n_password'), null], 'Passwords do not match')
});

const LandingSiteSendMessageValidator = Yup.object().shape({
    full_name: Yup.string()
        .required("Your Name is required")
        .min(1, "Too Short")
        .max(50, "Too long")
        .matches(Regx.textWithSpclChar, DefaultMessagesForRegx.noSpclCharAllowed)
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noNumbersAllowed),
    email: Yup.string()
        .required("Email is required")
        .max(60, "Too long")
        .matches(Regx.email, "Invalid Email"),
    mobile_number: Yup.string()
        .required("Mobile Number is required")
        .matches(Regx.mobile, "Invalid Mobile Number"),
    message: Yup.string()
        .required("Message field is required")
        .min(1, "Too Short")
        .max(256, "Too long")
});

const upgradeFormValidator = Yup.object().shape({
    full_name: Yup.string()
        .required("Your Name is required")
        .min(1, "Too Short")
        .max(50, "Too long")
        .matches(Regx.textWithSpclChar, DefaultMessagesForRegx.noSpclCharAllowed)
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noNumbersAllowed),
    email: Yup.string()
        .required("Email is required")
        .max(60, "Too long")
        .matches(Regx.email, "Invalid Email"),
    mobile_number: Yup.string()
        .required("Mobile Number is required")
        .matches(Regx.mobile, "Invalid Mobile Number"),
    state: Yup.string()
        .required("State is required")
        .min(1, "Too Short")
        .max(50, "Too long")
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noDigitsSpclCharAllowed),
    reference_of: Yup.string()
        .matches(Regx.plaintTextWithoutDigits, DefaultMessagesForRegx.noDigitsSpclCharAllowed),
})

const paymentFormValidator = SignUpFormStepOneValidator.concat(
    Yup.object().shape({
        billing_country: Yup.string().required("Billing Country is required"),
        billing_state: Yup.string().required("Billing State is required"),
        shipping_country: Yup.string().required("Shipping Country is required"),
        shipping_state: Yup.string().required("Shipping State is required"),
    })
)

export {
    SignUpFormStepOneValidator,
    SignUpFormStepTwoValidator,
    LoginWithPasswordValidator,
    EmailOrPhoneValidator,
    ProfileFormValidator,
    PasswordValidator,
    LandingSiteSendMessageValidator,
    upgradeFormValidator,
    paymentFormValidator
}