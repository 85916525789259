const chartConfig = {
    chart: {
        toolbar: {
            show: false
        },
        background: '#fff',
        height: 350,
        type: 'bar',
    },
    plotOptions: {
        bar: {
            borderRadius: 15,
            borderRadiusOnAllStackedSeries: true,
            columnWidth: '40%',
            distributed: true,
        }
    },
    colors: ['#7444FC'],
    fill: { opacity: 1 },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            return `${val}%`
        },
    },
    legend: {
        show: false
    },
}

export {
    chartConfig
}