import { postSendMessage } from '../../api/apiCalls';
import { LANDING_SITE_CONTEXT as context } from '../context';
import { ShowToast } from './ToastActions';

// POST Send Message Actions
const postSendMessageRequest = () => ({
    type: context.POST_SEND_MESSAGE_REQUEST,
})

const postSendMessageError = error => ({
    type: context.POST_SEND_MESSAGE_ERROR,
    postSendMessageError: error
})

const postSendMessageSuccess = () => ({
    type: context.POST_SEND_MESSAGE_SUCCESS,
})

export const postApiSendMessage = (data) => {
    return (dispatch) => {
        dispatch(postSendMessageRequest())
        postSendMessage(data).then((res) => {
            if (res?.status === 200) {
                dispatch(postSendMessageSuccess())
                dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(postSendMessageError(res))
            }
        })
    }
}