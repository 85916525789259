import React from "react";
import { Field } from "formik";
import { FormHelperText, TextareaAutosize } from "@mui/material";

const FormikTextArea = props => {
    const {
        variant,
        name,
        label,
        placeholder,
        defaultValue,
        className,
        onChange,
        value,
        disabled,
        minRows
    } = props;

    const Content = ({ form, meta }) => {
        return (
            <React.Fragment>
                <TextareaAutosize
                    aria-label="textarea"
                    minRows={minRows || 3}
                    name={name}
                    id={name}
                    value={(value || meta.value) ?? ""}
                    placeholder={placeholder}
                    label={label}
                    variant={variant || "outlined"}
                    defaultValue={meta.initialValue || defaultValue}
                    className={className || ""}
                    onChange={onChange}
                    disabled={disabled}
                />
                {
                    (meta.touched || form.submitCount > 0) && Boolean(meta.error) &&
                    <FormHelperText className="form-errors">{meta.error}</FormHelperText>
                }
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Field name={name}>{Content}</Field>
        </React.Fragment>
    );
};

export default FormikTextArea;
