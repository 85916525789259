import React from 'react';
import { Grid, Box, Skeleton } from "@mui/material";
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import styles from "./website.module.scss";
import { Container } from "@mui/material";

import homepage_img from "../../components/assets/media/landingSiteMedia/homepage_img.png";
import whatsapp from "../../components/assets/media/landingSiteMedia/whatsapp.png";
import card_line1 from "../../components/assets/media/landingSiteMedia/card_line1.png";
import card_line2 from "../../components/assets/media/landingSiteMedia/card_line2.png";
import card_line3 from "../../components/assets/media/landingSiteMedia/card_line3.png";
import learn from "../../components/assets/media/landingSiteMedia/learn.png";
import Practice from "../../components/assets/media/landingSiteMedia/Practice.png";
import Analyse from "../../components/assets/media/landingSiteMedia/Analyse.png";
import Revise from "../../components/assets/media/landingSiteMedia/Revise.png";
import Nishant from "../../components/assets/media/landingSiteMedia/Nishant.png";
import Mridul from "../../components/assets/media/landingSiteMedia/Mridul.png";
import Siva from "../../components/assets/media/landingSiteMedia/Siva.png";
import Ajay from "../../components/assets/media/landingSiteMedia/Ajay.png";

import animated_one from "../../components/assets/media/landingSiteMedia/animated_one.gif";
import animated_two from "../../components/assets/media/landingSiteMedia/animated_two.gif";
import animated_three from "../../components/assets/media/landingSiteMedia/animated_three.gif";
import animated_four from "../../components/assets/media/landingSiteMedia/animated_four.gif";

import one from "../../components/assets/media/landingSiteMedia/one.png";
import two from "../../components/assets/media/landingSiteMedia/two.png";
import three from "../../components/assets/media/landingSiteMedia/three.png";
import four from "../../components/assets/media/landingSiteMedia/four.png";
import five from "../../components/assets/media/landingSiteMedia/five.png";
import six from "../../components/assets/media/landingSiteMedia/six.png";
import slide1 from "../../components/assets/media/landingSiteMedia/slide1.png";
import slide2 from "../../components/assets/media/landingSiteMedia/slide2.png";
import slide3 from "../../components/assets/media/landingSiteMedia/slide3.png";

import phone from "../../components/assets/media/landingSiteMedia/phone.png";
import mail from "../../components/assets/media/landingSiteMedia/mail.png";
import map from "../../components/assets/media/landingSiteMedia/map.png";
import righticon from "../../components/assets/media/landingSiteMedia/righticon.png";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FormikForm, FormikTextArea, FormikTextField, LandingAppBar, LandingFooter } from '../../components';
import { useMediaQuery } from "react-responsive";
import { SiteMap } from '../../Routes';
import { LandingSiteSendMessageValidator } from '../../components/assets';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

import { Form } from "formik";

const Website = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const LandingState = useSelector(state => state.landingSite)
    const DigitalFeatureRef = React.useRef(null)
    const GetStartedRef = React.useRef(null)
    const TestimonialsRef = React.useRef(null)
    const TeamRef = React.useRef(null)
    const PricingRef = React.useRef(null)
    const ContactUsRef = React.useRef(null)

    const SendMessageFormRef = React.useRef(null)
    const [loaded, setLoaded] = React.useState(false)

    const { postApiSendMessage } = bindActionCreators(
        actions.landingSite,
        dispatch
    );

    const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
    const isTab = useMediaQuery({ query: "(max-width: 899px)" });

    let SliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile ? 1 : isTab ? 2 : 3,
        slidesToScroll: isMobile ? 1 : isTab ? 2 : 3,
    };

    React.useEffect(() => {
        if (LandingState?.currentAction === "POST_SEND_MESSAGE_SUCCESS") {
            SendMessageFormRef?.current?.resetForm();
        }
    }, [LandingState?.currentAction])

    React.useEffect(() => {
        let delayer = setTimeout(() => {
            setLoaded(true)
        }, 400)

        return () => clearTimeout(delayer)
    }, [])

    React.useEffect(() => {
        if (loaded) {
            if (location.hash === "#features" && DigitalFeatureRef) DigitalFeatureRef.current.scrollIntoView({ behavior: 'smooth' })
            if (location.hash === "#testimonials" && TestimonialsRef) TestimonialsRef.current.scrollIntoView({ behavior: 'smooth' })
            if (location.hash === "#team" && TeamRef) TeamRef.current.scrollIntoView({ behavior: 'smooth' })
            if (location.hash === "#pricing" && PricingRef) PricingRef.current.scrollIntoView({ behavior: 'smooth' })
            if (location.hash === "#contactus" && ContactUsRef) ContactUsRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [location, loaded])

    const handleSubmit = data => { // Send Message API
        postApiSendMessage(data)
    }

    return (
        <Box className={styles.landing_page}>
            <Box>
                <LandingAppBar />
            </Box>
            <Box className={styles.landing_home_page}>
                <Container maxWidth="xl">
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
                        <Grid item lg={6} xs={12}>
                            <Box className={styles.heading_homepage}>

                                {loaded ? <Typography className={styles.main_heading}> Practice makes Math perfect </Typography>
                                    : <Box className={styles.main_heading}>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Box>
                                }

                                {loaded ?
                                    <Typography className={styles.heading_details}>
                                        Your exhaustive practicing space to get detailed insights and actionable suggestions for improving your Mathematics* performance
                                    </Typography>
                                    :
                                    <Box className={styles.heading_details}>
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                        <Skeleton variant="text" />
                                    </Box>
                                }

                                <Box className={styles.start_p}>
                                    {loaded ?
                                        <>
                                            <Button className={styles.start_button} onClick={() => window.location.href = SiteMap.HomePage.public_url}>
                                                Start Practising
                                            </Button>
                                            <Button className={styles.knowMore} variant="text" onClick={() => GetStartedRef.current.scrollIntoView({ behavior: 'smooth' })} >
                                                Know More
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <Skeleton className={styles.start_button} variant="rectangular" width={200} height={58} />
                                            <Skeleton className={styles.knowMore} variant="rectangular" width={200} height={58} />
                                        </>
                                    }
                                </Box>
                                <Typography className={styles.currently_details}>*Currently active for 10th standard only</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <Box className={styles.homepageimg}>
                                {loaded ?
                                    <Box className={styles.homeimg}>
                                        <img src={homepage_img} alt="homepage_img" />
                                    </Box>
                                    :
                                    <Box className={styles.homeimg}>
                                        <Skeleton variant="rectangular" height={512} width={489} />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={styles.get_started} ref={GetStartedRef}>
                <Box>
                    {loaded ?
                        <>
                            <Typography className={styles.get_details}>
                                An EdTech solution created by self-directed learners from BITS and IIM, who understand the importance of guided practising.
                            </Typography>
                            <Button className={styles.get_start} onClick={() => window.location.href = SiteMap.HomePage.public_url}>Get Started</Button>
                        </>
                        :
                        <>
                            <Box className={styles.get_details}>
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                                <Skeleton variant="text" />
                            </Box>
                            <Box className="display-flex justify-content-center">
                                <Skeleton className={styles.get_start} variant="rectangular" width={160} height={50} />
                            </Box>
                        </>
                    }
                </Box>
                <Box className={styles.whatsapp}>
                    <a target="_blank" href="https://wa.me/917014960186" rel="noopener noreferrer">
                        <img src={whatsapp} alt="whatsapp" />
                    </a>
                </Box>
            </Box>
            <Box className={styles.numbers}>
                <Box className={styles.number_heading}>
                    <Typography className={styles.someNumber}>Some numbers that matter</Typography>
                    <Typography className={styles.depicted}>Our achievements in the journey depicted in numbers</Typography>
                </Box>
                <Box className={styles.number_section}>
                    <Box className={styles.n_matter}>
                        <Box className={styles.students}>
                            <Typography className={styles.matter_heading}>700+</Typography>
                            <Typography className={styles.matter_details}>Students registered</Typography>
                        </Box>
                        <Box className={styles.students_center}>
                            <Typography className={styles.matter_heading}>5000+</Typography>
                            <Typography className={styles.matter_details}>Practice Sessions Done</Typography>
                        </Box>
                        <Box className={styles.students}>
                            <Typography className={styles.matter_heading}>20000+</Typography>
                            <Typography className={styles.matter_details}>Questions Solved</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={styles.studdict} ref={DigitalFeatureRef}>
                <Container maxWidth="xl">
                    <Box className={styles.studdict_heading}>
                        <Typography className={styles.s_heading}>StuddictEd Digital Features</Typography>
                    </Box>
                    <Box className={styles.studdict_box}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }} alignItems="center">
                            <Grid item lg={7} md={9} sm={12} xs={12}>
                                <Box className={styles.stu_img}>
                                    <Box className={styles.animated_one}>
                                        <img src={animated_one} alt="animated_one" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={5} md={3} sm={12} xs={12}>
                                <Box className={styles.stu_details}>
                                    <Typography className={styles.application} >APPLICATION</Typography>
                                    <Typography className={styles.stu_heading}>See the “boring” Math in real life</Typography>
                                    <Typography className={styles.stu_heading_details}>Our "Studdictive" questions will leave you in awe of how diversely Math is applied in the real world. Our solutions are easier to understand than other resources and are written to explain, not to be copied and pasted.</Typography>
                                    <Button onClick={() => window.location.href = SiteMap.HomePage.public_url} className={styles.get_start_two} variant="text" endIcon={<ArrowRightAltIcon />}>
                                        Get started
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box className={styles.studdict_box}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }} alignItems="center">
                            <Grid item lg={6} md={6} sm={12} xs={12} order={{ xs: 2, md: 1 }}>
                                <Box className={styles.stu_details}>
                                    <Typography className={`${styles.application} ${styles.yellow}`}>UNDERSTANDING</Typography>
                                    <Typography className={styles.stu_heading}>Get a 360º view of concepts</Typography>
                                    <Typography className={styles.stu_heading_details}>To understand a concept, it is necessary to know and understand all its properties and relationships. We explore the concepts in detail so that it makes sense to you everytime it appears somewhere.</Typography>
                                    <Button onClick={() => window.location.href = SiteMap.HomePage.public_url} className={styles.get_start_two} variant="text" endIcon={<ArrowRightAltIcon />}>
                                        Get started
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} order={{ xs: 1, md: 2 }}>
                                <Box className={styles.stu_img}>
                                    <Box className={styles.animated_two}>
                                        <img src={animated_two} alt="animated_two" />
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>

                    <Box className={styles.studdict_box}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }} alignItems="center">
                            <Grid item lg={7} md={8} sm={12} xs={12}>
                                <Box className={styles.stu_img}>
                                    <Box className={styles.animated_three}>
                                        <img src={animated_three} alt="animated_three" />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={5} md={4} sm={12} xs={12}>
                                <Box className={styles.stu_details}>
                                    <Typography className={`${styles.application} ${styles.blue}`}>DOING</Typography>
                                    <Typography className={styles.stu_heading}>Practice! Practice! Practice!</Typography>
                                    <Typography className={styles.stu_heading_details}>Mathematical concepts stay with you as long as you practice. StuddictEd gives you a plethora of high-quality competency-based questions so that each session gives you a fresh persepective on your performing abilities.</Typography>
                                    <Button onClick={() => window.location.href = SiteMap.HomePage.public_url} className={styles.get_start_two} variant="text" endIcon={<ArrowRightAltIcon />}>
                                        Get started
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={styles.studdict_box}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }} alignItems="center">

                            <Grid item lg={6} md={6} sm={12} xs={12} order={{ xs: 2, md: 1 }}>
                                <Box className={styles.stu_details}>
                                    <Typography className={`${styles.application} ${styles.green}`}>ANALYSIS</Typography>
                                    <Typography className={styles.stu_heading}>Get smart learning analytics</Typography>
                                    <Typography className={styles.stu_heading_details}>A StuddictEd practice session ends with a quick and detailed feedback on your performance. You can easily keep a track of things you could/could not do, recently and historically. Our unique Smart Score Calculator helps you plan your future learning sessions better.</Typography>
                                    <Button onClick={() => window.location.href = SiteMap.HomePage.public_url} className={styles.get_start_two} variant="text" endIcon={<ArrowRightAltIcon />}>
                                        Get started
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} order={{ xs: 1, md: 2 }}>
                                <Box className={styles.stu_img}>
                                    <Box className={styles.animated_four}>
                                        <img src={animated_four} alt="animated_four" />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box className={styles.parents} ref={TestimonialsRef}>
                <Container maxWidth="lg">
                    <Box className={styles.parents_heading}>
                        <Typography className={styles.parents_head}>
                            Parents and students love us!
                        </Typography>
                        <Typography className={styles.parents_details}>
                            We have been helping students and parents from around the country.
                        </Typography>
                    </Box>
                    <Slider {...SliderSettings}>
                        <Box>
                            <Box className={styles.slider_details}>
                                <Typography className={styles.slider_box_heading}>“Good” different</Typography>
                                <Typography className={styles.slider_box_details}>
                                    The practice sessions are different from those taken at school, even though they cover the same things I do at school. The analysis was in so much detail that I can plan my practice sessions, and not take too much exam pressure. Waiting for more!
                                </Typography>
                            </Box>
                            <Box className={styles.slider_avatar}>
                                <Avatar className={styles.slider_avtar} src={slide1}></Avatar>
                                <Typography variant='h3'>Kunal</Typography>
                                <Typography variant='h5'>Class 10 Student</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={styles.slider_details}>
                                <Typography className={styles.slider_box_heading}>Relatable and Challenging</Typography>
                                <Typography className={styles.slider_box_details}>The questions are relatable and challenging as well. I thought I would be very good at it. While I could do well in Algebra, I made some errors in Geometry questions. I will now make a plan as per the suggestions, and would check my progress through this portal.</Typography>
                            </Box>
                            <Box className={styles.slider_avatar}>
                                <Avatar className={styles.slider_avtar} src={slide2}></Avatar>
                                <Typography variant='h3'>Nandini Jaithalia</Typography>
                                <Typography variant='h5'>Class 10 Student</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box className={styles.slider_details}>
                                <Typography className={styles.slider_box_heading}>Excellent to keep track</Typography>
                                <Typography className={styles.slider_box_details}>StuddictEd Digital is a great product! Suggestions are an icing on the cake. The more practice she does, the more help she gets. I have always pushed her more towards better learning than better marks, and StuddictEd knows how to actually apply that.</Typography>
                            </Box>
                            <Box className={styles.slider_avatar}>
                                <Avatar className={styles.slider_avtar} src={slide3}></Avatar>
                                <Typography variant='h3'>Mrs. Malpani</Typography>
                                <Typography variant='h5'>Kunal’s mother</Typography>
                            </Box>
                        </Box>
                    </Slider>
                </Container>
            </Box>
            <Box className={styles.approach}>
                <Container maxWidth="xl">
                    <Box className={styles.approach_heading}>
                        <Typography className={styles.ap_heading} >Our approach to help you learn better</Typography>
                    </Box>
                    <Box className={styles.approach_details}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4, lg: 4 }}>
                            <Grid item md={3} sm={6} xs={12}>
                                <Box className={styles.app_card}>
                                    <Box className={styles.app_card_img}>
                                        <Avatar className={styles.avatar_img}><img src={learn} alt="learn" /></Avatar>
                                        <img src={card_line1} alt="card_line1" />
                                    </Box>
                                    <Typography className={styles.app_card_heading}>Learn</Typography>
                                    <Typography className={styles.app_card_details}>Learning should be the only constant in this variable world. Make sure you never get fed up of learning about new things.</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Box className={styles.app_card}>
                                    <Box className={styles.app_card_img}>
                                        <Avatar className={styles.avatar_img}><img src={Practice} alt="Practice" /></Avatar>
                                        <img src={card_line2} alt="card_line2" />
                                    </Box>
                                    <Typography className={styles.app_card_heading}>Practice</Typography>
                                    <Typography className={styles.app_card_details}>The only way to become the best at something, is by practicing. All the Messis and Federers of the world will tell you that.</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Box className={styles.app_card}>
                                    <Box className={styles.app_card_img}>
                                        <Avatar className={styles.avatar_img}><img src={Analyse} alt="Analyse" /></Avatar>
                                        <img src={card_line3} alt="card_line3" />
                                    </Box>
                                    <Typography className={styles.app_card_heading}>Analyse</Typography>
                                    <Typography className={styles.app_card_details}>Blindly spending time practising does not lead to perfection. Smart analysis along with practice then becomes key for improvement.</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <Box className={styles.app_card}>
                                    <Box className={styles.app_card_img}>
                                        <Avatar className={styles.avatar_img}><img src={Revise} alt="Revise" /></Avatar>
                                    </Box>
                                    <Typography className={styles.app_card_heading}>Revise</Typography>
                                    <Typography className={styles.app_card_details}>Using the analysis to revise or learn again or practice again the weakensses will help you become the expert.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box className={styles.meetOurTeam} ref={TeamRef}>
                <Container maxWidth="xl">
                    <Box className={styles.meetOurTeam_heading}>
                        <Typography className={styles.meetHeading}>Meet our team of creative experts</Typography>
                    </Box>
                    <Box className={styles.ceoName}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box className={styles.ceoName_box}>
                                    <Box className={styles.ceo_img}>
                                        <img src={Nishant} alt="Nishant" />
                                    </Box>
                                    <Box className={styles.ceo_details}>
                                        <Typography className={styles.ceo_name}>Nishant Sharma</Typography>
                                        <Typography className={styles.ceo_position}>Co-founder, Learning and Content</Typography>
                                        <Typography className={styles.ceo_pre_details}>Nishant is a BITS-Pilani graduate with with an experience of over 6 years of developing academic content and 2 years of teaching. He is the brains behind our entire pedagogy.</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box className={styles.ceoName_box}>
                                    <Box className={styles.ceo_img} >
                                        <img src={Mridul} alt="Mridul" />
                                    </Box>
                                    <Box className={styles.ceo_details}>
                                        <Typography className={styles.ceo_name}>Mridul Sharma</Typography>
                                        <Typography className={styles.ceo_position}>Co-founder, Business and Operations</Typography>
                                        <Typography className={styles.ceo_pre_details}>Mridul is a passionate IIM graduate. Having developed a knack for business during his MBA days, he is the best man to grow a sustainable business out of our solid content and tech.</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box className={styles.ceoName_box}>
                                    <Box className={styles.ceo_img}>
                                        <img src={Siva} alt="Siva" />
                                    </Box>
                                    <Box className={styles.ceo_details}>
                                        <Typography className={styles.ceo_name}>Siva</Typography>
                                        <Typography className={styles.ceo_position}>Head of Engineering</Typography>
                                        <Typography className={styles.ceo_pre_details}>Siva plays a vital role in ensuring quality code is being delivered on both UI and API layers, he is enthusiastic on designing database schemas & also quickly adopts new technologies.</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Box className={styles.ceoName_box}>
                                    <Box className={styles.ceo_img}>
                                        <img src={Ajay} alt="Ajay" />
                                    </Box>
                                    <Box className={styles.ceo_details}>
                                        <Typography className={styles.ceo_name}>Ajay</Typography>
                                        <Typography className={styles.ceo_position}>Technical Architect</Typography>
                                        <Typography className={styles.ceo_pre_details}>Ajay comes with a rich experience in Cloud, API Development and various other domains. He is interested in the DevOps domain and handles the scrum master role as well.</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box className={styles.affordable} ref={PricingRef}>
                <Container maxWidth="xl">
                    <Box className={styles.affordable_heading}>
                        <Typography className={styles.a_heading}>
                            One way to pay, Affordable, Value for money!
                        </Typography>
                    </Box>
                    <Box className={styles.affordable_box}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item lg={3} md={4} sm={12} xs={12}>
                                <Box className={styles.practiceBox}>
                                    <Typography className={styles.free}>
                                        Try 1 practice session completely free.
                                    </Typography>
                                    <Typography className={styles.d_amount}>₹12,000</Typography>
                                    <Typography className={styles.pa_off}>58% off</Typography>
                                    <Typography className={styles.amount}>₹7,500</Typography>
                                    <Typography className={styles.gst}>plus GST@ 18%</Typography>
                                    <Button onClick={() => window.location.href = SiteMap.HomePage.public_url} className={styles.upgrade}>Upgrade</Button>
                                </Box>
                            </Grid>
                            <Grid item lg={9} md={8} sm={12} xs={12}>
                                <Box className={styles.affordable_details}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={one} alt="one" />
                                                <Typography className={styles.aff_details_name}>Unlimited Practice</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={two} alt="two" />
                                                <Typography className={styles.aff_details_name}>Unlimited Questions</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={three} alt="three" />
                                                <Typography className={styles.aff_details_name}>Customised Pratice</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={two} alt="two" />
                                                <Typography className={styles.aff_details_name}>Revisit Questions</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={four} alt="four" />
                                                <Typography className={styles.aff_details_name}>Mapped to Reality</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={one} alt="one" />
                                                <Typography className={styles.aff_details_name}>StuddictEd Suggests</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={four} alt="four" />
                                                <Typography className={styles.aff_details_name}>Smart Score Calculation</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={five} alt="five" />
                                                <Typography className={styles.aff_details_name}>Conceptual Analysis</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={6} xs={12}>
                                            <Box className={styles.aff_details_box}>
                                                <img src={six} alt="six" />
                                                <Typography className={styles.aff_details_name}>Ability Focused</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>

            <Box className={styles.lets_connect} ref={ContactUsRef}>
                <Box className={styles.lets_connectBox}>
                    <Container maxWidth="xl">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item lg={7} md={6} sm={12} xs={12}>
                                <Box className={styles.lets_details}>
                                    <Typography className={styles.lets_heading}>Let’s connect</Typography>
                                    <Typography className={styles.lets_heading_details}>We are ready to help you</Typography>
                                    <Box className={styles.contact}>
                                        <Box className={styles.contact_details}>
                                            <img src={phone} alt="phone" />
                                            <Typography className={styles.Contact_d}>+91-70149 60186</Typography>
                                        </Box>
                                        <Box className={styles.contact_details}>
                                            <img src={mail} alt="mail" />
                                            <Typography className={styles.Contact_d}>support@studdicted.com</Typography>
                                        </Box>
                                        <Box className={styles.contact_details}>
                                            <img src={map} alt="map" />
                                            <Typography className={styles.Contact_d}>Jaipur, Rajasthan</Typography>
                                        </Box>
                                        <Box className={styles.social_icon}>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/studdicted.in/"><Avatar className={styles.avatar}>  <InstagramIcon /></Avatar> </a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/studdicted/"><Avatar className={styles.avatar}> <LinkedInIcon /> </Avatar></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/studdicted.in"><Avatar className={styles.avatar}> <FacebookIcon /> </Avatar></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/StuddictEd_in"><Avatar className={styles.avatar}> <TwitterIcon /> </Avatar></a>
                                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCOlw26k6FCG1tjmgIcNGihg"><Avatar className={styles.avatar}> <YouTubeIcon /> </Avatar></a>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={5} md={6} sm={12} xs={12}>
                                <Box className={styles.contact_form}>
                                    <FormikForm
                                        innerRef={SendMessageFormRef}
                                        onSubmit={handleSubmit}
                                        validator={LandingSiteSendMessageValidator}
                                    >
                                        {({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => {
                                            return (
                                                <Form
                                                    onSubmit={handleSubmit}
                                                    onChange={e => {
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    onReset={resetForm}
                                                >
                                                    <Grid direction="row" container spacing={3} className="field-outer">
                                                        <Grid container item sm={12}>
                                                            <div className="custom-form-field">
                                                                <label className="form-label">Your name</label>
                                                                <FormikTextField
                                                                    className="white-bg-field"
                                                                    name="full_name"
                                                                    label=""
                                                                />
                                                            </div>
                                                        </Grid>

                                                        <Grid container item sm={12}>
                                                            <div className="custom-form-field">
                                                                <label className="form-label">Email address</label>
                                                                <FormikTextField
                                                                    className="white-bg-field"
                                                                    name="email"
                                                                    label=""
                                                                />
                                                            </div>
                                                        </Grid>

                                                        <Grid container item sm={12}>
                                                            <div className="custom-form-field">
                                                                <label className="form-label">Mobile number</label>
                                                                <FormikTextField
                                                                    inputProps={{
                                                                        maxLength: 10,
                                                                    }}
                                                                    className="white-bg-field"
                                                                    name="mobile_number"
                                                                    label=""
                                                                />
                                                            </div>
                                                        </Grid>

                                                        <Grid container item sm={12}>
                                                            <div className="custom-form-field">
                                                                <label className="form-label">Message</label>
                                                                <FormikTextArea
                                                                    id="message"
                                                                    name="message"
                                                                    minRows={4}
                                                                />
                                                            </div>
                                                        </Grid>
                                                        <Grid container item sm={12}>
                                                            <Button id="sendMessage" type="submit" disabled={!(dirty && isValid)} className={styles.upgrade}>Send Message</Button>
                                                        </Grid>

                                                    </Grid>
                                                </Form>
                                            )
                                        }}

                                    </FormikForm>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>

            <Box className={styles.direction}>
                <Typography className={styles.direction_heading}>Give a direction to your Mathematics practice sessions.</Typography>
                <Button onClick={() => window.location.href = SiteMap.HomePage.public_url}>Start Practising</Button>
                <Box className={styles.direction_option}>
                    <Typography className={styles.dir_details}>
                        <img src={righticon} alt="righticon" />
                        Questions mapped to reality
                    </Typography>
                    <Typography className={styles.dir_details}>
                        <img src={righticon} alt="righticon" />
                        Smart Score calcuation
                    </Typography>
                    <Typography className={styles.dir_details}>
                        <img src={righticon} alt="righticon" />
                        Unlimited sessions and questions
                    </Typography>
                </Box>
            </Box>
            <LandingFooter />
        </Box >
    )
}

export default Website;