import {
    postSignInWithOTP,
    postGenerateLoginOTP, postGenerateForgotPasswordOTP,
    postVerifyForgotPasswordOTP, patchResetPassword, getProfileDetails,
    putProfileDetails, patchChangePassword, postSignInWithPassword, getAvatars,
    postBillingDetails
} from '../../api/apiCalls';
import { ShowToast } from './ToastActions';
import { AUTH_CONTEXT as context } from '../context';
import { refreshToken } from '../../api/apiHelper';
import { paymentRequired, resetPracticeReducer } from './practiceActions';
import { restAnalyseReducer } from './analyseActions';
import { resetBookmarkReducer } from './bookmarkActions';
import { resetDashboardReducer } from './dashboardActions';
import { resetUIReducer } from './UIActions';

// RESET AUTH REDUCER
export const resetAuthReducer = () => ({
    type: context.REST_AUTH_REDUCER,
})

//REFRESH TOKEN ACTIONS
const refreshTokenRequest = () => ({
    type: context.REFRESH_TOKEN_REQUEST,
})

const refreshTokenError = error => ({
    type: context.REFRESH_TOKEN_ERROR,
    refreshTokenError: error
})

const refreshTokenSuccess = data => ({
    type: context.REFRESH_TOKEN_SUCCESS,
    data: data
})

export const getApiRefreshToken = () => {
    return async (dispatch) => {
        dispatch(refreshTokenRequest())
        await refreshToken().then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                let { token } = res?.data.data || {}
                let expireTime = new Date()
                expireTime.setSeconds(expireTime.getSeconds() + Number(token.expires_in));
                token['expires_at'] = expireTime
                dispatch(refreshTokenSuccess(res?.data.data))
            } else {
                dispatch(LogOutSuccess())
                dispatch(refreshTokenError(res?.data?.detail))
                dispatch(ShowToast({ "message": "Your session is expired. You need to login again.", "severity": "error" }))
            }
        })
    }
}

//RESET OTP STATES
export const resetOTPStates = () => ({
    type: context.RESET_OTP_STATES,
})

//SING IN OTP GENRATION ACTIONS
const OtpGenerateRequest = () => ({
    type: context.OTP_GENERATE_REQUEST,
})

const OtpGenerateError = error => ({
    type: context.OTP_GENERATE_ERROR,
    otpGenerateError: error
})

const OtpGenerateSuccess = () => ({
    type: context.OTP_GENERATE_SUCCESS,
})

export const getApiSignInOtpGenerate = data => {
    return (dispatch) => {
        dispatch(OtpGenerateRequest())
        postGenerateLoginOTP(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(OtpGenerateSuccess())
                dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(OtpGenerateError(res?.data?.message))
            }
        })
    }
}

//SIGN IN ACTIONS
const SignInRequest = () => ({
    type: context.SIGN_IN_REQUEST,
})

const SignInError = error => ({
    type: context.SIGN_IN_ERROR,
    signInError: error
})

export const SignInSuccess = data => ({
    type: context.SIGN_IN_SUCCESS,
    data: data
})

export const getApiSignInWithOTP = data => {
    return (dispatch) => {
        dispatch(SignInRequest())
        postSignInWithOTP(data).then((res) => {
            if (res?.status === 200) {
                let { token } = res?.data.data || {}
                let expireTime = new Date()
                expireTime.setSeconds(expireTime.getSeconds() + Number(token.expires_in));
                token['expires_at'] = expireTime
                dispatch(resetPracticeReducer())
                dispatch(SignInSuccess(res.data.data))
                if (res.data.data.payment_plan === "Paid" || res.data.data.payment_plan === "Free") {
                    dispatch(paymentRequired(false))
                }
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(SignInError(res?.data?.message))
            }
        })
    }
}

export const getApiSignInWithPassword = data => {
    return (dispatch) => {
        dispatch(SignInRequest())
        postSignInWithPassword(data).then((res) => {
            if (res?.status === 200) {
                let { token } = res?.data.data || {}
                let expireTime = new Date()
                expireTime.setSeconds(expireTime.getSeconds() + Number(token.expires_in));
                token['expires_at'] = expireTime
                dispatch(SignInSuccess(res.data.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(SignInError(res?.data?.message))
            }
        })
    }
}

//FORGOT PASSWORD OTP GENRATION ACTIONS
export const getApiForgotPasswordOtpGenerate = data => {
    return (dispatch) => {
        dispatch(OtpGenerateRequest())
        postGenerateForgotPasswordOTP(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(OtpGenerateSuccess())
                dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(OtpGenerateError(res?.data?.message))
            }
        })
    }
}

//FORGOT PASSWORD OTP VERIFICATION ACTIONS
const OtpVerificationRequest = () => ({
    type: context.FORGOT_PASSWORD_OTP_VERIFY_REQUEST,
})

const OtpVerificationError = error => ({
    type: context.FORGOT_PASSWORD_OTP_VERIFY_ERROR,
    otpVerificationError: error
})

const OtpVerificationSuccess = () => ({
    type: context.FORGOT_PASSWORD_OTP_VERIFY_SUCCESS,
})

export const getApiOtpVerification = data => {
    return (dispatch) => {
        dispatch(OtpVerificationRequest())
        postVerifyForgotPasswordOTP(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(OtpVerificationSuccess())
                dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(OtpVerificationError(res?.data?.message))
            }
        })
    }
}



//RESET PASSWORD ACTIONS
const resetPasswordRequest = () => ({
    type: context.RESET_PASSWORD_REQUEST,
})

const resetPasswordError = error => ({
    type: context.RESET_PASSWORD_ERROR,
    resetPasswordError: error
})

const resetPasswordSuccess = data => ({
    type: context.RESET_PASSWORD_SUCCESS,
    data: data
})

export const getApiResetPassword = data => {
    return (dispatch) => {
        dispatch(resetPasswordRequest())
        patchResetPassword(data).then((res) => {
            if (res?.status === 200 || res?.status === 201) {
                dispatch(ShowToast({ "message": res?.data?.message || "Reset Success !" }))
                dispatch(resetPasswordSuccess(res?.data.data))
            }
            else {
                dispatch(resetPasswordError(res?.data?.message))
                dispatch(ShowToast({ "message": res?.data?.message || "Something went wrong !", "severity": "error" }))
            }
        })
    }
}


//LOGOUT ACTIONS
// const LogOutRequest = () => ({
//     type: context.LOGOUT_REQUEST,
// })

// const LogOutError = error => ({
//     type: context.LOGOUT_ERROR,
//     logOutError: error
// })

export const LogOutSuccess = () => ({
    type: context.LOGOUT_SUCCESS,
})

export const getApiLogOut = () => {
    return (dispatch) => {
        // LogOutRequest()
        // **** Logout API will be added here ****
        // postLogout().then((res) => {
        //     if (res?.status === 200) {
        //         dispatch(ShowToast({ "message": res?.data?.message }))
        //         dispatch(LogOutSuccess(res?.data.data))
        dispatch(LogOutSuccess())
        dispatch(restAnalyseReducer())
        dispatch(resetAuthReducer())
        dispatch(resetBookmarkReducer())
        dispatch(resetDashboardReducer())
        dispatch(resetPracticeReducer())
        dispatch(resetUIReducer())
        //     }
        //     else {
        //         dispatch(ShowToast({ "message": res?.data?.message, "severity": "error" }))
        //         dispatch(LogOutError(res?.data?.message))
        //     }
        // })
    }
}


//GET PROFILE DETAILS ACTIONS
const GetProfileDetailsRequest = () => ({
    type: context.GET_PROFILE_REQUEST,
})

const GetProfileDetailsError = error => ({
    type: context.GET_PROFILE_ERROR,
    getProfileDetailsErr: error
})

const GetProfileDetailsSuccess = (data) => ({
    type: context.GET_PROFILE_SUCCESS,
    userProfile: data
})

export const getApiProfileDetails = () => {
    return (dispatch) => {
        dispatch(GetProfileDetailsRequest())
        getProfileDetails().then((res) => {
            if (res?.status === 200) {
                dispatch(GetProfileDetailsSuccess(res.data.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(GetProfileDetailsError(res?.data?.message))
            }
        })
    }
}


//PUT PROFILE DETAILS ACTIONS
const PutProfileDetailsRequest = () => ({
    type: context.PUT_PROFILE_REQUEST,
})

const PutProfileDetailsError = error => ({
    type: context.PUT_PROFILE_ERROR,
    putProfileDetailsErr: error
})

const PutProfileDetailsSuccess = (data) => ({
    type: context.PUT_PROFILE_SUCCESS,
    userProfile: data
})

export const putApiProfileDetails = (data) => {
    return (dispatch) => {
        dispatch(PutProfileDetailsRequest())
        putProfileDetails(data).then((res) => {
            if (res?.status === 200) {
                dispatch(PutProfileDetailsSuccess(res?.data.data))
                dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(PutProfileDetailsError(res?.data?.message))
            }
        })
    }
}

//PATCH PASSWORD ACTIONS
const patchPasswordRequest = () => ({
    type: context.PATCH_PASSWORD_REQUEST,
})

const patchPasswordError = error => ({
    type: context.PATCH_PASSWORD_ERROR,
    patchPasswordError: error
})

const patchPasswordSuccess = () => ({
    type: context.PATCH_PASSWORD_SUCCESS,
})

export const patchApiPasswordChange = (data) => {
    return (dispatch) => {
        dispatch(patchPasswordRequest())
        patchChangePassword(data).then((res) => {
            if (res?.status === 200) {
                dispatch(patchPasswordSuccess())
                dispatch(ShowToast({ "message": res?.data?.message }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(patchPasswordError(res?.data?.message))
            }
        })
    }
}

//GET AVATAR LIST ACTIONS
const getAvatarsRequest = () => ({
    type: context.GET_AVATARS_REQUEST,
})

const getAvatarsError = error => ({
    type: context.GET_AVATARS_ERROR,
    getAvatarsError: error
})

const getAvatarsSuccess = (data) => ({
    type: context.GET_AVATARS_SUCCESS,
    avatarsList: data
})

export const getApiAvatarsList = () => {
    return (dispatch) => {
        dispatch(getAvatarsRequest())
        getAvatars().then((res) => {
            if (res?.status === 200) {
                dispatch(getAvatarsSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(getAvatarsError(res?.data?.message))
            }
        })
    }
}

// POST BILLING DETAILS ACTIONS
const postBillingDetailsRequest = () => ({
    type: context.POST_BILLING_DETAILS_REQUEST,
})

const postBillingDetailsError = error => ({
    type: context.POST_BILLING_DETAILS_ERROR,
    postBillingDetailsError: error
})

const postBillingDetailsSuccess = () => ({
    type: context.POST_BILLING_DETAILS_SUCCESS
})

export const postApiBillingDetails = (data) => {
    return (dispatch) => {
        dispatch(postBillingDetailsRequest())
        postBillingDetails(data).then((res) => {
            if (res?.status === 200) {
                dispatch(postBillingDetailsSuccess(res?.data?.data))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Form Submitted Successfully" }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong!", "severity": "error" }))
                dispatch(postBillingDetailsError(res?.data?.message))
            }
        })
    }
}

export const UpdatePaymentPlan = (data) => ({
    type: context.UPDATE_PAYMENT_PLAN,
    payment_plan: data
})