import React from 'react';
import { Box, Button, Modal, Typography } from "@mui/material"
import styles from "./Overlays.module.scss"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const ConfirmationDialog = ({ open = false, handleCloseIcon, handleClose = () => { }, handleOk = () => { }, message, buttonOneText, buttonTwoText, loading = false }) => {
    return (
        <>
            <Modal
                open={open}
                onClose={typeof handleCloseIcon === "function" ? handleCloseIcon : handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="confirmation_modal"
            >
                <Box className={styles.pause_session_modal}>
                    <>
                        <Button onClick={typeof handleCloseIcon === "function" ? handleCloseIcon : handleClose} className={styles.close_modal}><HighlightOffOutlinedIcon /></Button>
                        <Box className={styles.pause_session_modal_inner}>
                            <Typography className={styles.modal_title}>{message}</Typography>
                            <Box className={`bottom_btn_box justify-content-center ${styles.button_box}`}>
                                <Button disabled={loading} className="bottom_btn" onClick={handleClose}>{buttonOneText}</Button>
                                <Button disabled={loading} className="bottom_btn_back" onClick={handleOk}>{buttonTwoText}</Button>
                            </Box>
                        </Box>
                    </>
                </Box>
            </Modal>
        </>
    )
}

export default ConfirmationDialog