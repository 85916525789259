import React from "react";
import {
	InputLabel,
	FormControl,
} from "@mui/material";
import { Field } from "formik";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordField = (props) => {
	const { name, label, className } = props;

	const [values, setValues] = React.useState({
		password: "",
		showPassword: false,
	});

	const handleChange = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
	};

	const handleClickShowPassword = () => {
		setValues({
			...values,
			showPassword: !values.showPassword,
		});
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const Content = ({ form, meta }) => {

		return (
			<React.Fragment>
				{label ? (
					<FormControl variant="outlined">
						<InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
						<OutlinedInput
							autoComplete="false"
							fullWidth
							className={className}
							id={name}
							name={name}
							type={values.showPassword ? "text" : "password"}
							value={values.password}
							onChange={handleChange("password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{values.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label={label}
						/>
					</FormControl>
				) : (
					<>
						<OutlinedInput
							autoComplete="false"
							fullWidth
							className={className}
							id={name}
							name={name}
							type={values.showPassword ? "text" : "password"}
							value={values.password}
							onChange={handleChange("password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{values.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label=""
							error={(meta.touched || form.submitCount > 0) && Boolean(meta.error)}
						/>
						{(meta.touched || form.submitCount > 0) &&
							<FormHelperText variant="filled" className="Mui-error">{meta.error}</FormHelperText>}
					</>
				)}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Field name={name}>{Content}</Field>
		</React.Fragment>
	);
};

export default PasswordField;
