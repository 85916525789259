import React from "react";
import style from "./GoogleTranslateHeader.module.scss"
import { Box } from "@mui/material";

const GoogleTranslate = () => {

    const scriptAlreadyExists = () => document.getElementById('gtranslate') !== null

    const googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
            {
                pageLanguage: "en",
                includedLanguages: 'en,hi',
                autoDisplay: false,
                layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
            },
            "google_translate_element"
        );
    };

    React.useEffect(() => {
        if (!scriptAlreadyExists()) {
            var addScript = document.createElement("script");
            addScript.setAttribute("id", "gtranslate");
            addScript.setAttribute(
                "src",
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
            );
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = googleTranslateElementInit;
        }
    }, []);

    return (
        <Box className={style.google_translate_wrap}>
            <Box className={style.google_translate_box}>
                <div id="google_translate_element"></div>
            </Box>
        </Box>
    )
}

export default GoogleTranslate;