import React from "react";
import { WhiteLogo } from "../assets";
import { IllustrationBoy, IllustrationGirl } from "../assets";
import { useLocation } from "react-router-dom";
import { SiteMap } from "../../Routes";

const LogoBlock = () => {
	const location = useLocation()
	return (
		<React.Fragment>
			<div className="left-wrap">
				<div className="logo-block">
					<div className="logo-block-inner" onClick={() => window.location.href=SiteMap.LandingWebsite.public_url}>
						<img alt="The house from the offer." src={WhiteLogo} />
					</div>
				</div>
				<div className="illustration-block">
					<img alt="The house from the offer." src={(location?.pathname === SiteMap.HomePage.path) || (location?.pathname === SiteMap.SignupPage.path) ? IllustrationGirl : IllustrationBoy} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default LogoBlock;