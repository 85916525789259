import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import styles from "./TopicWiseAnalysis.module.scss"
import WestIcon from '@mui/icons-material/West';
import { AbilityAnalysisWithProgress } from "..";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
import TopicCard from "./TopicCard";
import SubtopicCard from "./SubtopicCard";
import moment from "moment";
import { SiteMap } from "../../Routes";
import { useNavigate } from "react-router-dom";

const TopicWiseAnalysis = ({ viewSuggestion = () => { }, viewPracticeHistory = () => { } }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const analyseState = useSelector(state => state.analyse)
    const { topicWiseAnalysis = [], subtopicWiseAnalysis = [], topicSubtopicWiseAbilityAnalysis = [],
        topicWiseAnalysisLoading, subtopicWiseAnalysisLoading, topicSubtopicWiseAbilityAnalysisLoading } = analyseState || {}

    const [step, setStep] = React.useState(0)

    const [topicsSortBy, setTopicSortBy] = React.useState("")
    const [subTopicsSortBy, setSubTopicsSortBy] = React.useState("")

    const [topicsAnalysis, setTopicsAnalysis] = React.useState([])
    const [subtopicsAnalysis, setSubtopicsAnalysis] = React.useState([])

    const [selectedTopic, setSelectedTopic] = React.useState({ "topic": "", "topic_id": null })
    const [selectedSubtopic, setSelectedSubtopic] = React.useState({ "subtopic": "", "subtopic_id": null })

    const { getApiTopicWiseAnalysis, getApiSubtopicWiseAnalysis, getApiTopicSubtopicWiseAbilityAnalysis } = bindActionCreators(
        actions.analyse,
        dispatch
    )

    const { selectTopics, selectSubTopics } = bindActionCreators(
        actions.ui,
        dispatch
    );

    const { getApiSubTopicList } = bindActionCreators(
        actions.practice,
        dispatch
    );

    React.useEffect(() => {
        getApiTopicWiseAnalysis()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setTopicsAnalysis(topicWiseAnalysis)
    }, [topicWiseAnalysis])

    React.useEffect(() => {
        setSubtopicsAnalysis(subtopicWiseAnalysis)
    }, [subtopicWiseAnalysis])

    React.useEffect(() => { // handles sorting for Topics Cards
        if (topicsSortBy) {
            let sortedArray = sortBy(topicWiseAnalysis, topicsSortBy);
            setTopicsAnalysis(sortedArray)
        }
    }, [topicWiseAnalysis, topicsSortBy])

    React.useEffect(() => { // handles sorting for Subtopics Cards
        if (subTopicsSortBy) {
            let sortedArray = sortBy(subtopicWiseAnalysis, subTopicsSortBy);
            setSubtopicsAnalysis(sortedArray)
        }
    }, [subtopicWiseAnalysis, subTopicsSortBy])

    const sortBy = (sortingArray, sortByField) => {
        let sortingFunc = (a, b) => (a[sortByField] - b[sortByField]);
        let sortingTimeFunc = (a, b) => (moment(a[sortByField], 'hh:mm:ss') - moment(b[sortByField], 'hh:mm:ss'));
        let tempArray = [...sortingArray];
        if (sortByField.includes('time')) {
            tempArray.sort(sortingTimeFunc)
        } else tempArray.sort(sortingFunc);
        return tempArray;
    }

    const hanldeTopicClick = (topic_id, topic) => {
        setSelectedTopic({ "topic": topic, "topic_id": topic_id })
        getApiSubtopicWiseAnalysis(topic_id)
        setStep(1)
    }

    const hanldeSubtopicClick = (subtopic, subtopic_id, topic_id) => {
        getApiTopicSubtopicWiseAbilityAnalysis(topic_id, subtopic_id)
        setSelectedSubtopic({ "subtopic": subtopic, "subtopic_id": subtopic_id })
        setStep(2)
    }

    const practiceTopic = () => {
        let { topic, topic_id } = selectedTopic
        selectTopics([{ "topic": topic, "id": topic_id }])
        selectSubTopics([])
        getApiSubTopicList(topic_id)
        navigate(SiteMap.SelectSubTopicPage.path)
    }

    const practiceSubtopic = () => {
        let { subtopic, subtopic_id } = selectedSubtopic
        let { topic, topic_id } = selectedTopic
        getApiSubTopicList(topic_id)
        selectTopics([{ "topic": topic, "id": topic_id }])
        selectSubTopics([{ "subtopic": subtopic, "id": subtopic_id }])
        navigate(SiteMap.SelectDifficultyPage.path)
    }

    return (
        <>
            {
                step === 0 &&
                <Box className={styles.topic_wise_tab} >
                    <Box className={styles.sorting_row}>
                        <FormControl className="sorting_dropdown">
                            <InputLabel id="demo-simple-select-standard-label">Sort By</InputLabel>
                            <Select
                                className="dropdown-select"
                                value={topicsSortBy}
                                onChange={(e) => setTopicSortBy(e.target.value)}
                                label="Sort By"
                            >
                                <MenuItem value="" selected disabled>Sort By</MenuItem>
                                <MenuItem value="overall_accuracy">Overall Accuracy</MenuItem>
                                <MenuItem value="practice_sessions_done">Practice Sessions Done</MenuItem>
                                <MenuItem value="questions_practiced">Question Done</MenuItem>
                                <MenuItem value="time_spent">Time Spent</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={styles.sorting_col_row}>
                        <Grid container spacing={4}>
                            {
                                topicWiseAnalysisLoading ?
                                    Array(3).fill().map((topic, key) => <TopicCard key={key} loading />)
                                    :
                                    topicsAnalysis.map((topic) => <TopicCard key={topic.topic_id} topicsAnalysis={topic} hanldeTopicClick={hanldeTopicClick} />)
                            }
                        </Grid>
                    </Box>
                    <Box className="button_list bottom_btn_box footer_button_list sorting_btn_center">
                        <Button className="bottom_btn" onClick={() => viewSuggestion()}>View Suggestions</Button>
                        <Button className="bottom_btn_simple" onClick={() => viewPracticeHistory()}>View Practice History</Button>
                    </Box>
                </Box>
            }
            {step === 1 &&
                <Box className={`${styles.topic_wise_tab_two} ${styles.topic_wise_tab}`} >
                    <Box className={styles.sorting_row}>
                        <Typography onClick={() => setStep(0)} className={styles.back_to_topic_one} >
                            <WestIcon />
                            {selectedTopic.topic}
                        </Typography>
                        <FormControl className="sorting_dropdown">
                            <InputLabel id="demo-simple-select-standard-label">Sort By</InputLabel>
                            <Select
                                value={subTopicsSortBy}
                                onChange={(e) => setSubTopicsSortBy(e.target.value)}
                                label="Sort By"
                            >
                                <MenuItem value="" selected disabled>Sort By</MenuItem>
                                <MenuItem value="overall_accuracy">Overall Accuracy</MenuItem>
                                <MenuItem value="questions_practiced">Question Done</MenuItem>
                                <MenuItem value="time_spent">Time Spent</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className={styles.sorting_col_row}>
                        <Grid container spacing={4}>
                            {subtopicWiseAnalysisLoading ?
                                Array(2).fill().map((subtopic, key) => <SubtopicCard key={key} loading />)
                                :
                                subtopicsAnalysis.map((subtopic) => <SubtopicCard key={subtopic.subtopic_id} subtopicAnalysis={subtopic} hanldeSubtopicClick={hanldeSubtopicClick} />)
                            }
                        </Grid>
                    </Box>
                    <Box className="button_list bottom_btn_box footer_button_list justify_between">
                        <Button className="bottom_btn" onClick={() => practiceTopic()}>Practice this Topic</Button>
                        <Button className="bottom_btn_back" onClick={() => viewSuggestion()}>View Suggestions</Button>
                        <Button className="bottom_btn_simple" onClick={viewPracticeHistory}>View Practice History</Button>
                    </Box>
                </Box>
            }

            {step === 2 &&
                <Box className={`${styles.topic_wise_tab_three} ${styles.topic_wise_tab}`} >
                    <Box className={styles.sorting_row}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography className={styles.back_to_topic_one} onClick={() => setStep(0)}>
                                    <WestIcon />
                                    {selectedTopic.topic}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography className={styles.back_to_sub_topic} onClick={() => setStep(1)}>
                                    <WestIcon />
                                    {selectedSubtopic.subtopic}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={`${styles.ability_row} ${styles.sorting_col_row}`}>

                        <Typography className={styles.ability_wise_analysis_title} variant="h3">
                            Ability-wise Analysis
                        </Typography>
                        <Grid container spacing={4}>
                            <AbilityAnalysisWithProgress
                                data={topicSubtopicWiseAbilityAnalysis || {}}
                                loading={topicSubtopicWiseAbilityAnalysisLoading || false}
                            />
                        </Grid>
                    </Box>
                    <Box className="button_list bottom_btn_box footer_button_list justify_between">
                        <Button className="bottom_btn" onClick={() => practiceTopic()}>Practice this Topic</Button>
                        <Button className="bottom_btn_back" onClick={() => practiceSubtopic()}>Practice this Subtopic</Button>
                        <Button className="bottom_btn_simple" onClick={() => viewSuggestion()}>View Suggestions</Button>
                    </Box>
                </Box>
            }
        </>
    )
}

export default TopicWiseAnalysis;