import { Box, Grid, Typography, LinearProgress, Skeleton } from "@mui/material"
import styles from "./TopicWiseAnalysis.module.scss"
import EastIcon from '@mui/icons-material/East';

const TopicCard = ({ topicsAnalysis, hanldeTopicClick = () => { }, loading }) => {

    const { topic_id,
        topic, overall_accuracy,
        practice_sessions_done,
        questions_practiced,
        time_spent,
        attempt_summary: { correct = 0, incorrect = 0, partially_correct = 0 } = {} } = topicsAnalysis || {}

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box className={styles.first_topic_box}>
                {
                    loading ?
                        <Skeleton height={40} /> :
                        <Typography variant="h6" className={styles.first_topic_title}
                            onClick={() => hanldeTopicClick(topic_id, topic)}
                        >
                            {topic}
                            <EastIcon />
                        </Typography>

                }

                <Typography variant="h6" className={styles.first_topic_overall_accuracy}>
                    Overall Accuracy
                    {loading ? <><Skeleton width={20} />%</> : <span>{overall_accuracy}%</span>}
                </Typography>
                <Box className={styles.first_topic_progress}>
                    <Box aria-describedby="box_right_progress" className={styles.first_topic_progress}>
                        <LinearProgress variant={loading ? "indeterminate" : "determinate"} value={overall_accuracy} />
                    </Box>
                </Box>
                <Box className={styles.first_topic_list}>
                    <ul>
                        <li>
                            <p>Practice Sessions Done</p>
                            {loading ? <Skeleton width={20} /> : <span>{practice_sessions_done}</span>}
                        </li>
                        <li>
                            <p>Questions Practised</p>
                            {loading ? <Skeleton width={20} /> : <span>{questions_practiced}</span>}
                        </li>
                        <li>
                            <p>Attempt Summary</p>
                            <span className={styles.attempt_summary}>
                                {loading ? <Skeleton width={20} /> :
                                    <>
                                        <div className={styles.attempt_summary_green}>{correct}</div>
                                        <div className={styles.attempt_summary_yellow}>{partially_correct}</div>
                                        <div className={styles.attempt_summary_red}>{incorrect}</div>
                                    </>
                                }
                            </span>
                        </li>
                        <li>
                            <p>Time Spent</p>
                            {loading ? <Skeleton width={20} /> : <span>{time_spent}</span>}
                        </li>
                    </ul>
                </Box>
            </Box>
        </Grid>
    )
}

export default TopicCard;