import { getAbilityAnalysis, getConceptAnalysis, getPracticeSummary, getTopSuggestions } from '../../api/apiCalls'
import { DASHBOARD_CONTEXT as context } from '../context'
import { ShowToast } from './ToastActions'

//RESET DASHBOARD REDUCER 
export const resetDashboardReducer = () => ({
    type: context.RESET_DASHBOARD_REDUCER
})

// GET Practice Summary Actions
const getPracticeSummaryRequest = () => ({
    type: context.GET_PRACTICE_SUMMARY_REQUEST,
})

const getPracticeSummaryError = error => ({
    type: context.GET_PRACTICE_SUMMARY_ERROR,
    getPracticeSummaryError: error
})

const getPracticeSummarySuccess = (data) => ({
    type: context.GET_PRACTICE_SUMMARY_SUCCESS,
    practiceSummary: data
})

export const getApiPracticeSummary = (filter) => {
    return (dispatch) => {
        dispatch(getPracticeSummaryRequest())
        getPracticeSummary(filter).then((res) => {
            if (res?.status === 200) {
                dispatch(getPracticeSummarySuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getPracticeSummaryError(res))
            }
        })
    }
}

// GET Top Suggestions Actions
const getTopSuggestionRequest = () => ({
    type: context.GET_TOP_SUGGESTIONS_REQUEST,
})

const getTopSuggestionError = error => ({
    type: context.GET_TOP_SUGGESTIONS_ERROR,
    getTopSuggestionError: error
})

const getTopSuggestionSuccess = (data) => ({
    type: context.GET_TOP_SUGGESTIONS_SUCCESS,
    topSuggestions: data
})

export const getApiTopSuggestions = (filter) => {
    return (dispatch) => {
        dispatch(getTopSuggestionRequest())
        getTopSuggestions(filter).then((res) => {
            if (res?.status === 200) {
                dispatch(getTopSuggestionSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getTopSuggestionError(res))
            }
        })
    }
}

// GET Concept Analysis Actions
const getConceptAnalysisRequest = () => ({
    type: context.GET_CONCEPT_ANALYSIS_REQUEST,
})

const getConceptAnalysisError = error => ({
    type: context.GET_CONCEPT_ANALYSIS_ERROR,
    getConceptAnalysisError: error
})

const getConceptAnalysisSuccess = (data) => ({
    type: context.GET_CONCEPT_ANALYSIS_SUCCESS,
    conceptAnalysis: data
})

export const getApiConceptAnalysis = (filter) => {
    return (dispatch) => {
        dispatch(getConceptAnalysisRequest())
        getConceptAnalysis(filter).then((res) => {
            if (res?.status === 200) {
                dispatch(getConceptAnalysisSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getConceptAnalysisError(res))
            }
        })
    }
}

// GET Concept Ability Actions
const getAbilityAnalysisRequest = () => ({
    type: context.GET_ABILITY_ANALYSIS_REQUEST,
})

const getAbilityAnalysisError = error => ({
    type: context.GET_ABILITY_ANALYSIS_ERROR,
    getAbilityAnalysisError: error
})

const getAbilityAnalysisSuccess = (data) => ({
    type: context.GET_ABILITY_ANALYSIS_SUCCESS,
    abilityAnalysis: data
})

export const getApiAbilityAnalysis = (filter) => {
    return (dispatch) => {
        dispatch(getAbilityAnalysisRequest())
        getAbilityAnalysis(filter).then((res) => {
            if (res?.status === 200) {
                dispatch(getAbilityAnalysisSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getAbilityAnalysisError(res))
            }
        })
    }
}

export const welcomeModalClose = () => ({
    type: context.WELCOME_MODAL_CLOSED
})