const Regx = {
    mobile: /^\d{10}$/,
    email: /^[a-zA-Z0-9_.]+@[a-zA-Z_.]+?\.[a-zA-Z]{2,6}$/,
    pin: /^\d{6}$/,
    plainText: /^[a-zA-Z0-9 ]+$/,
    plaintTextWithoutDigits: /^[a-zA-Z ]+$/,
    textWithSpclChar: /^[a-zA-Z0-9_&.,\- ]+$/,
    textWithMoreSpclChar: /^[a-zA-Z0-9_()-,.&/\- ]+$/,
    number: /^\d+$/,
    emailOrPhone: /^(?:\d{10}|[a-zA-Z0-9_.]+@[a-zA-Z_.]+?\.[a-zA-Z]{2,6})$/,
    customEmailOrPhone: /^(?:\d{10}|[a-zA-Z0-9_.]+@[a-zA-Z0-9_.]+?\.[a-zA-Z]{2,6})$/
}

const DefaultMessagesForRegx = {
    textWithSpclChar: 'The Only allowed special characters are _&.,\-', //eslint-disable-line
    textWithMoreSpclChar: 'The Only allowed special characters are _()\-,.&/', //eslint-disable-line
    noSpclCharAllowed: 'No special characters are allowed',
    noNumbersAllowed: 'Only alphabets and spaces are allowed',
    noDigitsSpclCharAllowed: 'No special characters or numbers are allowed'
}

export {
    Regx,
    DefaultMessagesForRegx
};