import React from 'react'
import { Avatar, Box, Container, Typography } from '@mui/material'
import styles from "../../pages/landingSite/website.module.scss"
import { useNavigate } from 'react-router-dom'
import logowhite from "../../components/assets/media/landingSiteMedia/logowhite.png";
import { SiteMap } from '../../Routes';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const LandingFooter = () => {
    const navigate = useNavigate()
    return (
        <Box className={styles.footer}>
            <Container maxWidth="xl">
                <Box className={styles.footer_details}>
                    <img src={logowhite} alt="logowhite" />
                    <Box className={styles.footer_option}>
                        <Typography className={styles.f_option} onClick={() => navigate('/#features')}>Features</Typography>
                        <Typography className={styles.f_option} onClick={() => navigate('/#testimonials')}>Testimonials</Typography>
                        <Typography className={styles.f_option} onClick={() => navigate('/#team')}>Team</Typography>
                        <Typography className={styles.f_option} onClick={() => navigate('/#pricing')}>Pricing</Typography>
                        <Typography className={styles.f_option} onClick={() => navigate('/#contactus')}>Contact Us</Typography>
                        <Typography className={styles.f_option} onClick={() => navigate(SiteMap.PrivacyPolicy.path)}>Privacy Policy</Typography>
                    </Box>
                    <Box className={styles.footer_option}>
                        <Typography className={styles.f_option} onClick={() => navigate(SiteMap.Terms.path)}>Terms of Services</Typography>
                        <Typography className={styles.f_option} onClick={() => window.location.href = "http://guidance.studdicted.com/"}>StuddictEd Guidance</Typography>
                    </Box>
                </Box>
                <Box className={styles.footerCopy}>
                    <Box className={styles.copy}>
                        <Typography className={styles.copy_details}>© 2022 Thought Leading Education Pvt Ltd. All rights reserved</Typography>
                    </Box>
                    <Box className={styles.social_icon}>
                        <a target="_blank" href="https://www.instagram.com/studdicted.in/" rel="noopener noreferrer"><Avatar className={styles.avatar}>  <InstagramIcon /></Avatar> </a>
                        <a target="_blank" href="https://www.linkedin.com/company/studdicted/" rel="noopener noreferrer"><Avatar className={styles.avatar}> <LinkedInIcon /> </Avatar></a>
                        <a target="_blank" href="https://www.facebook.com/studdicted.in" rel="noopener noreferrer"><Avatar className={styles.avatar}> <FacebookIcon /> </Avatar></a>
                        <a target="_blank" href="https://twitter.com/StuddictEd_in" rel="noopener noreferrer"><Avatar className={styles.avatar}> <TwitterIcon /> </Avatar></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCOlw26k6FCG1tjmgIcNGihg" rel="noopener noreferrer"><Avatar className={styles.avatar}> <YouTubeIcon /> </Avatar></a>
                    </Box>
                </Box>
            </Container>
        </Box >
    )
}

export default LandingFooter