import { AUTH_CONTEXT as context } from '../context';

const INITIAL_STATE = {
    isSessionValid: false,
    isOtpSent: false,
    otpVerificationError: '',
    signInError: '',
    user: {
        email_id: "",
        first_name: "",
        last_name: "",
        mobile_number: "",
        profile_pic: ""
    },
    token: {
        access_token: "",
        refresh_token: "",
        expires_at: ""
    },
    payment_plan: "None",
    currentAction: "INITIAL_STATE"
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case context.REST_AUTH_REDUCER:
            return INITIAL_STATE

        case context.RESET_OTP_STATES:
            return {
                ...state,
                isOtpSent: false,
                isOtpVerified: false,
                otpVerificationError: '',
                signInError: '',
                currentAction: action.type
            }

        case context.OTP_GENERATE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.OTP_GENERATE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                otpGenerateError: action.otpGenerateError,
                currentAction: action.type
            }

        case context.OTP_GENERATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isOtpSent: true,
                otpGenerateError: "",
                currentAction: action.type
            }

        case context.SIGN_IN_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            }

        case context.SIGN_IN_ERROR:
            return {
                ...state,
                loading: false,
                signInError: action.signInError,
                currentAction: action.type
            }

        case context.REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                currentAction: action.type
            }

        case context.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                signInError: "",
                token: action.data.token,
                refreshTokenError: undefined,
                isSessionValid: true,
                currentAction: action.type
            }

        case context.REFRESH_TOKEN_ERROR:
            return {
                ...state,
                loading: false,
                refreshTokenError: action.refreshTokenError,
                currentAction: action.type
            }

        case context.SIGN_IN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                signInError: "",
                user: action.data.user,
                token: action.data.token,
                payment_plan: action.data.payment_plan,
                isSessionValid: true,
                currentAction: action.type
            }

        case context.FORGOT_PASSWORD_OTP_VERIFY_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                verifyingOTP: true,
                currentAction: action.type
            }

        case context.FORGOT_PASSWORD_OTP_VERIFY_ERROR:
            return {
                ...state,
                loading: false,
                verifyingOTP: false,
                otpVerificationError: action.otpVerificationError,
                currentAction: action.type
            }

        case context.FORGOT_PASSWORD_OTP_VERIFY_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isOtpVerified: true,
                verifyingOTP: false,
                otpVerificationError: "",
                currentAction: action.type
            }

        case context.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                resetPasswordError: action.resetPasswordError,
                currentAction: action.type
            }

        case context.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.data,
                currentAction: action.type
            }

        case context.GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.GET_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                getProfileDetailsErr: action.getProfileDetailsErr,
                currentAction: action.type
            }

        case context.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getProfileDetailsErr: "",
                user: { ...state.user, ...action.userProfile },
                currentAction: action.type
            }

        case context.PUT_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.PUT_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                putProfileDetailsErr: action.putProfileDetailsErr,
                currentAction: action.type
            }

        case context.PUT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                putProfileDetailsErr: "",
                user: { ...state.user, ...action.userProfile },
                currentAction: action.type
            }

        case context.LOGOUT_SUCCESS:
            return {
                ...state,
                isSessionValid: false,
                user: {},
                token: {},
                currentAction: action.type
            }

        case context.PATCH_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.PATCH_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                patchPasswordError: action.patchPasswordError,
                currentAction: action.type
            }

        case context.PATCH_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                patchPasswordError: "",
                currentAction: action.type
            }

        case context.GET_AVATARS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.GET_AVATARS_ERROR:
            return {
                ...state,
                loading: false,
                getAvatarsError: action.getAvatarsError,
                currentAction: action.type
            }

        case context.GET_AVATARS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getAvatarsError: "",
                avatarsList: action.avatarsList,
                currentAction: action.type
            }

        case context.POST_BILLING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            }

        case context.POST_BILLING_DETAILS_ERROR:
            return {
                ...state,
                loading: false,
                postBillingDetailsError: action.postBillingDetailsError,
                currentAction: action.type
            }

        case context.POST_BILLING_DETAILS_SUCCESS:
            return {
                ...state,
                user: { ...state.user, callback_form_filled: true },
                loading: false,
                success: true,
                postBillingDetailsError: "",
                currentAction: action.type
            }

        case context.UPDATE_PAYMENT_PLAN:
            return {
                ...state,
                payment_plan: action.payment_plan,
            }

        default:
            return state;
    }
};

export default reducer;