const katex = require('katex');
const { v4: uuidv4 } = require('uuid');

String.prototype.splice = function (idx, rem, str) {
   return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};


export const stripHtml = (html) => {
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   let text = (tmp.textContent || tmp.innerText)
   var regex = /\n/gi;
   var result;
   while ((result = regex.exec(text))) {
      var index = result.index;
      /* check for delimitters
      ** Do this replacement operation only outside latex expressions */
      var count = countOccurance(text.substring(0, index + 1), /\$/gi);
      var openCircularBraceCount = countOccurance(text.substring(0, index + 1), /\/\(/gi);
      var closingCircularBraceCount = countOccurance(text.substring(0, index + 1), /\/\)/gi);
      var openSquareBraceCount = countOccurance(text.substring(0, index + 1), /\/\[/gi);
      var closingSquareBraceCount = countOccurance(text.substring(0, index + 1), /\/\]/gi);
      if (count % 2 == 0 && openCircularBraceCount == closingCircularBraceCount && openSquareBraceCount == closingSquareBraceCount) {
         text = text.splice(index + 1, 0, "$\\newline$");
      }

   }
   return text || "";
}

const countOccurance = (source, searchFor) => {
   if (source == undefined || source == null)
      return 0;
   return (source.match(searchFor) || []).length;
}


const texToHtml = (expression) => {
   expression = stripHtml(expression);
   /* expression = expression.replace('\\\(', '\(');
   expression = expression.replace('\\\)', '\)');
   expression = expression.replace('\\\[', '\[');
   expression = expression.replace('\\\]', '\]'); */
   //expression = '\\(-\\frac{5}{2}, \\frac{25}{4}, -\\frac{125}{8},\\) ____\\(, -\\frac{3125}{32}, &hellip;\\)';
   expression = expression.replace(/\\\(/g, '');
   expression = expression.replace(/\\\)/g, '');
   expression = expression.replace(/\\\[/g, '');
   expression = expression.replace(/\\\]/g, '');
   expression = expression.replaceAll(/\$/g, '');
   return katex.renderToString(expression, { throwOnError: false, displayMode: true });
}

export const renderToHtmlString = (html) => {
   var regex = /\\(|\\)|\\[|\\]|\$/gi;
   var startingDelimitter = null;
   var startingIndex = -1;
   var currentDelimitter = null;
   var endingIndex = -1;
   var result;
   var internalDelimitterStack = [];
   var renderedHtmlMap = {};
   var htmlTmpString = html;

   while ((result = regex.exec(html))) {
      /* if (!['\\)', '\\(', '\\[', '\\]', '\$'].includes(html.substring(result.index, result.index+2)))
         continue; */

      if (startingDelimitter == null) {
         startingIndex = result.index;
         startingDelimitter = html.substring(result.index, result.index + 2);
         if (html.substring(result.index, result.index + 3) != '\$\$' && html.substring(result.index, result.index + 1) == '\$')
            startingDelimitter = '\$';
      } else {
         currentDelimitter = html.substring(result.index, result.index + 2)
         if (startingDelimitter == '\$') {
            if (html.substring(result.index, result.index + 1) == '\$') {
               currentDelimitter = '\$'
            }
         }

         if (
            internalDelimitterStack.length == 0
            && (
               startingDelimitter == '\\(' && currentDelimitter == '\\)'
               || startingDelimitter == '\\[' && currentDelimitter == '\\]'
            )
            || startingDelimitter == '\$' && currentDelimitter == '\$'
            || startingDelimitter == '\$\$' && currentDelimitter == '\$\$'
         ) {
            endingIndex = result.index;
            var uuid = uuidv4();
            var delimitterLength = startingDelimitter.length;
            var expression = html.substring(startingIndex, endingIndex + delimitterLength);
            renderedHtmlMap[uuid] = texToHtml(expression);
            renderedHtmlMap[uuid] = renderedHtmlMap[uuid].replace("katex-display", "katex-display notranslate")
            htmlTmpString = htmlTmpString.replace(expression, uuid)
            /* if (startingIndex ==0)
               html = uuid + html.substring(endingIndex+delimitterLength, html.length)
            else
               html = html.substring(0, startingIndex) + uuid + html.substring(endingIndex+delimitterLength, html.length) */
            startingDelimitter = null;
         } else {
            if (currentDelimitter == '\\(' || currentDelimitter == '\\[')
               internalDelimitterStack.push(currentDelimitter);
            else {
               internalDelimitterStack.pop()
            }

         }
      }
   }

   for (let key in renderedHtmlMap) {
      //console.log(key, renderedHtmlMap[key]);
      htmlTmpString = htmlTmpString.replace(key, renderedHtmlMap[key])
   }
   return htmlTmpString;
}
