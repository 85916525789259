import React from 'react';
import { Box, Button, Modal, TextareaAutosize, Typography } from "@mui/material"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import styles from "./Overlays.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';

const ReportModal = ({ open = false, handleClose = () => { }, customQid = null }) => {
    const practiceState = useSelector(state => state.practice);
    const dispatch = useDispatch();
    const [reportErrorMessage, setReportErrorMessage] = React.useState("")
    const { question_id } = practiceState.question || {}

    React.useEffect(() => {
        setReportErrorMessage("")
    }, [])

    const { getApiReportError } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const handleSubmit = () => {
        let reportErrorPayload = {
            question_id: customQid ?? question_id,
            remark: reportErrorMessage,
        }
        getApiReportError(reportErrorPayload)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="confirmation_modal"
        >
            <Box className={styles.pause_session_modal}>
                <>
                    <Button className={styles.close_modal} onClick={handleClose}><HighlightOffOutlinedIcon /></Button>
                    <Box className={styles.pause_session_modal_inner}>
                        {
                            practiceState.currentAction === "REPORT_ERROR_SUCCESS" ?
                                <>
                                    <Typography className={styles.modal_title}>Error Reported</Typography>

                                    <Typography>Thank you for bringing this error to our notice. We will review and rectify this error and make sure that such errors do not repeat in the future.</Typography>

                                </> :
                                <>
                                    <Typography className={styles.modal_title}>Report an error</Typography>
                                    <Typography className={styles.report_title}>Tell us what's wrong with the question or its solution:</Typography>
                                    <Typography className={styles.report_sub_title}>Please mention as many details as you can to help us pinpoint to the problem.</Typography>
                                    <TextareaAutosize
                                        aria-label="textarea"
                                        minRows={10}
                                        value={reportErrorMessage}
                                        onChange={(e) => setReportErrorMessage(e.target.value)}
                                    />
                                    <Box className="button_list bottom_btn_box justify-content-center">
                                        <Button disabled={!reportErrorMessage || practiceState?.loading} className="bottom_btn" onClick={handleSubmit}>Report Error</Button>
                                    </Box>
                                </>
                        }
                    </Box>
                </>
            </Box>
        </Modal>
    )
}

export default ReportModal