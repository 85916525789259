import React from "react";
import {
	FormikForm,
	FormikTextField,
	PasswordField,
	FormikOTPField,
} from "..";
import { EmailOrPhoneValidator } from "../assets"
import { Box, Button, Collapse, FormHelperText } from "@mui/material";
import { actions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { SiteMap } from "../../Routes";
import { Link, useNavigate } from "react-router-dom"
import { Form } from "formik";

const ForgotPassword = () => {
	const ref = React.useRef(null)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authState = useSelector(state => state.auth)
	const { isOtpSent, isOtpVerified, verifyingOTP, otpVerificationError } = authState || {}

	const { resetOTPStates,
		getApiForgotPasswordOtpGenerate,
		getApiOtpVerification,
		getApiResetPassword } = bindActionCreators(
			actions.auth,
			dispatch
		);

	const [otpFieldState, setOtpFieldState] = React.useState(false);
	const [otpTimer, setOtpTimer] = React.useState(-1);
	const [otp, setOtp] = React.useState("")
	const [resetOtpField, setResetOtpField] = React.useState(false)

	const sendOtp = () => {
		ref.current.setFieldTouched("user_contact").then(() => {
			if (!ref.current.errors.hasOwnProperty("user_contact")) {
				resetOTPFields()
				if (otpTimer === -1 || !isOtpSent || !otpFieldState) {
					setOtpTimer(30)
					getApiForgotPasswordOtpGenerate({ "user_contact": ref.current.values.user_contact })
				}
				setOtpFieldState(true);
			}
		})
	};

	const verifyOtp = (otpStr) => {
		if (!ref.current.errors.hasOwnProperty("user_contact") && isOtpSent && otpStr) {
			getApiOtpVerification({ "user_contact": ref.current.values.user_contact, "otp": otpStr })
		}
	}

	React.useEffect(() => {
		resetOTPStates()  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		let timer
		if (otpFieldState === true && isOtpSent && otpTimer > 0) {
			timer = setTimeout(() => {
				setOtpTimer(otpTimer - 1)
			}, 1000)
		} else if (otpTimer === 0 && isOtpSent) {
			setOtpTimer(-1)
			setOtpFieldState(false)
		}
		return () => {
			clearTimeout(timer)
		}  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otpFieldState, otpTimer, isOtpSent])

	React.useEffect(() => {
		if (authState.currentAction === "RESET_PASSWORD_SUCCESS") {
			navigate(SiteMap.PasswordLoginPage.path)
		}  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.currentAction])

	const handleOTPChange = (otpStr) => {
		setOtp(otpStr)
		if (otpStr.length === Number(process.env.REACT_APP_OTP_LENGTH) && !isOtpVerified) verifyOtp(otpStr)
	}

	const resetOTPFields = () => {
		setResetOtpField(!resetOtpField)
		for (let i = 0; i < Number(process.env.REACT_APP_OTP_LENGTH); i++) {
			ref.current.setFieldValue(`otpsubfield${i}`, "")
		}
	}

	const handleSubmit = data => {
		let payload = {
			"user_contact": data.user_contact,
			"password": data.password,
			"otp": otp
		}
		getApiResetPassword(payload)
	}


	return (
		<FormikForm validator={EmailOrPhoneValidator} innerRef={ref} onSubmit={handleSubmit}>
			{({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
				<Form
					onSubmit={handleSubmit}
					onChange={e => {
						handleChange(e);
					}}
					onBlur={handleBlur}
					onReset={resetForm}
				>
					<Box className="forgot_password">
						<div className="reg-title">
							<h1>{isOtpVerified ? "Change" : "Forgot"} Password</h1>
						</div>
						<Collapse in={!isOtpVerified}>
							<div className="custom-form-field">
								<label className="form-label">Email or Mobile Number</label>
								<FormikTextField
									className="white-bg-field"
									name="user_contact"
									label=""
									onChange={() => resetOTPStates()}
									InputProps={{
										endAdornment: (
											<Button
												onClick={sendOtp}
												className="ghost-button-primary"
												variant="text"
												disabled={otpTimer !== 0 && otpFieldState && isOtpSent}
											>
												{!isOtpSent ? "Send" : "Resend"}
											</Button>
										),
									}}
								/>
								{otpFieldState === true && isOtpSent && otpTimer !== 0 &&
									<Box display={"flex"} justifyContent={"flex-end"}>
										<Box padding={"6px"} className="ghost-button-primary">Resend in {otpTimer}</Box>
									</Box>
								}
							</div>
						</Collapse>

						<Collapse in={isOtpSent && !isOtpVerified}>
							<div className="custom-form-field">
								<label className="form-label">One-time Password (OTP)</label>
								<FormikOTPField
									resetOTPFields={resetOtpField}
									className="white-bg-field otp-field"
									name="otp"
									label=""
									otpLength={process.env.REACT_APP_OTP_LENGTH ?? 6}
									onChange={handleOTPChange}
								/>
								{verifyingOTP && <FormHelperText className="form-success">Verifying...</FormHelperText>}
								{otpVerificationError && <FormHelperText className="form-errors">Invalid OTP</FormHelperText>}
							</div>
						</Collapse>
						<Collapse in={isOtpVerified}>
							<div className="custom-form-field">
								<label className="form-label">Create New Password</label>
								<PasswordField
									name="password"
									className="white-bg-field"
									label=""
								/>
							</div>
						</Collapse>
						<div className="form-action-button">
							{
								isOtpVerified &&
								<Button
									id="submit"
									type="submit"
									size="large"
									disabled={!isOtpVerified || !(dirty && isValid)}
									className="form-action-button-blue"
									variant="contained"
								>
									Reset Password
								</Button>
							}
						</div>
						<div className="login-route-option">
							<ul>
								<li>
									<Link to={SiteMap.OTPLoginPage.path}>
										Login with OTP
									</Link>
								</li>
								<li>
									<Link to={SiteMap.SignupPage.path}>
										Sign Up
									</Link>
								</li>
							</ul>
						</div>
					</Box>
				</Form>)}
		</FormikForm>
	);
};

export default ForgotPassword;
