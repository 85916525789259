import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actions } from '../../redux'
import { SiteMap } from '../../Routes'
import styles from './Suggestions.module.scss'

const SuggestionTab = ({ TabTitle, TabData, clickable = false, message }) => {

    let { subtopics = [] } = TabData || {}

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { selectTopics, selectSubTopics } = bindActionCreators(
        actions.ui,
        dispatch
    );

    const { getApiSubTopicList } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const handleClick = (selected) => {
        if (!clickable) return;
        let { topic_id, topic, subtopic_id, subtopic } = selected
        selectTopics([{ "topic": topic, "id": topic_id }])
        if (topic_id && subtopic_id) {
            getApiSubTopicList(topic_id)
            selectSubTopics([{ "subtopic": subtopic, "id": subtopic_id }])
            navigate(SiteMap.SelectDifficultyPage.path)
        }
    }

    return (
        <>
            {
                ((!subtopics) || (subtopics.length === 0)) ?
                    <p>{message || "No values available here"}</p>
                    :
                    <React.Fragment>
                        <Typography className={styles.suggestion_wrap_title}>
                            {TabTitle}:
                        </Typography>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className={styles.suggestion_wrap_box}>
                                    <Box className={styles.suggestion_wrap_box_title}>
                                        <Typography>Grade</Typography>
                                        <Typography>Topic</Typography>
                                        <Typography>Subtopic</Typography>
                                    </Box>
                                    {
                                        subtopics.length > 0 &&
                                        subtopics.map((item, index) => (
                                            <Box key={item?.topic + index} onClick={() => handleClick(item)}
                                                className={`${styles.suggestion_wrap_inner_box} ${styles.suggestion_wrap_inner_box_theme2}  ${clickable ? styles.clickable : ""}`}>
                                                <Typography className={styles.suggestion_wrap_inner_box_left}>
                                                    {item?.grade}
                                                </Typography>
                                                <Typography className={styles.suggestion_wrap_inner_box_center}>
                                                    {item?.topic}
                                                </Typography>
                                                <Typography className={styles.suggestion_wrap_inner_box_right}>
                                                    {item?.subtopic}
                                                </Typography>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </React.Fragment>
            }

        </>
    )
}

export default SuggestionTab