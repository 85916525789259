import { Skeleton } from "@mui/material"
import React from "react"

const OptionSkeleton = props => {
    return (
        <div className="option-skeleton-wraper">
            <Skeleton className='mb1 semi-rounded' variant="rectangular" width={30} height={30} />
            <Skeleton className='mb1 semi-rounded ml1' variant="rectangular" width={420} height={30} />
        </div>
    )
}


export default OptionSkeleton