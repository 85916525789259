import React from "react";
import styles from "./PaymentForm.module.scss"
import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { FormikForm, FormikTextField, FormikSelect } from ".."
import { Link, useNavigate } from "react-router-dom";
import { SiteMap } from "../../Routes";
import { CountriesOption, IndianStatesOption, paymentFormValidator } from "../assets";
import { actions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { postPaymentInit, postPaymentValidation } from "../../api/apiCalls";
import { Form } from "formik";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const PaymentForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const paymentState = useSelector(state => state.payment)
    const authState = useSelector(state => state.auth)
    const { first_name, last_name, parent_name, email_id, mobile_number } = authState.user || {}
    const { billingDetails } = paymentState || {}
    const { amount, coupon, discount, gst, total } = billingDetails || {}
    const PaymentFormref = React.useRef(null)
    const [agreeToTerms, setAgreeToTerms] = React.useState(true)

    const { ShowToast } = bindActionCreators(
        actions.toast,
        dispatch
    );

    const { getApiTopicList } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const { postApiGenerateBilling } = bindActionCreators(
        actions.payment,
        dispatch
    );

    React.useEffect(() => {
        postApiGenerateBilling({
            "coupon": null,
            "gstin": null
        })// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleRazorPay = (razopayResponse) => {
        //on successfull payment
        postPaymentValidation(razopayResponse)
            .then((res) => {
                let { message } = res.data || {}
                ShowToast({ "message": message })
                getApiTopicList()
                navigate(SiteMap.DashboardPage.path)
            })
    }

    async function initiatePayment(data) {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            ShowToast({ "message": "We are unable to process your payment request, please try after sometime.", "severity": "error" })
            return
        }

        let PaymentInitData = {
            "coupon": coupon,
            "gstin": data.GSTIN,
            "first_name": data.first_name,
            "last_name": data.last_name,
            "email": data.email_id,
            "mobile_number": data.mobile_number,
            "billing_state": data.billing_state,
            "billing_country": data.billing_state,
            "shipping_state": data.shipping_state,
            "shipping_country": data.shipping_country
        }

        const apiRes = await postPaymentInit(PaymentInitData)

        if (apiRes.status === 200) {
            const { data } = apiRes.data
            const { amount, currency, description, id } = data

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                currency: currency,
                amount: amount.toString(),
                order_id: id,
                name: 'Studdicted',
                description: description,
                image: 'https://mentor-studdicted.s3.ap-south-1.amazonaws.com/social_media_icons/logo.png',
                handler: handleRazorPay, // callback
                prefill: {
                    name: parent_name ?? "",
                    email: email_id ?? "",
                    contact: mobile_number ? mobile_number.toString() : "",
                },
                theme: {
                    color: "#4200FF",
                },
            }
            const paymentObject = new window.Razorpay(options)
            paymentObject.open()
        }
    }

    const handleCopyBillingAddress = () => {
        let { values } = PaymentFormref.current || {}
        PaymentFormref.current.setFieldValue('shipping_country', values?.billing_country)
        PaymentFormref.current.setFieldValue('shipping_state', values?.billing_state)
    }

    const handleCouponCode = () => {
        let { values } = PaymentFormref.current || {}
        if (values?.coupon) {
            postApiGenerateBilling({
                "coupon": values?.coupon,
                "gstin": values?.GSTIN,
            })
        }
    }

    const hanldeRemove = () => {
        let { values, setFieldValue = () => { } } = PaymentFormref.current || {}
        postApiGenerateBilling({
            "coupon": null,
            "gstin": values?.GSTIN,
        })
        setFieldValue("coupon", "")
    }

    const handleSubmit = data => {
        if (!agreeToTerms) {
            ShowToast({ "message": "You must agree to the Terms and Privacy Policy", "severity": "error" })
            return
        }
        initiatePayment(data)
    }

    return (
        <Box className={styles.payment_form}>
            <Box className={styles.form_title}>
                StuddictEd Digital - Upgrade
            </Box>
            <Box className={styles.form_box}>
                <FormikForm
                    innerRef={PaymentFormref}
                    initialValues={{
                        first_name: first_name,
                        last_name: last_name,
                        email_id: email_id,
                        mobile_number: mobile_number,
                        shipping_country: CountriesOption[0].value,
                        billing_country: CountriesOption[0].value
                    }}
                    onSubmit={handleSubmit}
                    validator={paymentFormValidator}
                >
                    {({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
                        <Form
                            onSubmit={handleSubmit}
                            onChange={e => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            onReset={resetForm}
                        >
                            <Box className={styles.ammount_box}>
                                <Typography>Amount</Typography>
                                <Typography className={styles.ammount}>{amount ? `₹${amount.toLocaleString()}` : `₹7,500.00`}</Typography>
                            </Box>
                            <Box className={styles.ammount_box}>
                                <Grid direction="row" container spacing={3}>
                                    <Grid item xs={6}><Typography>{`GSTIN (Optional)`}</Typography></Grid>
                                    <Grid item xs={6}>
                                        <div className="custom-form-field">
                                            <FormikTextField className="white-bg-field" name="GSTIN" label="" />
                                        </div>
                                    </Grid>

                                    {coupon && <>
                                        <Grid item xs={6}><Typography>{coupon}</Typography></Grid>
                                        <Grid item xs={6}>
                                            <Typography className={styles.ammount}>{discount.toLocaleString()}
                                                <span onClick={hanldeRemove}>Remove</span>
                                            </Typography>
                                        </Grid>
                                    </>
                                    }
                                    <Grid item xs={6}><Typography>{`GST (18%)`}</Typography></Grid>
                                    <Grid item xs={6}><Typography className={styles.ammount}>{gst ? `₹${gst.toLocaleString()}` : `₹1,350.00`}</Typography></Grid>
                                </Grid>
                            </Box>
                            <Box className={styles.ammount_box}>
                                <Grid item xs={6}>
                                    <div className="custom-form-field">
                                        <FormikTextField className="white-bg-field" placeholder="Coupon Code" name="coupon" label="" disabled={!!coupon} />
                                        {coupon &&
                                            <Typography className={styles.coupon_text_success}>Coupon Applied Successfully</Typography>}
                                    </div>
                                </Grid>
                                <Grid item sm={3}>
                                    <Box className={`bottom_btn_box ${styles.ammount}`}>
                                        <Button className="bottom_btn" disabled={!!coupon} onClick={handleCouponCode}>{coupon ? 'Applied' : 'Apply'}</Button>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box className={`${styles.ammount_box} ${styles.total_ammount}`}>
                                <Typography>Total</Typography>
                                <Typography className={styles.ammount}>{total ? `₹${total.toLocaleString()}` : `₹8,850.00`}</Typography>
                            </Box>

                            <Box className={styles.contact_form}>

                                <h1>Contact Details</h1>

                                <Grid direction="row" container spacing={3} className="field-outer">
                                    <Grid container item xs={6}>
                                        <div className="custom-form-field">
                                            <label className="form-label">First Name</label>
                                            <FormikTextField
                                                className="white-bg-field"
                                                name="first_name"
                                                label=""
                                            />
                                        </div>
                                    </Grid>
                                    <Grid container item xs={6}>
                                        <div className="custom-form-field">
                                            <label className="form-label">Last Name</label>
                                            <FormikTextField
                                                className="white-bg-field"
                                                name="last_name"
                                                label=""
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid direction="row" container className="field-outer">
                                    <Grid container item sm={12}>
                                        <div className="custom-form-field">
                                            <label className="form-label">Email</label>
                                            <FormikTextField className="white-bg-field" name="email_id" label="" />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid direction="row" container className="field-outer">
                                    <Grid container item sm={12}>
                                        <div className="custom-form-field">
                                            <label className="form-label">Mobile</label>
                                            <FormikTextField className="white-bg-field" name="mobile_number" label="" />
                                        </div>
                                    </Grid>
                                </Grid>

                                <h1>Billing Address</h1>

                                <Grid direction="row" container spacing={3} className="field-outer">
                                    <Grid container item xs={12} sm={6}>
                                        <div className="custom-form-field">
                                            <label className="form-label">Country</label>
                                            <FormikSelect
                                                items={CountriesOption}
                                                placeholder="Choose your Country"
                                                className="white-bg-field custom-select"
                                                name="billing_country"
                                                label=""
                                            />
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6}>
                                        <div className="custom-form-field">
                                            <label className="form-label">State</label>
                                            <FormikSelect
                                                items={IndianStatesOption}
                                                placeholder="Choose your State"
                                                className="white-bg-field custom-select"
                                                name="billing_state"
                                                label=""
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Box className={styles.h1_with_subheader}>
                                    <h1>Shipping Address</h1>
                                    <span onClick={handleCopyBillingAddress}>Copy Billing Address</span>
                                </Box>
                                <Grid direction="row" container spacing={3} className="field-outer">
                                    <Grid container item xs={12} sm={6}>
                                        <div className="custom-form-field">
                                            <label className="form-label">Country</label>
                                            <FormikSelect
                                                items={CountriesOption}
                                                placeholder="Choose your Country"
                                                className="white-bg-field custom-select"
                                                name="shipping_country"
                                                label=""
                                            />
                                        </div>
                                    </Grid>
                                    <Grid container item xs={12} sm={6}>
                                        <div className="custom-form-field">
                                            <label className="form-label">State</label>
                                            <FormikSelect
                                                items={IndianStatesOption}
                                                placeholder="Choose your State"
                                                className="white-bg-field custom-select"
                                                name="shipping_state"
                                                label=""
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid direction="row" container spacing={3} className="field-outer">
                                    <Grid container item sm={12}>
                                        <FormControlLabel className={styles.terms_text}
                                            onChange={() => setAgreeToTerms(!agreeToTerms)}
                                            control={<Checkbox checked={agreeToTerms} id={"TermsCheckbox"} />}
                                            label={
                                                <>
                                                    By clicking <span>'Checkout with Razorpay'</span> {`you agree to the `}
                                                    <Link to={SiteMap.Terms.path}>Terms of Service</Link> and <Link to={SiteMap.PrivacyPolicy.path}>Privacy Policy</Link>
                                                </>}
                                        />
                                    </Grid>
                                </Grid>

                                <Box className={styles.checkout_box}>
                                    <Box className={`bottom_btn_box`}>
                                        <Button className="bottom_btn" id="submit" disabled={!agreeToTerms || !(dirty && isValid)}
                                            type="submit">Checkout with Razorpay</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Form>)}
                </FormikForm>
            </Box>
        </Box>
    )
}

export default PaymentForm;