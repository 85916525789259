import React from "react";
import { BaseLayout, ChooseLoginMethod } from "../../components";

const HomePage = () => {
	return (
		<BaseLayout>
			<ChooseLoginMethod />
		</BaseLayout>
	);
};

export default HomePage;
