import { deleteBookmarkQuestion, getBookmarkQuestionDetails, getBookmarksList, postBookmarkQuestion } from '../../api/apiCalls';
import { BOOKMARK_CONTEXT as context } from '../context';
import { setBookmarkStatus } from './practiceActions';
import { ShowToast } from './ToastActions';

// RESET BOOKMARK REDUCER
export const resetBookmarkReducer = () => ({
    type: context.RESET_BOOKMARK_REDUCER,
})

// POST Add Question to Bookmark Actions
const postBookmarkRequest = () => ({
    type: context.POST_BOOKMARK_REQUEST,
})

const postBookmarkError = error => ({
    type: context.POST_BOOKMARK_ERROR,
    postBookmarkError: error
})

const postBookmarkSuccess = () => ({
    type: context.POST_BOOKMARK_SUCCESS,
})

export const postApiBookmarkQuestion = (question_id) => {
    return (dispatch) => {
        dispatch(postBookmarkRequest())
        postBookmarkQuestion(question_id).then((res) => {
            if (res?.status === 200) {
                dispatch(postBookmarkSuccess())
                dispatch(setBookmarkStatus(res?.data?.data))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Bookmarked" }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(postBookmarkError(res))
            }
        })
    }
}

// DELETE Remove Question from Bookmark Actions
const deleteBookmarkRequest = () => ({
    type: context.DEL_BOOKMARK_REQUEST,
})

const deleteBookmarkError = error => ({
    type: context.DEL_BOOKMARK_ERROR,
    deleteBookmarkError: error
})

const deleteBookmarkSuccess = () => ({
    type: context.DEL_BOOKMARK_SUCCESS,
})

export const deleteApiBookmarkQuestion = (bookmark_id) => {
    return (dispatch) => {
        dispatch(deleteBookmarkRequest())
        deleteBookmarkQuestion(bookmark_id).then((res) => {
            if (res?.status === 200) {
                dispatch(deleteBookmarkSuccess())
                dispatch(setBookmarkStatus(res?.data?.data))
                dispatch(ShowToast({ "message": res?.data?.message ?? "Deleted from Bookmarks" }))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(deleteBookmarkError(res))
            }
        })
    }
}

// GET Bookmarks List
const getBookmarkListRequest = () => ({
    type: context.GET_BOOKMARKS_LIST_REQUEST,
})

const getBookmarkListError = error => ({
    type: context.GET_BOOKMARKS_LIST_ERROR,
    getBookmarkListError: error
})

const getBookmarkListSuccess = (data) => ({
    type: context.GET_BOOKMARKS_LIST_SUCCESS,
    BookmarksList: data
})

export const getApiBookmarskList = () => {
    return (dispatch) => {
        dispatch(getBookmarkListRequest())
        getBookmarksList().then((res) => {
            if (res?.status === 200) {
                dispatch(getBookmarkListSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getBookmarkListError(res))
            }
        })
    }
}

// GET Bookmarked Question Detail Actions
const getBookmarkedQuestionRequest = () => ({
    type: context.GET_BOOKMARK_DETAIL_REQUEST,
})

const getBookmarkedQuestionError = error => ({
    type: context.GET_BOOKMARK_DETAIL_ERROR,
    getBookmarkedQuestionError: error
})

const getBookmarkedQuestionSuccess = (data) => ({
    type: context.GET_BOOKMARK_DETAIL_SUCCESS,
    BookmarkedQuestion: data
})

export const getApiBookmarskedQuestion = (bookmark_id) => {
    return (dispatch) => {
        dispatch(getBookmarkedQuestionRequest())
        getBookmarkQuestionDetails(bookmark_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getBookmarkedQuestionSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getBookmarkedQuestionError(res))
            }
        })
    }
}