import { Box, Grid, Skeleton } from "@mui/material"
import React from "react";

const ConceptBox = ({ title, data, styleClass = "", message, loading }) => {

    return (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <Box className={styleClass}>
                {
                    loading ?
                        <React.Fragment>
                            <Skeleton className='custom-rounded-skeleton' variant="rounded" height={40} />
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton width={'50%'} animation={false} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h6>
                                {title}
                            </h6>
                            {
                                data ?
                                    data.map((item, index) => (
                                        <Box key={item + index}>
                                            <p>{typeof item === "string" ? item : ""}</p>
                                        </Box>
                                    )) :
                                    <p>{message || "No data available here"}</p>
                            }
                        </React.Fragment>
                }

            </Box>
        </Grid>
    )
}

export default ConceptBox;