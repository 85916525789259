import React from "react";
import {
	FormikForm,
	FormikTextField,
	PasswordField,
} from "..";

import { Box, Button, Skeleton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom"
import { SiteMap } from "../../Routes";
import { LoginWithPasswordValidator } from "../assets";
import { bindActionCreators } from "redux";
import { actions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "formik";

const LoginWithPassword = () => {

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const authState = useSelector(state => state.auth)
	const { loading } = authState
	const { getApiSignInWithPassword, resetAuthReducer } = bindActionCreators(
		actions.auth,
		dispatch
	);

	React.useEffect(() => {
		if (authState.currentAction === "SIGN_IN_SUCCESS") navigate(SiteMap.DashboardPage.path)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.currentAction])

	React.useEffect(() => {
		resetAuthReducer()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSubmit = data => {
		getApiSignInWithPassword(data)
	}

	return (
		<FormikForm onSubmit={handleSubmit} validator={LoginWithPasswordValidator}>
			{({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
				<Form
					onSubmit={handleSubmit}
					onChange={e => {
						handleChange(e);
					}}
					onBlur={handleBlur}
					onReset={resetForm}
				>
					<Box className="login_password">
						<div className="reg-title">
							<h1>Login to Existing Account</h1>
						</div>
						<div className="field-outer">
							<div className="custom-form-field">
								<label className="form-label">Email or Mobile Number</label>
								<FormikTextField
									className="white-bg-field"
									name="user_contact"
									label=""
								/>
							</div>
						</div>
						<div className="custom-form-field">
							<label className="form-label">Password</label>
							<PasswordField
								name="password"
								className="white-bg-field"
								label=""
							/>
						</div>
						<div className="form-action-button">
							{loading ?
								<Skeleton variant="circular" className="form-action-button-blue" height={55}>Logging In</Skeleton>
								: <Button
									id="login"
									type="submit"
									size="large"
									className="form-action-button-blue"
									variant="contained"
									disabled={!(dirty && isValid)}
								>
									Login
								</Button>
							}

						</div>
						<div className="login-route-option">
							<ul>
								<li>
									<Link to={SiteMap.ForgotPasswordPage.path}>
										Forgot Password?
									</Link>
								</li>
								<li>
									<Link to={SiteMap.OTPLoginPage.path}>
										Login using OTP
									</Link>
								</li>
								<li>
									<Link to={SiteMap.SignupPage.path}>
										New user? Create Account
									</Link>
								</li>
							</ul>
						</div>
					</Box>
				</Form>)}
		</FormikForm>
	);
};

export default LoginWithPassword;
