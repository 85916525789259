import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Modal, Radio, RadioGroup, Skeleton } from '@mui/material';
import React from 'react';
import styles from "./Overlays.module.scss";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WarningIcon from '@mui/icons-material/Warning';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import ReportModal from './ReportModal';
import { SiteMap } from "../../Routes";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
//import Latex from "react-latex";
//import { stripHtml } from '../assets';
import { renderToHtmlString } from '../assets/utils/utils';
import ZoomableImage from '../ZoomableImage/ZoomableImage';

const BookmarkModal = ({ open = false, handleClose = () => { } }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getApiSubTopicList } = bindActionCreators(
        actions.practice,
        dispatch
    );
    const { selectTopics, selectSubTopics } = bindActionCreators(
        actions.ui,
        dispatch
    );

    const [showHint, setShowHint] = React.useState(false)
    const [showSolution, setShowSolution] = React.useState(false)
    const [showReportModal, setShowReportModal] = React.useState(false)
    const toggleHint = (value) => { setShowHint(value); setShowSolution(false) }
    const toggleSolution = (value) => { setShowSolution(value); setShowHint(false) }
    const bookmarkState = useSelector(state => state.bookmark)
    const isLoading = bookmarkState.currentAction === "GET_BOOKMARK_DETAIL_REQUEST"
    const { BookmarkedQuestion = {} } = bookmarkState || {}
    const {
        after_image_text,
        answers = [],
        difficulty,
        hint,
        image_url,
        options = [],
        question_id,
        question_text,
        question_type,
        reality_attributes,
        solution,
        subtopic,
        subtopic_id,
        topic,
        topic_id,
    } = BookmarkedQuestion || {}

    const handleClick = (type) => {
        selectTopics([{ "topic": topic, "id": topic_id }])
        getApiSubTopicList(topic_id)
        if (type === "subtopic") {
            selectSubTopics([{ "subtopic": subtopic, "id": subtopic_id }])
            navigate(SiteMap.SelectDifficultyPage.path)
        } else {
            navigate(SiteMap.SelectSubTopicPage.path)
        }
    }

    const handleCloseBookmarkModal = () => {
        handleClose()
        setShowHint(false)
        setShowSolution(false)
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseBookmarkModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="pause_session_modal"
            >
                <Box className={styles.BookmarkModal}>

                    <HighlightOffOutlinedIcon className={styles.close_icon} onClick={handleCloseBookmarkModal} />
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                            <Box className={styles.question_box_left}>
                                {isLoading ? <Skeleton variant="text" /> : <div dangerouslySetInnerHTML={{ __html: renderToHtmlString(question_text) }} />}
                                {/* <Latex displayMode={true} output="mathml">{stripHtml(question_text)}</Latex> */}
                                {!isLoading && image_url && <ZoomableImage src={image_url} alt="bookmarkImage" />}
                                {!isLoading && after_image_text && <div dangerouslySetInnerHTML={{ __html: renderToHtmlString(after_image_text) }} />}
                                {/* <Latex displayMode={true} output="mathml">{stripHtml(after_image_text)}</Latex> */}
                                <Box className={styles.radio_list}>

                                    <FormControl>

                                        {!isLoading && options.length > 0 ? question_type === "MCQ - Single Correct" ?
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="female"
                                                name="radio-buttons-group"
                                                className={styles.questionLabels}
                                            >
                                                {
                                                    options.map((option) => (
                                                        <FormControlLabel
                                                            className={answers?.some((item) => item.id === option.id) ? `${styles.correct} ${styles.option_entry}` : styles.option_entry}
                                                            // onClick={() => handleAnswerSelection(option)}
                                                            checked={answers?.some((item) => item.id === option.id)}
                                                            key={option.id} value={option.id}
                                                            control={<Radio color="default" />}
                                                            label={<span className={`${styles.label}`}><span dangerouslySetInnerHTML={{ __html: renderToHtmlString(option.label) }} />{/* <Latex displayMode={true} output="mathml">{stripHtml(option.label)}</Latex> */}</span>}
                                                        />
                                                    ))
                                                }
                                            </RadioGroup>
                                            :
                                            <FormGroup>
                                                {
                                                    options.map((option) => (
                                                        <FormControlLabel
                                                            className={answers?.some((item) => item.id === option.id) ? `${styles.correct} ${styles.option_entry}` : styles.option_entry}
                                                            // onClick={() => handleAnswerSelection(option)}
                                                            checked={answers?.some((item) => item.id === option.id)}
                                                            key={option.id}
                                                            value={option.id}
                                                            control={<Checkbox color="default" />}
                                                            label={<span className={`${styles.label}`}><span dangerouslySetInnerHTML={{ __html: renderToHtmlString(option.label) }} />{/* <Latex displayMode={true} output="mathml">{stripHtml(option.label)}</Latex> */}</span>}
                                                        />
                                                    ))
                                                }
                                            </FormGroup>
                                            :
                                            <>
                                                <Skeleton variant="rounded" width={500} height={30} />
                                                <Skeleton variant="rounded" width={500} height={30} />
                                                <Skeleton variant="rounded" width={500} height={30} />
                                                <Skeleton variant="rounded" width={500} height={30} />
                                            </>
                                        }

                                    </FormControl>

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
                            <Box className={styles.question_box_right}>
                                <Box className={styles.question_properties}>
                                    <h4>Question Properties:</h4>
                                    <Box className={styles.question_properties_inner}>
                                        {
                                            isLoading ? Array(3).fill().map((item, index) => (
                                                <p key={index}><Skeleton variant="text" /></p>
                                            ))
                                                :
                                                <>
                                                    <p>{question_type}</p>
                                                    <p>Difficulty: {difficulty}</p>
                                                    <p>{reality_attributes}</p>
                                                </>
                                        }
                                    </Box>
                                </Box>

                                <Box className={`${styles.right_animated_box} ${showHint ? styles.animated_box_active : ""}`} >
                                    <Button className={styles.right_animated_box_btn} onClick={() => toggleHint(true)}>
                                        <LightbulbIcon />
                                        Hint
                                    </Button>
                                    <Box className={styles.right_animated_box_inner}>
                                        <h4>
                                            <LightbulbIcon />
                                            Hint

                                        </h4>
                                        <Button disabled={!showHint} className={styles.right_animated_box_close} onClick={() => toggleHint(false)}>
                                            <HighlightOffOutlinedIcon />
                                        </Button>
                                        <Box className={styles.content}>
                                            <div dangerouslySetInnerHTML={{ __html: renderToHtmlString(hint) }}></div>
                                            {/* <Latex>{stripHtml(hint)}</Latex> */}
                                        </Box>
                                        {/* <p dangerouslySetInnerHTML={{ __html: hint }}></p> */}
                                    </Box>
                                </Box>


                                <Box className={`${styles.right_animated_box} ${showSolution ? styles.animated_box_active : ""}`} >
                                    <Button className={styles.right_animated_box_btn} onClick={() => toggleSolution(true)}>
                                        <WbIncandescentIcon className="rotate-180D" />
                                        Solution
                                    </Button>
                                    <Box className={styles.right_animated_box_inner}>
                                        <h4>
                                            <WbIncandescentIcon className="rotate-180D" />
                                            Solution

                                        </h4>
                                        <Button disabled={!showSolution} className={styles.right_animated_box_close} onClick={() => toggleSolution(false)}>
                                            <HighlightOffOutlinedIcon />
                                        </Button>
                                        <Box className={styles.content}>
                                            <div dangerouslySetInnerHTML={{ __html: renderToHtmlString(solution) }}></div>
                                            {/* <Latex>{stripHtml(solution)}</Latex> */}
                                        </Box>
                                        {/* <p dangerouslySetInnerHTML={{ __html: solution }}></p> */}
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={12} order={3}>
                            <Box className={`footer_button_list bottom_btn_box ${styles.model_footer}`}>
                                <Button className='bottom_btn_back' onClick={() => handleClick('subtopic')}>Practice Subtopic</Button>
                                <Button className='bottom_btn' onClick={() => handleClick('topic')}>Practice Topic</Button>

                                <Button className={styles.report_button} onClick={() => setShowReportModal(true)}> <WarningIcon /> Report an Error</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <ReportModal open={showReportModal} handleClose={() => setShowReportModal(false)} customQid={question_id} />
        </>
    )
}

export default BookmarkModal