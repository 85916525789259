import { Button, Grid, Box, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { Header, MenuLayout, SelectTopics } from "../../components";
import styles from "./PracticePage.module.scss"
import { useNavigate } from "react-router-dom";
import { SiteMap } from "../../Routes";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";

const SelectSubTopicPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const practiceState = useSelector(state => state.practice)
	const isLoading = practiceState.currentAction === "GET_SUB_TOPICS_LIST_REQUEST"
	const { subTopicList, loading } = practiceState || {}

	const { selectSubTopics } = bindActionCreators(
		actions.ui,
		dispatch
	);

	const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);


	const UI = useSelector(state => state.UI)
	const { topics: UItopics } = UI.header || {}

	const [topics, setTopics] = useState(UI.header.subtopics || [])

	const handleTopicSelect = (item) => {
		let currentTopics = [...topics]
		if (item === "All Subtopic") {
			if (currentTopics.length === subTopicList.length) {
				currentTopics = []
			} else {
				currentTopics = subTopicList
			}
		}
		else if (topics.includes(item)) {
			let index = currentTopics.indexOf(item)
			currentTopics.splice(index, 1)
		} else {
			currentTopics.push(item)
		}
		setTopics(currentTopics)
	}

	const handleNext = () => {
		if (topics.length || subTopicList.length === 0) {
			selectSubTopics(topics)
			navigate(SiteMap.SelectDifficultyPage.path)
		}
		else ShowToast({ "message": "Please select alteast one subtopic", "severity": "error" })
	}

	React.useEffect(() => {
		if (UItopics.length === 0) {
			navigate(SiteMap.PracticePage.path)
			return
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<MenuLayout>
			<Header header1={"Select Subtopic(s)"} />
			<Box className={styles.topics_section}>
				<Box className={styles.page_content}>
					<p>
						<span>Topic(s): </span>
						<span>{UItopics.length === 1 ? UItopics[0].topic : `${UItopics.length} topics are selected`}</span>
					</p>
				</Box>
				<Grid container className="button_list_wrap">
					<SelectTopics isLoading={isLoading} list={subTopicList} currentSelected={topics} setSelector={handleTopicSelect} />
					{
						isLoading ?
							<Box className='button_list bottom_btn_box button_list_center'>
								{Array(2).fill().map((item, index) => (
									<Skeleton key={index} className="bottom_btn" variant="rounded" height={60} />
								))}
							</Box>
							:
							<Box className='button_list button_list_center'>
								<Box className={`bottom_btn_box ${styles.btn_bottom}`}>
									<Button disabled={loading} className="bottom_btn bottom_btn_back" onClick={() => navigate(-1)}>Back</Button>
									<Button disabled={loading} className="bottom_btn" onClick={() => handleNext()}>Next</Button>
								</Box>
							</Box>
					}
				</Grid>
			</Box>
		</MenuLayout>
	);
};

export default SelectSubTopicPage;
