import "./App.scss";
import "./scss/override.scss";
import "./scss/alignment.scss";
import "./scss/katex.scss";
import { useSelector } from "react-redux";
import React from "react";
import Routes from "./Routes";
import Toast from "./components/Toast/Toast";
import { ErrorBoundary, GoogleTranslate } from "./components";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import * as FullStory from '@fullstory/browser';

FullStory.init({
	orgId: process.env.REACT_APP_FS_ID,
	devMode: process.env.REACT_APP_CURR_ENV !== "prod"
});

function App() {
	const ToastState = useSelector((state) => state.toast);
	const authState = useSelector((state) => state.auth);
	const { user } = authState || {}
	const { mobile_number, first_name, last_name, email_id } = user || {}

	FullStory.identify(mobile_number, {
		displayName: `${first_name} ${last_name}`,
		email: email_id
	});

	document.oncontextmenu = function (e) {
		return false;
	}
	
	const config = {
		tex2jax: { delimiters: [['$', '$']] },
		"HTML-CSS": {
			linebreaks: { automatic: true },
			mtextFontInherit: true,
			availableFonts: ["STIX"],
			preferredFont: "STIX",
			webFont: "STIX-Web",
		},
		CommonHTML: {
			linebreaks: { automatic: true },
			mtextFontInherit: true
		},
		SVG: {
			linebreaks: { automatic: true },
			mtextFontInherit: true
		}
	}

	return (
		<ErrorBoundary>
			<MathJaxContext config={config} version={2}>
				<MathJax>
					<React.Fragment>
						<Toast
							open={ToastState.open}
							message={ToastState.message}
							severity={ToastState?.severity}
						/>
						<GoogleTranslate />
						<Routes />
					</React.Fragment>
				</MathJax>
			</MathJaxContext>
		</ErrorBoundary>
	);
}

export default App;
