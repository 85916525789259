import { Grid, Box, Typography, Skeleton } from "@mui/material"
import React from "react"

const SummaryBox = ({ title, value, styleClass, loading }) => {
    return (
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} >
            <Box className={styleClass} >
                {
                    loading?
                    <React.Fragment>
                        <Skeleton height={60} width={'80%'} />
                        <Skeleton className="ml1" height={35} width={'15%'} />
                    </React.Fragment>
                    :
                    <Typography variant="h6">
                        {title}
                        <span>{value}</span>
                    </Typography>
                }
            </Box>
        </Grid>
    )
}

export default SummaryBox