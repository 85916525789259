import {
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { Box } from '@mui/material';

function CustomPagination({ scrollToRef }) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    function handlePageChange(e, value) {
        if (page + 1 !== value) {
            if (scrollToRef) scrollToRef?.current?.scrollIntoView({ behavior: 'smooth' })
            apiRef.current.setPage(value - 1)
        }
    }

    return (
        <Box>
            <Pagination count={pageCount}
                page={page + 1}
                onChange={handlePageChange}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: () => (<p>Prev</p>),
                            next: () => (<p>Next</p>)
                        }}
                        {...item}
                    />
                )}
            />
        </Box>
    );
}

export default CustomPagination;