import { Box, Grid, LinearProgress, Skeleton } from '@mui/material';
import React from 'react';
import { renderToHtmlString } from '../assets/utils/utils';

const AnalysisBoxWithProgress = ({ title, data, styleClass, message, loading }) => {

    return (

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={styleClass}>
                {
                    loading ?
                        <React.Fragment>
                            <h4>
                                {title}:
                            </h4>
                            <ul>
                                {Array(3).fill().map(() => (
                                    <li>
                                        <Skeleton animation="wave" />
                                        <div aria-describedby={styleClass.includes("green") ? "box_right_progress_theme_green" : "box_right_progress_theme_red"} >
                                            <LinearProgress variant="indeterminate" />
                                        </div>
                                    </li>
                                ))
                                }
                            </ul>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <h4>
                                {title}:
                            </h4>
                            {
                                data ?
                                    <ul>
                                        {
                                            data.map(({ ability, percentage_score }, index) => (
                                                <li key={index}>
                                                    <div>
                                                        <p className='ability-item' dangerouslySetInnerHTML={{ __html: renderToHtmlString(ability) }} />
                                                        <span>{percentage_score}%</span>
                                                    </div>
                                                    <div aria-describedby={styleClass.includes("green") ? "box_right_progress_theme_green" : "box_right_progress_theme_red"} >
                                                        <LinearProgress variant="determinate" value={percentage_score} />
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    :
                                    <p>{message || "No data available here"}</p>
                            }

                        </React.Fragment>
                }
            </Box>
        </Grid>
    )
}

export default AnalysisBoxWithProgress