import React from 'react'
import { Box, Button, Modal, Typography } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import styles from "./Overlays.module.scss"
import { SiteMap } from '../../Routes';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';

const WelcomeModal = ({ open, handleClose = () => { } }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { welcomeModalClose } = bindActionCreators(
        actions.dashboard,
        dispatch
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="confirmation_modal"
        >
            <Box className={styles.pause_session_modal}>
                <Button className={styles.close_modal} onClick={handleClose}><HighlightOffOutlinedIcon /></Button>
                <Typography className={styles.modal_title}>
                    Start with your first practice session to get amazing insights about your learning.
                </Typography>
                <Box className='bottom_btn_box display-flex justify-content-center'>
                    <Button className='bottom_btn' onClick={() => { welcomeModalClose(); navigate(SiteMap.PracticePage.path) }}>Start Practice Session</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default WelcomeModal;