import React from 'react';
import { Box, Button, CircularProgress, Modal, Typography } from "@mui/material"
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import styles from "./Overlays.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import ConfirmationDialog from "../Overlays/ConfirmationDialog";

let intervalId = undefined
let muiProgressBar = undefined

const PauseSessionModal = ({ open = false, handleClose = () => { } }) => {
    const [progress, setProgress] = React.useState(100)
    const PauseSessionOptions = ["2 minutes", "5 minutes", "10 minutes", "15 minutes", "30 minutes", "60 minutes"]

    const dispatch = useDispatch();
    const practiceState = useSelector(state => state.practice)
    const { timer = "00:00", selectedTime = "2 minutes", pauseTimerDate } = practiceState || {}

    const { getApiPauseSession, getApiResumeSession, getApiEndSession,
        setPauseTime, setPauseTimer, setPauseTimerDate
    } = bindActionCreators(
        actions.practice,
        dispatch
    );

    const [confirmationDialog, setConfirmationDialog] = React.useState(false)
    React.useEffect(() => {
        if (timer === "0:00" && practiceState?.sessionPaused) {
            handleClose()
            clearInterval(intervalId)
            getApiResumeSession()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress, practiceState?.sessionPaused])

    React.useEffect(() => {
        if (timer !== "0:00" && practiceState?.sessionPaused) {
            onClickReset()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMinToSeconds = (selectedTime) => {
        let Min = selectedTime.split(" ")[0]
        return Number(Min * 60)
    }

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setPauseTimer(+(minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds))
            setProgress(((minutes * 60 + seconds) / getMinToSeconds(selectedTime)) * 100)
            muiProgressBar = ((minutes * 60 + seconds) / getMinToSeconds(selectedTime)) * 100
        }
    }

    const clearTimer = (e) => {
        if (!pauseTimerDate) {
            setPauseTimer(`${selectedTime.split(" ")[0]}:00`);
        }
        if (intervalId) clearInterval(intervalId);
        intervalId = setInterval(() => {
            startTimer(e);
        }, 1000)
    }

    const getDeadTime = () => {
        if (pauseTimerDate) {
            return pauseTimerDate
        } else {
            let deadline = new Date();
            deadline.setSeconds(deadline.getSeconds() + getMinToSeconds(selectedTime));
            setPauseTimerDate(deadline)
            return deadline;
        }
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const handleEndSession = () => {
        clearInterval(intervalId); handleClose();
        getApiEndSession()
    }

    const handlePauseSession = () => {
        onClickReset();
        getApiPauseSession({ "time": selectedTime.split(" ")[0] })
    }

    const handleResumeSession = () => {
        clearInterval(intervalId); handleClose();
        getApiResumeSession()
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="pause_session_modal"
            >
                <Box className={styles.pause_session_modal}>
                    {practiceState?.sessionPaused ? <>
                        <Box className={styles.pause_session_modal_inner}>
                            <Typography className={`${styles.modal_title} ${styles.modal_title_big}`}>Session Paused</Typography>
                            <Box className={styles.pause_session_progress_box}>
                                <Box className={styles.pause_session_progress}>
                                    <CircularProgress variant={isNaN(muiProgressBar) ? "indeterminate" : "determinate"} value={muiProgressBar} />
                                    <h6>{timer}</h6>
                                </Box>
                                <h4>Time remaining</h4>
                            </Box>
                            <Box className="bottom_btn_box justify-content-center">
                                <Button className="bottom_btn" onClick={handleResumeSession}>Resume Session</Button>
                                <Button className="bottom_btn bottom_btn_back" onClick={() => setConfirmationDialog(true)}>Finish Session</Button>
                            </Box>
                        </Box>
                    </> :
                        <>
                            <Button className={styles.close_modal} onClick={() => handleClose()}><HighlightOffOutlinedIcon /></Button>
                            <Box className={styles.pause_session_modal_inner}>
                                <Typography className={styles.modal_title}>For how long do you want to pause this session?</Typography>
                                <Box className='button_list'>
                                    {PauseSessionOptions.map((option) => (
                                        <Button
                                            key={option}
                                            onClick={() => { setPauseTime(option); }}
                                            className={`btn  ${selectedTime === option ? "active" : ""}`}>
                                            {option}
                                        </Button>
                                    ))}
                                </Box>

                                <Box className={`bottom_btn_box justify-content-center ${styles.button_box}`}>
                                    <Button className="bottom_btn" onClick={handlePauseSession}>Pause Session</Button>
                                </Box>
                            </Box>
                        </>
                    }
                </Box>
            </Modal>
            <ConfirmationDialog
                loading={practiceState.loading}
                open={confirmationDialog}
                handleClose={() => setConfirmationDialog(false)}
                handleOk={() => handleEndSession()}
                message={"Are you sure you want to finish this practice session?"}
                buttonOneText={"Cancel"}
                buttonTwoText={"Finish Session"}
            />
        </>
    )
}

export default PauseSessionModal