import { TOAST_CONTEXT as context } from '../context';

const ToastInintialState = {
    open: false,
    message: '',
    severity: '',
};

export const ToastReducer = (state = ToastInintialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case context.TOAST_SHOW:
            return {
                open: true,
                message: payload.message,
                severity: payload.severity,
            };

        case context.TOAST_HIDE:
            return { ...state, open: false };

        default:
            return state;
    }
};