import React from "react";
import { BaseLayout, LoginWithPassword } from "../../components";

const LoginWithPasswordPage = () => {
	return (
		<BaseLayout>
			<LoginWithPassword />
		</BaseLayout>
	);
};

export default LoginWithPasswordPage;
