import { Button, Grid } from "@mui/material";
import React from "react";
import { MenuLayout } from "../../components";
import { post } from "../../api/apiHelper"
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const RazorpayDemo = () => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth)
    const { parent_name, email_id, parent_mobile } = authState.user || {}

    const { ShowToast } = bindActionCreators(
        actions.toast,
        dispatch
    );

    const handleRazorPay = (razopayResponse) => {
        //on successfull payment
        post('/user/payment-validation/', razopayResponse)
            .then((res) => {
                let { message } = res.data || {}
                ShowToast({ "message": message })
            })
    }

    async function initiatePayment() {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            ShowToast({ "message": "We are unable to process your payment request, please try after sometime.", "severity": "error" })
            return
        }

        const apiRes = await post('/user/initiate-payment')

        if (apiRes.status === 200) {
            const { data } = apiRes.data
            const { amount, currency, description, id } = data

            const options = {
                key: process.env.REACT_APP_RAZORPAY_KEY,
                currency: currency,
                amount: amount.toString(),
                order_id: id,
                name: 'Studdicted',
                description: description,
                image: 'https://mentor-studdicted.s3.ap-south-1.amazonaws.com/social_media_icons/logo.png',
                handler: handleRazorPay, // callback
                prefill: {
                    name: parent_name ?? "",
                    email: email_id ?? "",
                    contact: parent_mobile.toString() ?? "",
                },
                theme: {
                    color: "#4200FF",
                },
            }
            const paymentObject = new window.Razorpay(options)
            paymentObject.open()
        }
    }

    return (
        <MenuLayout>
            <Grid container>
                <Grid item xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100vh" }}>
                    <Button sx={{ color: '#4200FF' }} onClick={initiatePayment}>Razorpay</Button>
                </Grid>
            </Grid>
        </MenuLayout>
    )
}

export default RazorpayDemo;