import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CommentIcon from '@mui/icons-material/Comment';
import { Suggestions, TopicWiseAnalysis, ConceptAnalysis, AbilityAnalysis } from "../../components";
import styles from './PerformanceDrillDown.module.scss'
import { TabPanel, a11yProps } from "../../components";
import { useSelector } from "react-redux";

const PerformanceDrilldown = ({ viewPracticeHistory = () => { } }) => {

    const DashboardState = useSelector(state => state.dashboard)
    const { abilityAnalysisLoading = false } = DashboardState || {}
    const [tab, setTab] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Box className={styles.Performance_DrilldownWrap}>
            <Box className={styles.performance_wrap}>
                <Box>
                    <Box className="performance_tab_box">
                        <Tabs value={tab} onChange={handleChangeTabs} aria-label="basic tabs example">
                            <Tab icon={<DocumentScannerIcon />} iconPosition="start" label="Topic-wise Analysis" {...a11yProps(0)} />
                            <Tab icon={<DocumentScannerIcon />} iconPosition="start" label="Abilities and Concepts" {...a11yProps(1)} />
                            <Tab icon={<CommentIcon />} iconPosition="start" label="Suggestions" {...a11yProps(2)} />
                        </Tabs>
                    </Box>

                    <TabPanel value={tab} index={0} className="tab_layout_outer">
                        <Box className={styles.tab_layout}>
                            <TopicWiseAnalysis viewSuggestion={() => setTab(2)} viewPracticeHistory={viewPracticeHistory} />
                        </Box>
                    </TabPanel>

                    <TabPanel value={tab} index={1} className="tab_layout_outer">
                        <Box className={styles.tab_layout}>
                            <Box className={`${styles.ability_analysis_wrap}`}>
                                <Box className={`${styles.ability_analysis_wrap_inner}`}>
                                    <Typography className={`${styles.ability_wise_analysis_title} ${styles.ability_wise_analysis_title_top}`} variant="h3">
                                        Ability-wise Analysis
                                    </Typography>
                                    <Grid container spacing={4} >
                                        <AbilityAnalysis
                                            data={DashboardState.abilityAnalysis || {}}
                                            loading={abilityAnalysisLoading || false}
                                        />
                                    </Grid>
                                    <Typography className={styles.ability_wise_analysis_title} variant="h3">
                                        Concept-wise Analysis
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <ConceptAnalysis />
                                    </Grid>
                                </Box>
                                <Box className="button_list bottom_btn_box footer_button_list sorting_btn_center">
                                    <Button className="bottom_btn" onClick={() => setTab(2)}>View Suggestions</Button>
                                    <Button className="bottom_btn_simple" onClick={() => viewPracticeHistory()}>View Practice History</Button>
                                </Box>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={tab} index={2} className="tab_layout_outer">
                        <Box className={styles.tab_layout}>
                            <Typography className={`${styles.ability_wise_analysis_title} ${styles.ability_wise_analysis_title_top}`} variant="h3">
                                Top Suggestions
                            </Typography>
                            <Suggestions />
                        </Box>
                    </TabPanel>
                </Box>
            </Box>
        </Box >

    )
}

export default PerformanceDrilldown