import React from "react";
import { BaseLayout, LoginWithOTP } from "../../components";

const LoginWithOTPPage = () => {
	return (
		<BaseLayout>
			<LoginWithOTP />
		</BaseLayout>
	);
};

export default LoginWithOTPPage;
