import { Grid, Avatar, Tooltip, IconButton, Box, Skeleton } from "@mui/material";
import React from "react";
import styles from "./Header.module.scss"
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import AlarmIcon from '@mui/icons-material/Alarm';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LinearProgress from '@mui/material/LinearProgress';
import { PauseSessionModal } from "..";
import ConfirmationDialog from "../Overlays/ConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux";
import { bindActionCreators } from "redux";
import { useTimer, formatTime } from "..";
import { useLocation } from "react-router-dom";
import { SiteMap } from "../../Routes";

const Header = (props) => {

    const {
        sessionButtons = false,
        welcomeMessage = "",
        header1 = "",
        header2 = "",
        subHeader = "",
        subtopics = [],
        difficulty = "",
        typeOfQuestions = "",
        timer = false,
        score = "",
        handleEndSession = () => { },
        loading,
    } = props

    const location = useLocation()
    const dispatch = useDispatch()
    const [pauseQuestion, setPauseQuestion] = React.useState(false)
    const [confirmationDialog, setConfirmationDialog] = React.useState(false)

    const { postApiBookmarkQuestion, deleteApiBookmarkQuestion } = bindActionCreators(
        actions.bookmark,
        dispatch
    );

    const { setSessionTime } = bindActionCreators(actions.timer, dispatch)

    const practiceState = useSelector(state => state.practice)
    const bookmarkState = useSelector(state => state.bookmark)
    const timerState = useSelector(state => state.timer)
    const { sessionPaused, sessionInProgress } = practiceState || {}
    const { question_id } = practiceState.question || {}
    const { bookmark_id } = practiceState.bookmarkStatus || {}
    const { sessionTime = 0 } = timerState || {}

    const { timer: stopwatch, handleStart, handlePause, handleResume, handleReset } = useTimer(sessionTime)

    const handleBookmarks = () => {
        bookmark_id ?
            deleteApiBookmarkQuestion(bookmark_id) :
            postApiBookmarkQuestion(question_id)
    }

    React.useEffect(() => {
        setSessionTime(stopwatch) // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stopwatch])

    React.useEffect(() => {
        if (sessionTime > -1 && !sessionPaused && question_id && sessionInProgress &&
            location.pathname.includes(SiteMap.QuestionPage.path))
            handleStart()

        return () => {
            handlePause()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question_id])

    React.useEffect(() => {
        if (practiceState.currentAction === "PAUSE_SESSION_SUCCESS") {
            handlePause()
        }
        if (practiceState.currentAction === "RESUME_SESSION_SUCCESS") {
            setPauseQuestion(false)
            handleResume()
        }
        if (practiceState.currentAction === "END_SESSION_SUCCESS") {
            setSessionTime(0)
            handleReset()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceState.currentAction])

    React.useEffect(() => {
        if (practiceState.sessionPaused) {
            setPauseQuestion(true)
            handlePause()
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [practiceState?.sessionPaused])

    return (
        <React.Fragment>
            <Grid container className={styles.header} >
                {welcomeMessage && <Grid className={styles.welcome_header}>
                    <h1>
                        {welcomeMessage}
                    </h1>
                </Grid>}
                <Grid container className={styles.header_text}>
                    <Grid item md={6} xs={6} className={styles.box_left}>
                        <p className={styles.sub_header_top_text}>{subHeader}</p>
                        {
                            loading ?
                                <Skeleton variant="rounded" height={35} />
                                :
                                <h1><span>{header1}</span></h1>
                        }

                        <h1> {header2}</h1>
                        <Box className={styles.header_sub_details}>
                            {subtopics.length > 0 &&
                                <p>
                                    <span>Subtopics:</span>
                                    <span>{subtopics.length === 1 ? subtopics[0].subtopic : subtopics.length}</span>
                                </p>
                            }
                            {
                                (difficulty || typeOfQuestions) &&
                                <p>
                                    {difficulty && <span>Difficulty: {difficulty}</span>}
                                    {typeOfQuestions && <span>Type of questions: {typeOfQuestions}</span>}
                                </p>
                            }
                        </Box>
                    </Grid>

                    <Grid item md={6} xs={6} className={styles.box_right}>
                        {
                            score &&
                            <>
                                <h2> {`Current topic score: ${score}%`}</h2>
                                <div aria-describedby="box_right_progress" className={styles.box_right_progress}>
                                    <LinearProgress variant="determinate" value={score} />
                                </div>
                            </>
                        }
                        {sessionButtons &&
                            <Box className={styles.session_buttons}>
                                <IconButton disabled={!question_id || practiceState.loading || bookmarkState.loading} className={styles.Bookmark_btn} onClick={handleBookmarks}>
                                    <Tooltip title={bookmark_id ? "Remove Bookmark" : "Bookmark Question"} placement="bottom">
                                        <Avatar className={bookmark_id ? styles.bookmarkedIcon : styles.bookmarkIcon}>
                                            {bookmark_id ? <BookmarkAddedIcon /> : <BookmarkIcon />}
                                        </Avatar>
                                    </Tooltip>
                                </IconButton>
                                <IconButton disabled={!question_id || practiceState.loading} className={styles.Bookmark_btn} onClick={() => setPauseQuestion(!pauseQuestion)}>
                                    <Tooltip title={pauseQuestion ? "Resume Session" : "Pause Session"} placement="bottom">
                                        <Avatar className={pauseQuestion ? styles.resumeIcon : styles.pauseIcon}>{pauseQuestion ? <PlayArrowIcon /> : <PauseIcon />}</Avatar>
                                    </Tooltip>
                                </IconButton>
                                <IconButton disabled={!question_id || practiceState.loading} className={styles.Bookmark_btn} onClick={() => setConfirmationDialog(true)}>
                                    <Tooltip title="Finish Session" placement="bottom">
                                        <Avatar className={styles.stopIcon}><StopIcon /></Avatar>
                                    </Tooltip>
                                </IconButton>
                                {
                                    timer &&
                                    <>
                                        <Box className={styles.Bookmark_btn}>
                                            <Tooltip title="Session Time" placement="bottom">
                                                <Box className={styles.clockIcon}>
                                                    <AlarmIcon />
                                                    <Box>
                                                        <h3>{formatTime(stopwatch).getHours}</h3>
                                                        : <h3>{formatTime(stopwatch).getMinutes}</h3>
                                                        : <h3>{formatTime(stopwatch).getSeconds}</h3>
                                                    </Box>
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                    </>
                                }
                            </Box>
                        }
                    </Grid>
                </Grid>
                <PauseSessionModal open={pauseQuestion} handleClose={() => setPauseQuestion(false)} />
                <ConfirmationDialog
                    loading={practiceState.loading}
                    open={confirmationDialog}
                    handleClose={() => setConfirmationDialog(false)}
                    handleOk={() => handleEndSession()}
                    message={"Are you sure you want to finish this practice session?"}
                    buttonOneText={"Resume Session"}
                    buttonTwoText={"Finish Session"}
                />
            </Grid>
        </React.Fragment >
    )
}

export default Header