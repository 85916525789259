import { Box, Button, Grid } from "@mui/material"
import { NoRecordsLogo } from "../assets"
import styles from "./NoRecords.module.scss"

const NoRecords = ({ heading = "No Records Found!", description, buttonText, buttonClick = () => { } }) => {

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Box className={styles.no_records}>
                    <Box >
                        <img src={NoRecordsLogo} alt="NoRecordsLogo" />
                    </Box>
                    <h1>{heading}</h1>
                    <p>{description}</p>
                    {buttonText &&
                        <Box className={`bottom_btn_box`}>
                            <Button className="bottom_btn" onClick={buttonClick}>Start a practice session</Button>
                        </Box>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default NoRecords