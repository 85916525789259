import { getAnalyseChart, getReportStatus, getSessionAbilityScores, getSessionChart, getSessionHistory, getSessionSummary, getSubTopicAbilityScores, getSubTopicSummary, getSubtopicWiseAnalysis, getTopicSubtopicWiseAbilityAnalysis, getTopicSummary, getTopicWiseAnalysis } from '../../api/apiCalls';
import { ANALYSE_CONTEXT as context } from '../context';
import { ShowToast } from './ToastActions';

// RESET Analyse Reducer 
export const restAnalyseReducer = () => ({
    type: context.RESET_ANALYSE_REDUCER
})

// GET Session Summary Actions
const getSessionSummaryRequest = () => ({
    type: context.GET_SESSION_SUMMARY_REQUEST,
})

const getSessionSummaryError = error => ({
    type: context.GET_SESSION_SUMMARY_ERROR,
    getSessionSummaryError: error
})

const getSessionSummarySuccess = (data) => ({
    type: context.GET_SESSION_SUMMARY_SUCCESS,
    session_summary: data
})

export const getApiSessionSummary = (session_id) => {
    return (dispatch) => {
        dispatch(getSessionSummaryRequest())
        getSessionSummary(session_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getSessionSummarySuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSessionSummaryError(res))
            }
        })
    }
}

// GET Topic Summary Actions
const getTopicSummaryRequest = () => ({
    type: context.GET_TOPIC_SUMMARY_REQUEST,
})

const getTopicSummaryError = error => ({
    type: context.GET_TOPIC_SUMMARY_ERROR,
    getTopicSummaryError: error
})

const getTopicSummarySuccess = (data) => ({
    type: context.GET_TOPIC_SUMMARY_SUCCESS,
    topic_summary: data
})

export const getApiTopicSummary = (session_id) => {
    return (dispatch) => {
        dispatch(getTopicSummaryRequest())
        getTopicSummary(session_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getTopicSummarySuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getTopicSummaryError(res))
            }
        })
    }
}

// GET Subtopic Summary Actions
const getSubTopicSummaryRequest = () => ({
    type: context.GET_SUBTOPIC_SUMMARY_REQUEST,
})

const getSubTopicSummaryError = error => ({
    type: context.GET_SUBTOPIC_SUMMARY_ERROR,
    getSubTopicSummaryError: error
})

const getSubTopicSummarySuccess = (data) => ({
    type: context.GET_SUBTOPIC_SUMMARY_SUCCESS,
    subtopic_summary: data
})

export const getApiSubTopicSummary = (session_id, topic_id) => {
    return (dispatch) => {
        dispatch(getSubTopicSummaryRequest())
        getSubTopicSummary(session_id, topic_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getSubTopicSummarySuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSubTopicSummaryError(res))
            }
        })
    }
}

// GET Session Ability Scores Action
const getSessionSubtopicAbilityScoresRequest = () => ({
    type: context.GET_SESSION_SUBTOPIC_ABILITY_SCORES_REQUEST,
})

const getSessionSubtopicAbilityScoresError = error => ({
    type: context.GET_SESSION_SUBTOPIC_ABILITY_SCORES_ERROR,
    getSessionSubtopicAbilityScoresError: error
})

const getSessionSubtopicAbilityScoresSuccess = (data) => ({
    type: context.GET_SESSION_SUBTOPIC_ABILITY_SCORES_SUCCESS,
    sessionSubtopicAbilityScores: data
})

export const getApiSessionSubtopicAbilityScores = (session_id, topic_id, subtopic_id) => {
    return (dispatch) => {
        dispatch(getSessionSubtopicAbilityScoresRequest())
        getSubTopicAbilityScores(session_id, topic_id, subtopic_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getSessionSubtopicAbilityScoresSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSessionSubtopicAbilityScoresError(res))
            }
        })
    }
}

// GET Session Chart Actions
const getSessionChartRequest = () => ({
    type: context.GET_SESSION_CHART_REQUEST,
})

const getSessionChartError = error => ({
    type: context.GET_SESSION_CHART_ERROR,
    getSessionChartError: error
})

const getSessionChartSuccess = (data) => ({
    type: context.GET_SESSION_CHART_SUCCESS,
    chart: data
})

export const getApiSessionChart = (session_id) => {
    return (dispatch) => {
        dispatch(getSessionChartRequest())
        getSessionChart(session_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getSessionChartSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSessionChartError(res))
            }
        })
    }
}

// GET Session History Actions
const getSessionHistoryRequest = () => ({
    type: context.GET_SESSION_HISTORY_REQUEST,
})

const getSessionHistoryError = error => ({
    type: context.GET_SESSION_HISTORY_ERROR,
    getSessionHistoryError: error
})

const getSessionHistorySuccess = (data) => ({
    type: context.GET_SESSION_HISTORY_SUCCESS,
    session_history: data
})

export const getApiSessionHistory = () => {
    return (dispatch) => {
        dispatch(getSessionHistoryRequest())
        getSessionHistory().then((res) => {
            if (res?.status === 200) {
                dispatch(getSessionHistorySuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSessionHistoryError(res))
            }
        })
    }
}

// GET Analyse Chart Actions
const getAnalyseChartRequest = () => ({
    type: context.GET_ANALYSE_CHART_REQUEST,
})

const getAnalyseChartError = error => ({
    type: context.GET_ANALYSE_CHART_ERROR,
    getAnalyseChartError: error
})

const getAnalyseChartSuccess = (data) => ({
    type: context.GET_ANALYSE_CHART_SUCCESS,
    analyseChart: data
})

export const getApiAnalyseChart = () => {
    return (dispatch) => {
        dispatch(getAnalyseChartRequest())
        getAnalyseChart().then((res) => {
            if (res?.status === 200) {
                dispatch(getAnalyseChartSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getAnalyseChartError(res))
            }
        })
    }
}



// GET Report Status Actions
const getReportStatusRequest = () => ({
    type: context.GET_REPORT_STATUS_REQUEST,
})

const getReportStatusError = error => ({
    type: context.GET_REPORT_STATUS_ERROR,
    getReportStatusError: error
})

const getReportStatusSuccess = (data) => ({
    type: context.GET_REPORT_STATUS_SUCCESS,
    status: data
})

export const getApiReportStatus = (session_id) => {
    return (dispatch) => {
        dispatch(getReportStatusRequest())
        getReportStatus(session_id).then((res) => {
            if (res?.status === 200) {
                if (res?.data?.data?.state === "Complete")
                    dispatch(getReportStatusSuccess(true))
                else dispatch(getReportStatusSuccess(false))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getReportStatusError(res))
            }
        })
    }
}

// GET Session Ability Scores Action
const getSessionAbilityScoresRequest = () => ({
    type: context.GET_SESSION_ABILITY_SCORES_REQUEST,
})

const getSessionAbilityScoresError = error => ({
    type: context.GET_SESSION_ABILITY_SCORES_ERROR,
    getSessionAbilityScoresError: error
})

const getSessionAbilityScoresSuccess = (data) => ({
    type: context.GET_SESSION_ABILITY_SCORES_SUCCESS,
    sessionAbilityScores: data
})

export const getApiSessionAbilityScores = (session_id) => {
    return (dispatch) => {
        dispatch(getSessionAbilityScoresRequest())
        getSessionAbilityScores(session_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getSessionAbilityScoresSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSessionAbilityScoresError(res))
            }
        })
    }
}
// GET Topic wise Analysis Action
const getTopicWiseAnalysisRequest = () => ({
    type: context.GET_TOPIC_WISE_ANALYSIS_REQUEST,
})

const getTopicWiseAnalysisError = error => ({
    type: context.GET_TOPIC_WISE_ANALYSIS_ERROR,
    getTopicWiseAnalysisError: error
})

const getTopicWiseAnalysisSuccess = (data) => ({
    type: context.GET_TOPIC_WISE_ANALYSIS_SUCCESS,
    topicWiseAnalysis: data
})

export const getApiTopicWiseAnalysis = () => {
    return (dispatch) => {
        dispatch(getTopicWiseAnalysisRequest())
        getTopicWiseAnalysis().then((res) => {
            if (res?.status === 200) {
                dispatch(getTopicWiseAnalysisSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getTopicWiseAnalysisError(res))
            }
        })
    }
}

// GET Subtopic wise Analysis Action
const getSubtopicWiseAnalysisRequest = () => ({
    type: context.GET_SUBTOPIC_WISE_ANALYSIS_REQUEST,
})

const getSubtopicWiseAnalysisError = error => ({
    type: context.GET_SUBTOPIC_WISE_ANALYSIS_ERROR,
    getSubtopicWiseAnalysisError: error
})

const getSubtopicWiseAnalysisSuccess = (data) => ({
    type: context.GET_SUBTOPIC_WISE_ANALYSIS_SUCCESS,
    subtopicWiseAnalysis: data
})

export const getApiSubtopicWiseAnalysis = (topic_id) => {
    return (dispatch) => {
        dispatch(getSubtopicWiseAnalysisRequest())
        getSubtopicWiseAnalysis(topic_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getSubtopicWiseAnalysisSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getSubtopicWiseAnalysisError(res))
            }
        })
    }
}


// GET Topic Subtopic wise Ability Analysis Action
const getTopicSubtopicWiseAbilityAnalysisRequest = () => ({
    type: context.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_REQUEST,
})

const getTopicSubtopicWiseAbilityAnalysisError = error => ({
    type: context.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_ERROR,
    getTopicSubtopicWiseAbilityAnalysisError: error
})

const getTopicSubtopicWiseAbilityAnalysisSuccess = (data) => ({
    type: context.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS_SUCCESS,
    topicSubtopicWiseAbilityAnalysis: data
})

export const getApiTopicSubtopicWiseAbilityAnalysis = (topic_id, subtopic_id) => {
    return (dispatch) => {
        dispatch(getTopicSubtopicWiseAbilityAnalysisRequest())
        getTopicSubtopicWiseAbilityAnalysis(topic_id, subtopic_id).then((res) => {
            if (res?.status === 200) {
                dispatch(getTopicSubtopicWiseAbilityAnalysisSuccess(res?.data?.data))
            }
            else {
                dispatch(ShowToast({ "message": res?.data?.message ?? "Something went wrong !", "severity": "error" }))
                dispatch(getTopicSubtopicWiseAbilityAnalysisError(res))
            }
        })
    }
}

//

const setReportSuccessState = () => ({
    type: context.SET_REPORT_STATUS_SUCCESS,
})

export const setReportStatusSuccess = () => {
    return (dispatch) => {
        dispatch(setReportSuccessState());
    }
}