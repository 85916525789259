import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { Header, MenuLayout, OptionSelector } from "../../components";
import styles from "./PracticePage.module.scss"
import { SiteMap } from "../../Routes";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

const SelectDifficultyPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const UI = useSelector(state => state.UI)
	const { topics, subtopics } = UI.header || {}
	const practiceState = useSelector(state => state.practice)
	const isLoading = practiceState.loading
	const { difficultyLevels, questionFormats, topicList, subTopicList, loadingDifficultyLevels, loadingQuestionFormat } = practiceState || {}
	const disableHandleNext = (loadingDifficultyLevels || loadingQuestionFormat)
	let isAllSubtopic = subTopicList.length === subtopics.length
	let isAllTopics = topicList.length === topics.length

	const { selectDifficulty, selectQuestionFormat } = bindActionCreators(
		actions.ui,
		dispatch
	);

	const { getApiInstructions } = bindActionCreators(
		actions.practice,
		dispatch
	);

	const { getApiDifficultyLevels, getApiQuestionFormatsLevels } = bindActionCreators(
		actions.practice,
		dispatch
	);

	const [difficulty, setDifficulty] = useState(UI.header.difficulty)
	const [questionFormat, setQuestionFormat] = useState(UI.header.typeOfQuestions)

	const handleNext = () => {
		selectQuestionFormat(questionFormat)
		selectDifficulty(difficulty)
		getApiInstructions()
		navigate(SiteMap.InstructionPage.path)
	}

	React.useEffect(() => {
		getApiDifficultyLevels()
		getApiQuestionFormatsLevels()

		if (topics.length === 0) {
			navigate(SiteMap.PracticePage.path)
			return
		}
		if (topics.length === 1 && subtopics.length === 0) {
			navigate(SiteMap.SelectSubTopicPage.path)
			return
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<MenuLayout>
			<Header header1={"Select difficulty and question format"} />
			<Grid container>
				<Box className={styles.page_box}>
					<Box className={styles.page_content}>
						{
							topics.length > 0 &&
							<p>
								<span>Topic(s): </span>
								<span>{isAllTopics ? "All Topics" : topics.length === 1 ? topics[0].topic : `${topics.length} topics are selected`}</span>
							</p>
						}
						{
							subtopics.length > 0 &&
							<p>
								<span>Subtopic(s): </span>
								<span>{isAllSubtopic ? "All subtopics" : subtopics.length === 1 ? subtopics[0].subtopic : `${subtopics.length} subtopics are selected`}</span>
							</p>
						}
					</Box>
					<Box>
						<Box className={styles.question_box}>
							<h3 className={styles.question}>How difficult would you like your questions?</h3>
							<OptionSelector isLoading={isLoading} list={difficultyLevels} currentSelected={difficulty} setSelector={setDifficulty} />
						</Box>
						<Box className={styles.question_box}>
							<h3 className={styles.question}>Do you want questions in specific format</h3>
							<OptionSelector isLoading={isLoading} list={questionFormats} currentSelected={questionFormat} setSelector={setQuestionFormat} />
						</Box>
					</Box>
				</Box>
				<Box className='button_list button_list_center'>
					<Box className={`bottom_btn_box ${styles.btn_bottom}`}>
						<Button disabled={Boolean(isLoading)} className="bottom_btn bottom_btn_back" onClick={() => navigate(-1)}>Back</Button>
						<Button disabled={disableHandleNext} className="bottom_btn" onClick={() => handleNext()}>Done </Button>
					</Box>
				</Box>
			</Grid>
		</MenuLayout>
	);
};

export default SelectDifficultyPage;
