import React from 'react';
import { Box, Modal, Skeleton, Typography } from "@mui/material";
import styles from "./Overlays.module.scss";

const DialogBox = ({ open = false, handleClose = () => { }, message }) => {

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="confirmation_modal"
            >
                <Box className={styles.pause_session_modal}>
                    <Box className={styles.pause_session_modal_inner}>
                        <Typography className={styles.modal_title}>{message}</Typography>
                        <Box className={`bottom_btn_box justify-content-center ${styles.button_box}`}>
                            <Skeleton variant='rounded' height={52} className="bottom_btn">Please Wait...</Skeleton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default DialogBox;