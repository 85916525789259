import React from "react";
import { BaseLayout, SignUpForm } from "../../components";

const SignUpPage = () => {
	return (
		<BaseLayout>
			<SignUpForm />
		</BaseLayout>
	);
};

export default SignUpPage;
