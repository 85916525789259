import { LANDING_SITE_CONTEXT as context } from '../context';

const LandingInintialState = {
    loading: false,
    success: true,
    currentAction: "INITIAL_STATE"
};

export const LandingSiteReducer = (state = LandingInintialState, action) => {
    switch (action.type) {
        case context.POST_SEND_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                currentAction: action.type
            };
        case context.POST_SEND_MESSAGE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                currentAction: action.type
            };
        case context.POST_SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                currentAction: action.type
            };
        default:
            return state;
    }
};