import { Box, Container, Grid } from "@mui/material";
import React from "react";
import LogoBlock from "../LogoBlock/LogoBlock";
import style from "./BaseLayout.module.scss"

const BaseLayout = props => {
	return (
		<React.Fragment>
			<Box className="base_layout_container_outer">
				<Container className={style.base_container}>
					<Grid direction="row" container spacing={1}>
						<Grid item lg={4} md={4} sm={12} className="full-width">
							<LogoBlock />
						</Grid>
						<Grid item lg={8} md={8} sm={12} className="full-width">
							<Box className={style.login_right_wrap}>
								<Box className={style.login_right_main}>
									{props.children}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</React.Fragment>
	);
};

export default BaseLayout;
