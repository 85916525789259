import React from "react";
import { FormikForm, FormikTextField, FormikOTPField } from "..";
import {
	Box,
	Button,
	Collapse,
	FormHelperText,
	Skeleton,
} from "@mui/material";
import { SiteMap } from "../../Routes";
import { Link, useNavigate } from "react-router-dom"
import { EmailOrPhoneValidator } from "../assets"
import { actions } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import SendIcon from '@mui/icons-material/Send';
import { Form } from "formik";

const LoginWithOTP = () => {
	const ref = React.useRef(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const authState = useSelector(state => state.auth)
	const { isOtpSent, signInError } = authState
	const [otpFieldState, setOtpFieldState] = React.useState(false);
	const [otpTimer, setOtpTimer] = React.useState(-1);
	const [otp, setOtp] = React.useState("")
	const [resetOtpField, setResetOtpField] = React.useState(false)
	const { currentAction } = authState || {}

	const { resetOTPStates, resetAuthReducer,
		getApiSignInOtpGenerate,
		getApiSignInWithOTP } = bindActionCreators(
			actions.auth,
			dispatch
		);

	const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

	const sendOtp = () => {
		ref.current.setFieldTouched("user_contact").then(() => {
			if (!ref.current.errors.hasOwnProperty("user_contact")) {
				resetOTPFields()
				if (otpTimer === -1 || !isOtpSent || !otpFieldState) {
					setOtpTimer(30)
					getApiSignInOtpGenerate({ "user_contact": ref.current.values.user_contact })
				}
				setOtpFieldState(true);
			}
		})
	};

	React.useEffect(() => {
		resetOTPStates()
		resetAuthReducer()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		let timer
		if (otpFieldState === true && isOtpSent && otpTimer > 0) {
			timer = setTimeout(() => {
				setOtpTimer(otpTimer - 1)
			}, 1000)
		} else if (otpTimer === 0 && isOtpSent) {
			setOtpTimer(-1)
			setOtpFieldState(false)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [otpFieldState, otpTimer, isOtpSent])

	React.useEffect(() => {
		if (authState.currentAction === "SIGN_IN_SUCCESS") navigate(SiteMap.DashboardPage.path)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState.currentAction])

	const hanldeOTPChange = (otpStr) => {
		setOtp(otpStr)
	}

	const handleSubmit = data => {
		otp.length === Number(process.env.REACT_APP_OTP_LENGTH) ? getApiSignInWithOTP({ "user_contact": data.user_contact, "otp": otp }) :
			ShowToast({ "message": "You must enter OTP", "severity": "error" })
	}

	const resetOTPFields = () => {
		setResetOtpField(!resetOtpField)
		for (let i = 0; i < Number(process.env.REACT_APP_OTP_LENGTH); i++) {
			ref.current.setFieldValue(`otpsubfield${i}`, "")
		}
	}

	return (
		<FormikForm innerRef={ref} validator={EmailOrPhoneValidator} onSubmit={handleSubmit}>
			{({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
				<Form
					onSubmit={handleSubmit}
					onChange={e => {
						handleChange(e);
					}}
					onBlur={handleBlur}
					onReset={resetForm}
				>
					<Box className="login_otp">
						<div className="reg-title">
							<h1>Login to Existing Account</h1>
						</div>

						<div className="custom-form-field">
							<label className="form-label">Email or Mobile Number</label>
							<FormikTextField
								className="white-bg-field"
								name="user_contact"
								label=""
								onChange={() => resetOTPStates()}
								InputProps={{
									endAdornment: (
										<Button
											size="small"
											onClick={sendOtp}
											className="otp-button-primary"
											variant="contained"
											disabled={otpTimer !== 0 && otpFieldState && isOtpSent}
											endIcon={<SendIcon />}
										>
											{!isOtpSent ? "Send" : "Resend"}
										</Button>
									),
								}}
							/>
							{otpFieldState === true && isOtpSent && otpTimer !== 0 &&
								<Box className="display-flex justify-content-flex-end">
									<Box className="ghost-button-primary p-6">Resend in {otpTimer}</Box>
								</Box>
							}
						</div>
						<Collapse in={isOtpSent}>
							<div className="custom-form-field mt-12">
								<label className="form-label">One-time Password (OTP)</label>
								<FormikOTPField
									resetOTPFields={resetOtpField}
									className="white-bg-field otp-field"
									name="otp"
									label=""
									otpLength={process.env.REACT_APP_OTP_LENGTH ?? 6}
									onChange={hanldeOTPChange}
								/>
								{signInError && <FormHelperText className="form-errors">{signInError}</FormHelperText>}
							</div>
						</Collapse>
						<div className="form-action-button">
							{currentAction === 'SIGN_IN_REQUEST' ?
								<Skeleton variant="circular" className="form-action-button-blue" height={55}>Logging In</Skeleton>
								: <Button
									id="login"
									size="large"
									className="form-action-button-blue"
									variant="contained"
									type="submit"
									disabled={!(dirty && isValid)}
								>
									Login
								</Button>
							}
							<div className="login-route-option">
								<ul>
									<li>
										<Link to={SiteMap.PasswordLoginPage.path}>
											Login with Password
										</Link>
									</li>
									<li>
										<Link to={SiteMap.SignupPage.path}>
											Sign Up
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</Box>
				</Form>)}
		</FormikForm>
	);
};

export default LoginWithOTP;
