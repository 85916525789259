import React from 'react'
import { Box, Container, AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import styles from "../../pages/landingSite/website.module.scss"
import { SiteMap } from "../../Routes";
import { LogoSmall } from "../assets";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const LandingAppBar = () => {
    const navigate = useNavigate()
    const pages = [
        { name: 'Features', url: '/#features' },
        { name: 'Testimonials', url: '/#testimonials' },
        { name: 'Team', url: '/#team' },
        { name: 'Pricing', url: '/#pricing' },
        { name: 'Contact Us', url: '/#contactus' },
    ];

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar className={styles.appbar} position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters className={styles.toolbar}>
                    <Typography
                        className={styles.c_logo}
                        variant="h6"
                        noWrap
                        component="a"
                        href={SiteMap.LandingWebsite.public_url}
                    >
                        <img src={LogoSmall} alt="SiteLogo" />
                    </Typography>

                    <Box className={styles.menuBar_icon}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            aria-describedby="static_page_menu"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name} onClick={() => navigate(page.url)}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box className={styles.menulink}>
                        {pages.map((page) => (
                            <Button
                                onClick={() => navigate(page.url)}
                                className={styles.menulink_button}
                                key={page.name}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    <Box className={styles.tooltop}>
                        <Tooltip title="Start Practising">
                            <Button className={styles.start_button} onClick={() => window.location.href = SiteMap.HomePage.public_url}>
                                Start Practising
                            </Button>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default LandingAppBar