import { Grid, IconButton, Box, Avatar } from "@mui/material";
import React from "react";
import style from "./Sidebar.module.scss"
import { LogoSmall } from "../assets";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useLocation, useNavigate } from "react-router-dom"
import { SiteMap } from "../../Routes";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux";
import ConfirmationDialog from "../Overlays/ConfirmationDialog";

const Sidebar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const authState = useSelector(state => state.auth)

    const { first_name, last_name, student_class, profile_pic } = authState.user || {}

    const [confirmationDialog, setConfirmationDialog] = React.useState(false)

    const { getApiLogOut } = bindActionCreators(
        actions.auth,
        dispatch
    );

    const handleLogout = () => {
        getApiLogOut()
    }

    return (
        <React.Fragment>
            <Grid container className={style.Sidebar}>
                <Box className={style.SidebarInner}>
                    <Box>
                        <Box className={style.logoBox}>
                            <img src={LogoSmall} alt="Logo" />
                        </Box>
                        <Box className={style.userBox}>
                            <Avatar className={style.userAvatar} src={profile_pic} />
                            <h3>{first_name} {last_name}</h3>
                            {student_class && <p>Grade : {student_class}</p>}
                        </Box>
                        <IconButton
                            onClick={() => navigate(SiteMap.DashboardPage.path)}
                            className={location.pathname.split("/")[1] === (SiteMap.DashboardPage.path.split("/")[1]) ? style.active : ""}>
                            <DashboardIcon />
                            <h2>Dashboard</h2>
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(SiteMap.PracticePage.path)}
                            className={location.pathname.split("/")[1] === (SiteMap.SelectSubTopicPage.path.split("/")[1]) ? style.active : ""}>
                            <AssignmentIcon />
                            <h2>Practice</h2>
                        </IconButton>
                        <IconButton
                            onClick={() => navigate(SiteMap.BookmarkPage.path)}
                            className={location.pathname.split("/")[1] === (SiteMap.BookmarkPage.path.split("/")[1]) ? style.active : ""}>
                            <BookmarkBorderOutlinedIcon />
                            <h2>Bookmarks</h2>
                        </IconButton>
                        <IconButton onClick={() => navigate(SiteMap.PracticeHistoryPage.path)}
                            className={location.pathname.split("/")[1] === (SiteMap.PracticeHistoryPage.path.split("/")[1]) ? style.active : ""}>
                            <AssessmentIcon />
                            <h2>Analyse</h2>
                        </IconButton>
                        <IconButton onClick={() => navigate(SiteMap.ProfilePage.path)}
                            className={location.pathname.split("/")[1] === (SiteMap.ProfilePage.path.split("/")[1]) ? style.active : ""}>
                            <AccountCircleRoundedIcon />
                            <h2>Profile</h2>
                        </IconButton>
                    </Box>
                    <Box className={style.SidebarBottom}>
                        <IconButton onClick={() => setConfirmationDialog(true)}>
                            <LogoutOutlinedIcon />
                            <h2>Sign Out</h2>
                        </IconButton>
                    </Box>
                </Box>
                <ConfirmationDialog
                    open={confirmationDialog}
                    handleClose={() => setConfirmationDialog(false)}
                    handleOk={() => handleLogout()}
                    message={"Are you sure you want to Sign Out?"}
                    buttonOneText={"Cancel"}
                    buttonTwoText={"Sign Out"}
                />
            </Grid>
        </React.Fragment>
    );
};

export default Sidebar;
