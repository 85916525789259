import React from 'react'
import { Box, Button, Grid, Skeleton, TextField } from "@mui/material";
import { CustomPagination, CustomSelectDropDown, MenuLayout, NoRecords } from "../../components";
import styles from './BookmarksPage.module.scss'
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { BookmarkModal } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
//import { stripHtml } from '../../components/assets';
//import Latex from "react-latex";
import { renderToHtmlString } from '../../components/assets/utils/utils';


const BookmarksPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const bookmarkState = useSelector(state => state.bookmark)
    const { BookmarksList = [] } = bookmarkState || {}
    const isLoading = bookmarkState.currentAction === 'GET_BOOKMARKS_LIST_REQUEST'

    const { getApiBookmarskList, getApiBookmarskedQuestion } = bindActionCreators(
        actions.bookmark,
        dispatch
    );

    React.useEffect(() => {
        getApiBookmarskList()  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setFilteredBookmarkList(BookmarksList)
        makeFilters()  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BookmarksList])

    const BookmarkDataTableRef = React.useRef(null)

    const [filteredBookmarkList, setFilteredBookmarkList] = React.useState([])

    const [uniqueTopics, setUniqueTopics] = React.useState([])
    const [uniqueSubTopics, setUniqueSubTopics] = React.useState([])
    const [uniqueFormats, setUniqueFormats] = React.useState([])
    const [uniqueDifficulties, setUniqueDifficulties] = React.useState([])

    const [filterTopics, setFilterTopics] = React.useState([])
    const [filterSubTopics, setFilterSubTopics] = React.useState([])
    const [filterFormats, setFilterFormats] = React.useState([])
    const [filterDifficulties, setFilterDifficulties] = React.useState([])

    const [openBookmarkModal, setOpenBookmarkModal] = React.useState(false)

    const [searchString, setSearchString] = React.useState("")

    const columns = [
        {
            field: 'bookmark_id',
            headerName: 'S.No.',
            flex: 0.2,
            align: 'left',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => params.api.getRowIndex(params.row.bookmark_id) + 1,
        },
        {
            field: 'question_text',
            headerName: 'Question Text',
            flex: 1,
            align: 'left',
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Box>
                        <p dangerouslySetInnerHTML={{ __html: renderToHtmlString(params.value) }} />
                        {/* <Latex>{stripHtml(params.value)}</Latex> */}
                    </Box>
                )
            },
        },
        {
            field: 'topic',
            headerName: 'Topic',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'subtopic',
            headerName: 'Subtopic',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'question_type',
            headerName: 'Format',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true
        },
        {
            field: 'difficulty',
            headerName: 'Difficulty',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true,
        }
    ];

    const handleRowClick = (row) => {
        getApiBookmarskedQuestion(row.id)
        setOpenBookmarkModal(true)
    }

    const handleFilter = () => {
        let search = searchString.toLowerCase()

        let filteredBookmarks = BookmarksList.filter((item) => item.question_text.toLowerCase().includes(search))
        if (filterTopics.length > 0) {
            filteredBookmarks = filteredBookmarks.filter((item) => filterTopics.includes(item.topic))
        }

        if (filterSubTopics.length > 0) {
            filteredBookmarks = filteredBookmarks.filter((item) => filterSubTopics.includes(item.subtopic))
        }

        if (filterFormats.length > 0) {
            filteredBookmarks = filteredBookmarks.filter((item) => filterFormats.includes(item.question_type))
        }

        if (filterDifficulties.length > 0) {
            filteredBookmarks = filteredBookmarks.filter((item) => filterDifficulties.includes(item.difficulty))
        }

        setFilteredBookmarkList(filteredBookmarks)
    }

    React.useEffect(() => {
        handleFilter()  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString, filterTopics, filterSubTopics, filterFormats, filterDifficulties])

    React.useEffect(() => {
        let { topic } = location.state || {}
        if (topic) setFilterTopics([topic])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uniqueTopics])

    const makeFilters = () => {
        let uniqueTopics = []
        let uniqueSubtopic = []
        let uniqueFormat = []
        let uniqueDifficulty = []

        BookmarksList.forEach((bookmark) => {
            if (!uniqueTopics.includes(bookmark?.topic)) {
                uniqueTopics.push(bookmark.topic)
            }
            if (!uniqueSubtopic.includes(bookmark?.subtopic)) {
                uniqueSubtopic.push(bookmark.subtopic)
            }
            if (!uniqueFormat.includes(bookmark?.question_type)) {
                uniqueFormat.push(bookmark.question_type)
            }
            if (!uniqueDifficulty.includes(bookmark?.difficulty)) {
                uniqueDifficulty.push(bookmark.difficulty)
            }
        })

        setUniqueTopics(uniqueTopics)
        setUniqueSubTopics(uniqueSubtopic)
        setUniqueFormats(uniqueFormat)
        setUniqueDifficulties(uniqueDifficulty)
    }

    return (
        <MenuLayout>
            <Box className={styles.bookmarks_wrap}>
                <Box className={styles.bookmarks_header}>
                    <Grid container spacing={2}>

                        {BookmarksList.length !== 0 ?
                            <>
                                <Grid item xs={12}>
                                    <Box className={styles.bookmarks_title}>
                                        <h1>Bookmarked Questions</h1>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} xs={12} >
                                    <Box className={styles.bookmarked_field}>
                                        <label htmlFor="">Search question by keyword</label>
                                        <TextField onChange={(e) => setSearchString(e.target.value)}
                                            className={styles.TextField}
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <Button><SearchIcon /> </Button>
                                                ),
                                            }} />
                                    </Box>
                                </Grid>
                                <Grid item lg={2} xs={6}>
                                    <CustomSelectDropDown label="Filter by:" placeholder="Topics" options={uniqueTopics} selected={filterTopics} setSelected={setFilterTopics} />
                                </Grid>
                                <Grid item lg={2} xs={6}>
                                    <CustomSelectDropDown placeholder="Subtopics" options={uniqueSubTopics} selected={filterSubTopics} setSelected={setFilterSubTopics} />
                                </Grid>
                                <Grid item lg={2} xs={6}>
                                    <CustomSelectDropDown placeholder="Format" options={uniqueFormats} selected={filterFormats} setSelected={setFilterFormats} />
                                </Grid>
                                <Grid item lg={2} xs={6}>
                                    <CustomSelectDropDown placeholder="Difficulty" options={uniqueDifficulties} selected={filterDifficulties} setSelected={setFilterDifficulties} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box className={styles.topicsPracticed}>
                                        <Box className={styles.AnalyseDataTable} ref={BookmarkDataTableRef}>
                                            {isLoading ?
                                                Array(5).fill().map((item, index) => {
                                                    return (
                                                        <Skeleton key={index}
                                                            className="select_topic_data_grid bookmark_data_grid" variant="rounded" height={80} />
                                                    )
                                                })
                                                :
                                                <DataGrid
                                                    pageSize={10}
                                                    getRowId={(row) => row.bookmark_id}
                                                    onRowClick={handleRowClick}
                                                    className="analytics_data_grid bookmark_data_grid"
                                                    components={{
                                                        Pagination: () => (<CustomPagination scrollToRef={BookmarkDataTableRef} />),
                                                        ColumnUnsortedIcon: () => (<ImportExportOutlinedIcon />),
                                                        ColumnSortedAscendingIcon: () => (<ArrowUpwardOutlinedIcon />),
                                                        ColumnSortedDescendingIcon: () => (<ArrowDownwardOutlinedIcon />),
                                                        NoRowsOverlay: () => (<Box className='NoRowOverlay'></Box>),
                                                    }}
                                                    classes={{ row: "analytics_data_row practice_data_row bookmark_data_row" }}
                                                    autoHeight
                                                    rows={filteredBookmarkList}
                                                    columns={columns}
                                                    disableSelectionOnClick
                                                />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <NoRecords
                                heading="You haven’t bookmarked any questions yet."
                                description="Take a practice session and bookmark the questions you want to revisit later."
                                buttonText="Start a practice session"
                                buttonClick={() => navigate(SiteMap.PracticePage.path)}
                            />
                        }

                    </Grid>
                </Box>
            </Box>
            <BookmarkModal open={openBookmarkModal} handleClose={() => setOpenBookmarkModal(false)} />
        </MenuLayout>
    )
}

export default BookmarksPage;