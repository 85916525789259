import { del, get, post, put, patch } from "./apiHelper";
import { apiEndpoints } from "./apiConstants";

//SignUp Endpoints
export const postSignUp = data => post(apiEndpoints.AUTH_URLS.SIGN_UP.SIGN_UP_REQUEST, data);
export const postGenerateOTP = data => post(apiEndpoints.AUTH_URLS.SIGN_UP.GENERATE_OTP, data)
export const postVerifyOTP = data => post(apiEndpoints.AUTH_URLS.SIGN_UP.VERIFY_OTP, data)
export const postValidateEmail = data => post(apiEndpoints.AUTH_URLS.SIGN_UP.VALIDATE_EMAIL, data)

//Login
export const postSignInWithOTP = data => post(apiEndpoints.AUTH_URLS.LOGIN.WITH_OTP, data);
export const postSignInWithPassword = data => post(apiEndpoints.AUTH_URLS.LOGIN.WITH_PASSWORD, data);
export const postGenerateLoginOTP = data => post(apiEndpoints.AUTH_URLS.LOGIN.GENERATE_OTP, data)

//Forgot/Reset Password
export const postGenerateForgotPasswordOTP = data => post(apiEndpoints.AUTH_URLS.FORGOT_PASSWORD.GENERATE_OTP, data)
export const postVerifyForgotPasswordOTP = data => post(apiEndpoints.AUTH_URLS.FORGOT_PASSWORD.VERIFY_OTP, data)
export const patchResetPassword = data => patch(apiEndpoints.AUTH_URLS.FORGOT_PASSWORD.RESET, data);

//Profile
export const getAvatars = () => get(apiEndpoints.AUTH_URLS.PROFILE.AVATARS.GET_AVATARS_LIST)
export const getProfileDetails = () => get(apiEndpoints.AUTH_URLS.PROFILE.DETAILS)
export const putProfileDetails = data => put(apiEndpoints.AUTH_URLS.PROFILE.DETAILS, data)
export const patchChangePassword = data => patch(apiEndpoints.AUTH_URLS.PROFILE.CHANGE_PASSWORD, data)
export const postBillingDetails = data => post(apiEndpoints.AUTH_URLS.PROFILE.BILLING_DETAILS, data)

//Practice Session
export const getTopicsList = () => get(apiEndpoints.PRACTICE.GET_TOPICS_LIST)
export const getSubTopicsList = (topic_id) => get(`${apiEndpoints.PRACTICE.GET_SUB_TOPICS_LIST}/${topic_id}`)
export const getDifficultyLevels = () => get(apiEndpoints.PRACTICE.GET_DIFFICULTY_LEVELS)
export const getQuestionFormats = () => get(apiEndpoints.PRACTICE.GET_QUESTION_FORMATS)
export const getInstructions = () => get(apiEndpoints.PRACTICE.GET_INSTRUCTIONS)
export const postPrepareSession = data => post(apiEndpoints.PRACTICE.SESSION, data)
export const getQuestion = () => get(apiEndpoints.PRACTICE.GET_QUESTION)
export const postEndSession = () => post(apiEndpoints.PRACTICE.END_SESSION)
export const postSubmitAnswer = data => post(apiEndpoints.PRACTICE.SUBMIT_ANSWER, data)
export const postSkipQuestion = (question_id) => post(`${apiEndpoints.PRACTICE.SKIP_QUESTION}/${question_id}`)
export const postPauseSession = (time) => post(apiEndpoints.PRACTICE.PAUSE_SESSION, time)
export const postResumeSession = () => post(apiEndpoints.PRACTICE.RESUME_SESSION)
export const getSessionQuestionStatus = () => get(apiEndpoints.PRACTICE.SESSION_QUESTION_STATUS)
export const getSessionStatus = () => get(apiEndpoints.PRACTICE.GET_SESSION_STATUS)

//Report Error
export const postReportError = data => post(apiEndpoints.PRACTICE.REPORT_ERROR, data)

//Dashboard
export const getPracticeSummary = filter => get(`${apiEndpoints.DASHBOARD.GET_PRACTICE_SUMMARY}${filter === "all_time" ? `` : `?filter=${filter}`}`)
export const getTopSuggestions = filter => get(`${apiEndpoints.DASHBOARD.GET_TOP_SUGGESTIONS}${filter === "all_time" ? `` : `?filter=${filter}`}`)
export const getConceptAnalysis = filter => get(`${apiEndpoints.DASHBOARD.GET_CONCEPT_ANALYSIS}${filter === "all_time" ? `` : `?filter=${filter}`}`)
export const getAbilityAnalysis = filter => get(`${apiEndpoints.DASHBOARD.GET_ABILITY_ANALYSIS}${filter === "all_time" ? `` : `?filter=${filter}`}`)

//Bookmark
export const postBookmarkQuestion = (question_id) => post(`${apiEndpoints.BOOKMARK.GET_POST_DELETE}/${question_id}`)
export const deleteBookmarkQuestion = (bookmark_id) => del(`${apiEndpoints.BOOKMARK.GET_POST_DELETE}/${bookmark_id}`)
export const getBookmarkQuestionDetails = (bookmark_id) => get(`${apiEndpoints.BOOKMARK.GET_POST_DELETE}/${bookmark_id}`)
export const getBookmarksList = () => get(apiEndpoints.BOOKMARK.GET_BOOKMARKS_LIST)

//ANALYSE
export const getSessionSummary = session_id => get(`${apiEndpoints.ANALYSE.GET_SESSION_SUMMARY}/${session_id}`)
export const getTopicSummary = session_id => get(`${apiEndpoints.ANALYSE.GET_TOPIC_SUMMARY}/${session_id}`)
export const getSubTopicSummary = (session_id, topic_id) => get(`${apiEndpoints.ANALYSE.GET_SUBTOPIC_SUMMARY}/${session_id}/${topic_id}`)
export const getSubTopicAbilityScores = (session_id, topic_id, subtopic_id) => get(`${apiEndpoints.ANALYSE.GET_SUBTOPIC_ABILITY_SCORES}/${session_id}/${topic_id}/${subtopic_id}`)
export const getSessionChart = session_id => get(`${apiEndpoints.ANALYSE.GET_SESSION_CHART}/${session_id}`)
export const getReportStatus = session_id => get(`${apiEndpoints.ANALYSE.GET_REPORT_STATUS}/${session_id}`)
export const getSessionAbilityScores = session_id => get(`${apiEndpoints.ANALYSE.GET_SESSION_ABILITY_SCORES}/${session_id}`)
export const getSessionHistory = () => get(apiEndpoints.ANALYSE.GET_SESSION_HISTORY)
export const getAnalyseChart = () => get(apiEndpoints.DASHBOARD.GET_PRACTICE_SUMMARY)
//Performance Drill Down
export const getTopicWiseAnalysis = () => get(apiEndpoints.ANALYSE.GET_TOPIC_WISE_ANALYSIS)
export const getSubtopicWiseAnalysis = (topic_id) => get(`${apiEndpoints.ANALYSE.GET_SUBTOPIC_WISE_ANALYSIS}/${topic_id}`)
export const getTopicSubtopicWiseAbilityAnalysis = (topic_id, subtopic_id) => get(`${apiEndpoints.ANALYSE.GET_TOPIC_SUBTOPIC_WISE_ABILITY_ANALYSIS}/${topic_id}/${subtopic_id}`)

//Landing Site
export const postSendMessage = data => post(apiEndpoints.LANDING_SITE.POST_SEND_MESSAGE, data)

// Payment
export const postGenerateBilling = data => post(apiEndpoints.PAYMENT.POST_GENERATE_BILLING, data)
export const postPaymentInit = data => post(apiEndpoints.PAYMENT.POST_PAYMENT_INITIATE, data)
export const postPaymentValidation = data => post(apiEndpoints.PAYMENT.POST_PAYMENT_VALIDATION, data)