import { Box, Button, Grid, Skeleton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Header, MenuLayout } from "../../components";
import styles from "./PracticePage.module.scss"
import { SiteMap } from "../../Routes";
import { bindActionCreators } from "redux";
import { actions } from "../../redux";

const InstructionPage = ({ openSessionInProgressModal = () => { }, openFreeSessionModal = () => { } }) => {
	const UI = useSelector(state => state.UI)
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const authState = useSelector(state => state.auth)
	const practiceState = useSelector(state => state.practice)
	const isLoading = practiceState.currentAction === "GET_INSTRUCTIONS_REQUEST"
	const { payment_plan } = authState
	const { instructions = [], sessionInProgress } = practiceState || {}
	const { difficulty, typeOfQuestions, topics = [], subtopics = [] } = UI.header || {}

	const { getApiPrepareSession } = bindActionCreators(
		actions.practice,
		dispatch
	);

	const { setSessionTime } = bindActionCreators(actions.timer, dispatch)

	let isAllTopics = practiceState.topicList.length === topics.length
	let isAllSubTopics = practiceState.subTopicList.length === subtopics.length

	const startPracticeSession = () => {
		if (sessionInProgress) {
			openSessionInProgressModal()
			return
		}

		if (practiceState.paymentRequired || (payment_plan !== "Paid" && payment_plan !== "Free")) {
			openFreeSessionModal()
			return
		}

		let SessionPayload = {
			difficulty: difficulty.id,
			question_format: typeOfQuestions.id
		}

		isAllTopics ? SessionPayload = { ...SessionPayload, all_topics: true } :
			SessionPayload = { ...SessionPayload, topics: topics.map(a => a.id) }

		topics.length === 1 &&
			(isAllSubTopics ? SessionPayload = { ...SessionPayload, all_subtopics: true } :
				SessionPayload = { ...SessionPayload, subtopics: subtopics.map(a => a.id) })

		topics.length > 1 && (SessionPayload = { ...SessionPayload, all_subtopics: true })

		getApiPrepareSession(SessionPayload)
	}

	React.useEffect(() => {
		if (topics?.length === 0) {
			navigate(SiteMap.PracticePage.path)
			return
		}
		if (topics.length === 1 && subtopics.length === 0) {
			navigate(SiteMap.SelectSubTopicPage.path)
			return
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	React.useEffect(() => {
		if (practiceState.currentAction === "PREPARE_SESSION_SUCCESS") {
			setSessionTime(0)
			navigate(SiteMap.QuestionPage.path)
		} // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [practiceState.currentAction])

	return (
		<MenuLayout>
			<Header header1={"Instructions"} />
			<Grid container>
				<Box className={styles.page_box}>
					<Box className={styles.page_content}>
						{
							topics.length > 0 &&
							<p>
								<span>Topic(s): </span>
								<span>{isAllTopics ? "All Topics" : topics.length === 1 ? topics[0].topic : `${topics.length} topics are selected`}</span>
							</p>
						}
						{subtopics.length > 0 &&
							<p>
								<span>Subtopic(s): </span>
								<span>{subtopics.length === 1 ? subtopics[0].subtopic : `${subtopics.length} subtopics are selected`}</span>
							</p>
						}
						{
							(difficulty || typeOfQuestions) &&
							<>
								<p className={styles.initial}>
									{difficulty && <><span>Difficulty: </span><span>{difficulty.label}</span></>}
								</p>
								<p className={styles.initial}>
									{typeOfQuestions && <><span>Type of questions: </span><span>{typeOfQuestions.label}</span></>}
								</p>
							</>
						}
					</Box>
					<Box className={styles.practice_instruction}>
						<p>Keep in mind as you practice:</p>
						<ul>
							{isLoading ?
								Array(5).fill().map((item, key) => (
									<li key={key} ><Skeleton className="btn" variant="rounded" height={30} /></li>
								))
								: instructions.map((instruction, index) => (
									<li key={index}>{instruction.instruction}</li>
								))
							}
						</ul>
					</Box>
				</Box>
				<Box className={`button_list bottom_btn_box button_list_center ${styles.footer_btns}`}>
					<Button disabled={Boolean(isLoading) || practiceState.loading} className="bottom_btn bottom_btn_back" onClick={() => navigate(-1)}>Back</Button>
					<Button disabled={Boolean(isLoading) || practiceState.loading} className="bottom_btn" onClick={() => startPracticeSession()}>Start Practice Session</Button>
				</Box>
			</Grid>
		</MenuLayout >
	);
};

export default InstructionPage;
