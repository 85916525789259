import React from "react";
import { Formik } from "formik";

const FormikForm = props => {
	const { innerRef, initialValues, onSubmit, validator, initialTouched = {}, validateOnMount = false } = props;

	return (
		<React.Fragment>
			<Formik
				enableReinitialize={true}
				innerRef={innerRef}
				initialValues={initialValues || {}}
				onSubmit={onSubmit}
				validationSchema={validator}
				validateOnBlur={true}
				validateOnChange={true}
				initialTouched={initialTouched}
				validateOnMount={validateOnMount}
			>
				{props.children}
				{/* {({ handleChange, handleBlur, handleSubmit, resetForm }) => (
					<Form
						onSubmit={handleSubmit}
						onChange={e => {
							handleChange(e);
							if (onFormChange) onFormChange(e);
						}}
						onBlur={handleBlur}
						onReset={resetForm}
					>
						{props.children}
					</Form>
				)} */}
			</Formik>
		</React.Fragment>
	);
};

export default FormikForm;
