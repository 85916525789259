import { UI_CONTEXT as context } from '../context';

const INITIAL_STATE = {
    header: {
        header1: "",
        header2: "",
        subHeader: "",
        topics: [],
        subtopics: [],
        difficulty: { id: 'mixed', label: 'Mixed' },
        typeOfQuestions: { id: 'mixed', label: 'Mixed' },
    },
    question: {
        showHint: false,
        showSolution: false,
        displaySideMenuHint: false,
        displaySideMenuSolution: false,
    }
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case context.RESET_UI_REDUCER:
            return INITIAL_STATE;

        case context.SET_HEADER1:
            return {
                ...state,
                header: { ...state.header, header1: action.payload }
            }
        case context.SELECT_TOPICS:
            return {
                ...state,
                header: { ...state.header, topics: action.payload }
            }
        case context.SELECT_SUBTOPICS:
            return {
                ...state,
                header: { ...state.header, subtopics: action.payload }
            }
        case context.SELECT_DIFFICULTY:
            return {
                ...state,
                header: { ...state.header, difficulty: action.payload }
            }
        case context.SELECT_QUESTION_FORMAT:
            return {
                ...state,
                header: { ...state.header, typeOfQuestions: action.payload }
            }
        case context.SHOW_HINT:
            return {
                ...state,
                question: { ...state.question, showSolution: false, displaySideMenuHint: true, showHint: action.payload }
            }
        case context.SHOW_SOLUTON:
            return {
                ...state,
                question: { ...state.question, showHint: false, displaySideMenuSolution: true, showSolution: action.payload }
            }
        case context.RESET_HINT_SOLUTION:
            return {
                ...state,
                question: {
                    ...state.question,
                    showHint: false,
                    showSolution: false,
                    displaySideMenuHint: false,
                    displaySideMenuSolution: false,
                }
            }
        default:
            return state;
    }
};

export default reducer;
