import React from "react"
import { Avatar, Box, Button, Modal } from "@mui/material"
import { useSelector } from "react-redux"
import styles from "./Overlays.module.scss"

const ChangeAvatarModal = ({ open = false, handleClose = () => { }, newAvatar = "", setNewAvatar = () => { }, handleChangeAvatar = () => { } }) => {
    const authState = useSelector(state => state.auth)
    const { avatarsList = [], user } = authState || {}

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-describedby="confirmation_modal"
        >
            <Box className={styles.change_avatar_modal}>
                <Box className={styles.avatar_model_title}>
                    <h1>Choose your new avatar</h1>
                </Box>

                <Box className={styles.avatar_list_wrp}>
                    {
                        avatarsList.length > 0 &&
                        avatarsList.map((avatar, index) => (
                            <Box key={index} className={`${styles.avatar} ${avatar === newAvatar ? styles.selected : ""}`}>
                                <Avatar src={avatar} onClick={() => setNewAvatar(avatar)} />
                                {
                                    avatar === user.profile_pic &&
                                    <Box className={styles.avatar_selected}>
                                        <span>Current</span>
                                    </Box>
                                }
                            </Box>
                        ))
                    }
                </Box>
                <Box className={styles.avatar_bottom_button}>
                    <Button className={styles.change_avatar_btn} onClick={handleChangeAvatar}>Change Avatar</Button>
                    <Button className={styles.cancel_avatar_btn} onClick={handleClose}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ChangeAvatarModal