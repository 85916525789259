import { Box, Grid, Typography, LinearProgress, Skeleton } from "@mui/material"
import styles from "./TopicWiseAnalysis.module.scss"
import EastIcon from '@mui/icons-material/East';

const SubtopicCard = ({ loading, subtopicAnalysis, hanldeSubtopicClick = () => { } }) => {

    const { attempt_summary: { correct = 0, incorrect = 0, partially_correct = 0 } = {},
        overall_accuracy,
        questions_practiced,
        time_spent,
        subtopic_id, subtopic,
        topic_id
    } = subtopicAnalysis || {}

    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className={styles.second_topic_box}>
                {
                    loading ?
                        <Skeleton height={40} /> :
                        <Typography variant="h6" className={styles.first_topic_title} onClick={() => hanldeSubtopicClick(subtopic, subtopic_id, topic_id)}>
                            {subtopic}
                            <EastIcon />
                        </Typography>
                }
                <Box className={styles.second_topic_list_outer}>
                    <Box className={styles.first_topic_list}>
                        <ul>
                            <li>
                                <p>Question Practice</p>
                                {loading ? <Skeleton width={20} /> : <span>{questions_practiced}</span>}
                            </li>
                            <li>
                                <p>Attempt Summary</p>
                                <span className={styles.attempt_summary}>
                                    {loading ? <Skeleton width={20} /> : <>
                                        <div className={styles.attempt_summary_green}>{correct}</div>
                                        <div className={styles.attempt_summary_yellow}>{partially_correct}</div>
                                        <div className={styles.attempt_summary_red}>{incorrect}</div>
                                    </>}
                                </span>
                            </li>
                            <li>
                                <p>Time Spent</p>
                                {loading ? <Skeleton width={20} /> : <span>{time_spent}</span>}
                            </li>
                        </ul>
                    </Box>
                </Box>
                <Typography variant="h6" className={styles.first_topic_overall_accuracy}>
                    Overall Accuracy
                    {loading ? <><Skeleton width={20} />%</> : <span>{overall_accuracy}%</span>}
                </Typography>
                <Box className={styles.first_topic_progress}>
                    <Box aria-describedby="box_right_progress_theme2" className={styles.first_topic_progress}>
                        <LinearProgress variant={loading ? "indeterminate" : "determinate"} value={overall_accuracy} />
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

export default SubtopicCard;