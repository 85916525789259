import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SiteMap } from "../../Routes";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../redux";

const ChooseLoginMethod = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { resetSignUpState } = bindActionCreators(
		actions.signup,
		dispatch
	);

	React.useEffect(() => {
		resetSignUpState()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Box>
			<div className="reg-title">
				<h1>Login</h1>
			</div>

			<div className="full-width-btn-cntnr login-buttons">
				<Button
					onClick={() => {
						navigate(SiteMap.OTPLoginPage.path);
					}}
					variant="contained"
					size="large"
					className=""
				>
					Login using OTP
				</Button>
				<Typography variant="h4" className="or-text">Or</Typography>

				<Button
					onClick={() => {
						navigate(SiteMap.PasswordLoginPage.path);
					}}
					variant="contained"
					size="large"
					className=""
				>
					Login using Password
				</Button>
				<Box className="create-new-account">
					<div className="reg-title">
						<h1>Create New Account</h1>
					</div>
					<Button
						onClick={() => {
							navigate(SiteMap.SignupPage.path);
						}}
						variant="contained"
						size="large"
					>
						Sign Up
					</Button>
				</Box>
			</div>
		</Box>
	);
};

export default ChooseLoginMethod;
