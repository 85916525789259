import React from "react"
import { store } from "../../redux/store";
import { getApiLogOut } from "../../redux/actions/authActions";
import { SiteMap } from "../../Routes";
import { useNavigate } from "react-router-dom";

const SignOutPage = () => {
    const navigate = useNavigate()
    React.useEffect(() => {
        Promise.all([store.dispatch(getApiLogOut())])
            .then(() => {
                navigate(SiteMap.HomePage.path)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>Resetting All Reducers...</>
    )
}

export default SignOutPage;