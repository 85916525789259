import { UI_CONTEXT as context } from '../context';

export const resetUIReducer = () => ({
    type: context.RESET_UI_REDUCER
})

export const setHeader1 = (payload) => ({
    type: context.SET_HEADER1,
    payload: payload
})

export const selectTopics = (payload) => ({
    type: context.SELECT_TOPICS,
    payload: payload
})

export const selectSubTopics = (payload) => ({
    type: context.SELECT_SUBTOPICS,
    payload: payload
})

export const selectDifficulty = (payload) => ({
    type: context.SELECT_DIFFICULTY,
    payload: payload
})

export const selectQuestionFormat = (payload) => ({
    type: context.SELECT_QUESTION_FORMAT,
    payload: payload
})

export const toggleHint = (payload) => ({
    type: context.SHOW_HINT,
    payload: payload
})

export const toggleSolution = (payload) => ({
    type: context.SHOW_SOLUTON,
    payload: payload
})

export const resetHintSolution = () => ({
    type: context.RESET_HINT_SOLUTION
})