import { Box, Grid, Button, Skeleton, Tab, Tabs } from '@mui/material'
import React from 'react'
import { CustomPagination, MenuLayout, PerformanceDrilldown, useDataGridSkeletonHelper } from '../../components'
import styles from './AnalysePage.module.scss'
import { chartConfig } from "../../components/assets";
import ReactApexChart from "react-apexcharts";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { TabPanel, a11yProps } from "../../components";

const PracticeHistoryPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isMobile = useMediaQuery({ query: "(max-width: 599px)" });
    const analyseState = useSelector(state => state.analyse)
    const { session_history = [], session_history_chart_loading, session_history_loading } = analyseState || {}
    const { chart } = analyseState.session_history_chart || {}
    const AnalyseDataTableRef = React.useRef(null)
    const { loadingRows, loadingColumns } = useDataGridSkeletonHelper({ row: 3, columns: 5, height: 30, width: 100 })
    const { getApiSessionHistory, getApiAnalyseChart } = bindActionCreators(
        actions.analyse,
        dispatch
    );

    React.useEffect(() => {
        getApiAnalyseChart()
        getApiSessionHistory() // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (selected) => {
        let { session_id } = selected
        navigate({ pathname: SiteMap.AnalysePage.path },
            { state: { session_id: session_id, needPolling: false } });
    }

    const columns = [
        {
            field: 'session_id',
            headerName: 'Session Title',
            flex: 1,
            align: 'left',
            disableColumnMenu: true,
            renderCell: (params) => (params.row.session_title)
        },
        {
            field: 'session_date',
            headerName: 'Session Date',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true,
            sortComparator: (v1, v2) => (moment(v1, 'DD-MMM-YYYY') - moment(v2, 'DD-MMM-YYYY'))
        },
        {
            field: 'accuracy',
            headerName: 'Session Accuracy',
            flex: 0.5,
            align: 'center',
            disableColumnMenu: true,
            sortComparator: (v1, v2) => (Number(v1) - Number(v2))
        },
        {
            field: 'topics',
            headerName: 'Topics Covered in the Session',
            flex: 0.8,
            align: 'center',
            disableColumnMenu: true,
            sortComparator: (v1, v2) => (v1.toString() < v2.toString() ? -1 : 1)
        },
        {
            field: 'action',
            headerName: ' ',
            flex: 1,
            align: 'right',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box className="button_list_center bottom_btn_box ml-auto">
                        <Button className="bottom_btn bottom_btn_back" onClick={() => handleClick(params.row)}>
                            {isMobile ? "Analyse" : "Analyse Session"}
                        </Button>
                    </Box>
                );
            },
            renderHeader: () => (<> </>)
        }
    ];

    const [options, setOptions] = React.useState({
        ...chartConfig, xaxis: { categories: [], labels: { show: false } }, yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
                show: true,
                formatter: (value) => { return `${value}%` },
            },
        },
        title: {
            text: "Accuracy",
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#8A8A8A",
                fontSize: '10px',
                fontFamily: 'Poppins, Arial, sans-serif',
                fontWeight: 500,
                cssClass: 'apexcharts-xaxis-title',
            },
        },
    });

    const [series, setSeries] = React.useState([{ name: "Accuracy", data: [] }])

    React.useEffect(() => {
        if (chart && chart.hasOwnProperty("dates") && chart.hasOwnProperty("accuracies")) {
            setOptions({ ...options, xaxis: { categories: [...chart?.dates], labels: { show: true } } })
            setSeries([{ data: [...chart?.accuracies] }])
        }  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart])


    React.useEffect(() => {
        let barOption = { ...options.plotOptions.bar }
        if (isMobile) {
            barOption['dataLabels'] = { orientation: 'vertical' }
        } else {
            barOption['dataLabels'] = { orientation: 'horizontal' }
        }
        setOptions({ ...options, plotOptions: { bar: barOption } })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile])

    const [tab, setTab] = React.useState(0);
    const handleChangeTabs = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <MenuLayout>

            <Box className={`performance_tab_box ${styles.performance_tab_box}`}>
                <Tabs value={tab} onChange={handleChangeTabs} aria-label="basic tabs example">
                    <Tab icon={<HistoryIcon />} iconPosition="start" label="Practice Session History" {...a11yProps(0)} />
                    <Tab icon={<ListAltIcon />} iconPosition="start" label="Performance Drilldown" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0} className={`tab_layout_outer ${styles.tab_layout_outer}`}>
                <Box className={styles.AnalyzeWrp}>
                    <Grid container spacing={2}>
                        <Grid container className={styles.analyze_container} spacing={2}>
                            {
                                session_history_chart_loading ?
                                    <Grid item xs={12}>
                                        <Skeleton className='rounded' variant='rounded' height={400} width={'100%'} />
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        <Box className={styles.chartBox}>
                                            <Box className={styles.pageTitle}>
                                                <h6>Last 5 Practice Sessions</h6>
                                            </Box>
                                            <Box className={styles.chartView}>
                                                <ReactApexChart options={options} series={series} type="bar" height={350} />
                                            </Box>
                                        </Box>
                                    </Grid>
                            }

                            <Grid item xs={12}>
                                <Box className={styles.topicsPracticed}>

                                    <Box className={styles.AnalyseDataTable} ref={AnalyseDataTableRef}>
                                        {session_history_loading ?
                                            <DataGrid
                                                className="analytics_data_grid"
                                                classes={{ row: "analytics_data_row practice_data_row" }}
                                                autoHeight
                                                rows={loadingRows}
                                                columns={loadingColumns}
                                            />
                                            :
                                            session_history.length > 0 ?
                                                <DataGrid
                                                    className="analytics_data_grid with_custom_pagination_footer"
                                                    getRowId={(row) => row.session_id}
                                                    components={{
                                                        Pagination: () => (<CustomPagination scrollToRef={AnalyseDataTableRef} />),
                                                        ColumnUnsortedIcon: () => (<ImportExportOutlinedIcon />),
                                                        ColumnSortedAscendingIcon: () => (<ArrowUpwardOutlinedIcon />),
                                                        ColumnSortedDescendingIcon: () => (<ArrowDownwardOutlinedIcon />)
                                                    }}
                                                    classes={{ row: "analytics_data_row practice_data_row" }}
                                                    autoHeight
                                                    rows={session_history}
                                                    columns={columns}
                                                    // rowsPerPageOptions={[5, 10, 15]}
                                                    pageSize={100}
                                                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    disableSelectionOnClick
                                                /> : <></>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className={`bottom_btn_box footer_button_list ${styles.footer_button}`}>
                            <Button className="bottom_btn" onClick={() => navigate(SiteMap.PracticePage.path)}>Start another practice session</Button>
                            <Button className="bottom_btn_back" onClick={() => navigate(SiteMap.DashboardPage.path)}>View Dashboard</Button>
                        </Box>
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={tab} index={1} className={`tab_layout_outer ${styles.tab_layout_outer}`}>
                <PerformanceDrilldown viewPracticeHistory={() => setTab(0)} />
            </TabPanel>
        </MenuLayout>
    )
}

export default PracticeHistoryPage