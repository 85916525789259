import { useSelector } from "react-redux"
import { ConceptBox } from ".."
import styles from "./ConceptAnalysis.module.scss"

const ConceptAnalysis = () => {

    const DashboardState = useSelector(state => state.dashboard)
    const { conceptAnalysisLoading } = DashboardState || {}
    const { apply = [], do_not_know = [], knows = [], understand = [] } = DashboardState.conceptAnalysis || {}

    return (
        <>
            <ConceptBox
                loading={conceptAnalysisLoading}
                title="Do Not Know"
                data={do_not_know.length > 0 ? do_not_know : undefined}
                message="Nothing here for now; keep practicing so that we can tell you what comes here."
                styleClass={`${styles.concept_box} ${styles.concept_box_red}`} />
            <ConceptBox
                loading={conceptAnalysisLoading}
                title="Know"
                data={knows.length > 0 ? knows : undefined}
                message="Nothing here for now; keep practicing so that we can tell you what comes here."
                styleClass={`${styles.concept_box} ${styles.concept_box_yellow}`} />
            <ConceptBox
                loading={conceptAnalysisLoading}
                title="Understand"
                data={understand.length > 0 ? understand : undefined}
                message="Nothing here for now; keep practicing so that we can tell you what comes here."
                styleClass={`${styles.concept_box} ${styles.concept_box_blue}`} />
            <ConceptBox
                loading={conceptAnalysisLoading}
                title="Apply"
                data={apply.length > 0 ? apply : undefined}
                message="Nothing here for now; keep practicing so that we can tell you what comes here."
                styleClass={`${styles.concept_box} ${styles.concept_box_green}`} />
        </>
    )
}

export default ConceptAnalysis;