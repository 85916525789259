import { TOAST_CONTEXT as context } from '../context';

export const ShowToast = (payload) => {
    return (dispatch) => {
        dispatch({
            type: context.TOAST_SHOW,
            payload: payload
        })
    }
}

export const HideToast = () => {
    return (dispatch) => {
        dispatch({
            type: context.TOAST_HIDE
        })
    }
}