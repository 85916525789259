import React from "react";
import {
	FormikForm,
	FormikTextField,
	FormikSelect,
	FormikOTPField,
} from "..";
import {
	Box,
	Button,
	FormHelperText,
	Grid,
	InputAdornment,
	Skeleton,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ClassList, GenderList } from "../assets";
import Collapse from "@mui/material/Collapse";
import { actions } from "../../redux";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { SignUpFormStepOneValidator, SignUpFormStepTwoValidator } from "../assets"
import { SiteMap } from "../../Routes";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { postValidateEmail } from "../../api/apiCalls";
import { Form } from "formik";

const FirstStep = ({ handleSubmit, SignUpState, updateSignUpForm }) => {
	const ref = React.useRef(null)
	const dispatch = useDispatch();
	const { isOtpSent, isOtpVerified, verifyingOTP, otpVerificationError } = SignUpState
	const { getApiOtpGenerate, getApiOtpVerification, resetOTPStates } = bindActionCreators(
		actions.signup,
		dispatch
	);

	const [otpFieldState, setOtpFieldState] = React.useState(false);
	const [otpTimer, setOtpTimer] = React.useState(-1);
	const [resetOtpField, setResetOtpField] = React.useState(false)

	const resetOTPFields = () => {
		setResetOtpField(!resetOtpField)
		for (let i = 0; i < Number(process.env.REACT_APP_OTP_LENGTH); i++) {
			ref.current.setFieldValue(`otpsubfield${i}`, "")
		}
	}

	const verifyOtp = (otpStr) => {
		if (isOtpVerified) return
		if (!otpStr) {
			ref.current.setFieldTouched("mobile_number").then(() => {
				if (!ref.current.errors.hasOwnProperty("mobile_number")) {
					resetOTPFields()
					if (otpTimer === -1 || !isOtpSent || !otpFieldState) {
						setOtpTimer(30)
						getApiOtpGenerate({ "mobile_number": ref.current.values.mobile_number })
					}
					setOtpFieldState(true);
				}
			})
		} else {
			if (!ref.current.errors.hasOwnProperty("mobile_number") && isOtpSent && otpStr) {
				getApiOtpVerification({ "mobile_number": ref.current.values.mobile_number, "otp": otpStr })
				updateSignUpForm({ ...SignUpState.signUpForm, "otp": otpStr })
			}
		}
	};

	React.useEffect(() => {
		let timer
		if (otpFieldState === true && isOtpSent && otpTimer > 0) {
			timer = setTimeout(() => {
				setOtpTimer(otpTimer - 1)
			}, 1000)
		} else if (otpTimer === 0 && isOtpSent) {
			setOtpTimer(-1)
			setOtpFieldState(false)
		}
		if (isOtpVerified) {
			setOtpTimer(-1)
			setOtpFieldState(false)
			clearTimeout(timer)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [otpFieldState, otpTimer, isOtpSent, isOtpVerified])

	const hanldeOTPChange = (otpStr) => {
		if (otpStr.length === Number(process.env.REACT_APP_OTP_LENGTH) && !isOtpVerified) verifyOtp(otpStr)
	}

	return (
		<FormikForm
			innerRef={ref}
			onSubmit={handleSubmit}
			validator={SignUpFormStepOneValidator}
		>
			{({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
				<Form
					onSubmit={handleSubmit}
					onChange={e => {
						handleChange(e);
					}}
					onBlur={handleBlur}
					onReset={resetForm}
				>
					<Grid direction="row" container spacing={3} className="field-outer">
						<Grid container item sm={6}>
							<div className="custom-form-field">
								<label className="form-label">First Name</label>
								<FormikTextField
									className="white-bg-field"
									name="first_name"
									label=""
								/>
							</div>
						</Grid>
						<Grid container item sm={6}>
							<div className="custom-form-field">
								<label className="form-label">Last Name</label>
								<FormikTextField
									className="white-bg-field"
									name="last_name"
									label=""
								/>
							</div>
						</Grid>
					</Grid>
					<Grid direction="row" container spacing={1} className="field-outer">
						<Grid container item sm={12}>
							<div className="custom-form-field">
								<label className="form-label">Mobile Number</label>
								<FormikTextField
									className="white-bg-field"
									id="mobile_number"
									name="mobile_number"
									label=""
									onChange={() => resetOTPStates()}
									disabled={isOtpVerified}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">+91</InputAdornment>
										),
										endAdornment: (
											<Button
												size="small"
												onClick={() => verifyOtp()}
												className="otp-button-primary"
												variant="contained"
												id="verify"
												disabled={(otpTimer !== 0 && otpFieldState && isOtpSent) || isOtpVerified}
											>
												{isOtpVerified ? <CheckCircleIcon /> : !isOtpSent ? "Verify" : "Resend"}
											</Button>
										),
									}}
									inputProps={{
										maxLength: 10,
									}}
								/>
								{otpFieldState === true && isOtpSent && otpTimer !== 0 &&
									<Box className="display-flex justify-content-flex-end">
										<Box className="ghost-button-primary p-6">Resend in {otpTimer}</Box>
									</Box>
								}
							</div>
						</Grid>
					</Grid>
					<Collapse in={isOtpSent}>
						<Grid direction="row" container spacing={1} className="field-outer">
							<Grid container item sm={12}>
								<div className="custom-form-field">
									<label className="form-label">One-time Password (OTP)</label>
									<FormikOTPField
										resetOTPFields={resetOtpField}
										className="white-bg-field otp-field"
										name="otp"
										label=""
										otpLength={process.env.REACT_APP_OTP_LENGTH ?? 6}
										disabled={isOtpVerified}
										onChange={hanldeOTPChange}
									/>
									{verifyingOTP && <FormHelperText className="form-success">Verifying...</FormHelperText>}
									{otpVerificationError && <FormHelperText className="form-errors">{otpVerificationError?.data?.message}</FormHelperText>}
								</div>
							</Grid>
						</Grid>
					</Collapse>
					<Grid direction="row" container spacing={3}>
						<Grid container item sm={12}>
							<div className="custom-form-field">
								<label className="form-label">Email</label>
								<FormikTextField className="white-bg-field" name="email_id" label="" />
							</div>
						</Grid>
					</Grid>
					<div className="form-action-button">
						<Button
							id="continue"
							type="submit"
							size="large"
							className="form-action-button-orange"
							variant="contained"
							disabled={!(dirty && isValid)}
						>
							Continue
						</Button>
					</div>
					<div className="login-route-option">
						<label>Existing Account?</label>
						<ul>
							<li>
								<Link to={SiteMap.HomePage.path}>Login to Existing Account</Link >
							</li>
						</ul>
					</div>
				</Form>)}
		</FormikForm>

	);
};

const SecondStep = ({ handleSubmit, SignUpState }) => {
	const { loading } = SignUpState || {}
	return (
		<FormikForm
			onSubmit={handleSubmit}
			validator={SignUpFormStepTwoValidator}
		>
			{({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
				<Form
					onSubmit={handleSubmit}
					onChange={e => {
						handleChange(e);
					}}
					onBlur={handleBlur}
					onReset={resetForm}
				>
					<Grid direction="row" container spacing={3} className="field-outer">
						<Grid container item sm={6}>
							<div className="custom-form-field">
								<label className="form-label">Class<em>*</em></label>
								<FormikSelect
									items={ClassList}
									placeholder="Choose your Class"
									className="white-bg-field custom-select"
									name="student_class"
									label=""
								/>
							</div>
						</Grid>
						<Grid container item sm={6}>
							<div className="custom-form-field">
								<label className="form-label">Gender<em>*</em></label>
								<FormikSelect
									items={GenderList}
									placeholder="Your Gender"
									className="white-bg-field custom-select"
									name="gender"
									label=""
								/>
							</div>
						</Grid>
					</Grid>

					<Grid direction="row" container spacing={3}>
						<Grid container item sm={4} className="field-outer">
							<div className="custom-form-field">
								<label className="form-label">School Name<em>*</em></label>
								<FormikTextField
									className="white-bg-field"
									name="school"
									label=""
								/>
							</div>
						</Grid>
						<Grid container item sm={4} className="field-outer">
							<div className="custom-form-field">
								<label className="form-label">Branch</label>
								<FormikTextField
									className="white-bg-field"
									name="branch"
									label=""
								/>
							</div>
						</Grid>
						<Grid container item sm={4} className="field-outer">
							<div className="custom-form-field">
								<label className="form-label">City<em>*</em></label>
								<FormikTextField className="white-bg-field" name="city" label="" />
							</div>
						</Grid>
					</Grid>

					<Grid direction="row" container spacing={3}>
						<Grid container item sm={6} className="field-outer">
							<div className="custom-form-field">
								<label className="form-label">Parent's Name</label>
								<FormikTextField
									className="white-bg-field"
									name="parent_name"
									label=""
								/>
							</div>
						</Grid>
						<Grid container item sm={6} className="field-outer">
							<div className="custom-form-field">
								<label className="form-label">Parent's Mobile<em>*</em></label>
								<FormikTextField
									className="white-bg-field"
									name="parent_mobile"
									label=""
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">+91</InputAdornment>
										),
									}}
									inputProps={{
										maxLength: 10,
									}}
								/>
							</div>
						</Grid>
					</Grid>

					<div className="form-action-button">
						{loading ?
							<Skeleton
								variant="circular"
								className="form-action-button-orange display-flex align-items-center justify-content-center"
								height={55}>
								Signing Up Please Wait
							</Skeleton>
							: <Button
								id="singup"
								type="submit"
								size="large"
								className="form-action-button-orange"
								variant="contained"
								disabled={!(dirty && isValid) || loading}
							>
								Complete Sign Up
							</Button>
						}
					</div>
					<div className="auth-action-btns">

					</div>
				</Form>)}
		</FormikForm>
	);
};

const SignUpForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { updateSignUpForm, getApiSignUp, resetOTPStates, resetSignUpState } = bindActionCreators(
		actions.signup,
		dispatch
	);

	const { ShowToast } = bindActionCreators(
		actions.toast,
		dispatch
	);

	const { resetAuthReducer } = bindActionCreators(
		actions.auth,
		dispatch
	);

	React.useEffect(() => {
		resetSignUpState()
		resetAuthReducer()
		resetOTPStates()  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const SignUpState = useSelector(state => state.signup)
	const { isOtpVerified } = SignUpState

	const [activeStep, setActiveStep] = React.useState(1);

	const nextStep = () => {
		if (activeStep < 2) setActiveStep(activeStep + 1);
	};

	React.useEffect(() => {
		if (SignUpState.currentAction === "SIGN_UP_SUCCESS") {
			navigate(SiteMap.DashboardPage.path)
		}  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [SignUpState.currentAction])

	// const previousStep = () => {
	// 	if (activeStep > 0) setActiveStep(activeStep - 1);
	// };

	const handleSubmit = data => {
		if (!isOtpVerified) {
			ShowToast({ "message": "You must verify your mobile number", "severity": "error" })
		} else {
			if (activeStep < 2) {
				postValidateEmail({ "email_id": data.email_id }).then((res) => {
					if (res.data.error) {
						ShowToast({ "message": res.data.message, "severity": "error" })
					} else {
						nextStep()
					}
				}).catch(() => {
					ShowToast({ "message": "Something went wrong during email validation", "severity": "error" })
				})
			}
		}
		if (activeStep < 2) {
			updateSignUpForm({ ...SignUpState.signUpForm, ...data })
		}
		else {
			let payload = {
				student: {
					...data,
					"first_name": SignUpState.signUpForm.first_name,
					"last_name": SignUpState.signUpForm.last_name,
					"mobile_number": SignUpState.signUpForm.mobile_number,
					"email_id": SignUpState.signUpForm.email_id,
				},
				otp: { "otp": SignUpState.signUpForm.otp }
			}
			getApiSignUp(payload)
		}
	};

	return (

		<Box className="sign_up_card">
			<div className="reg-title">
				<h1>Create New Account</h1>
			</div>
			{activeStep === 1 && <FirstStep handleSubmit={handleSubmit} SignUpState={SignUpState} updateSignUpForm={updateSignUpForm} />}
			{activeStep === 2 && <SecondStep handleSubmit={handleSubmit} SignUpState={SignUpState} />}

		</Box>
	);
};

export default SignUpForm;
