import { AnalysisBox } from ".."
import styles from "./AbilityAnalysis.module.scss"

const AbilityAnalysis = ({ data, loading }) => {

    const { easy = [], difficult = [] } = data || {}

    return (
        <>
            <AnalysisBox
                loading={loading}
                data={easy.length > 0 ? easy : undefined}
                title="You find it easy to"
                message="Follow our suggestions of Revision and Practice enough problems to find this one out."
                styleClass={`${styles.ability_wise_analysis} ${styles.ability_wise_analysis_green}`} />

            <AnalysisBox
                loading={loading}
                data={difficult.length > 0 ? difficult : undefined}
                title="You find it diffiicult to"
                message="Keep solving problems. We will tell you your weaknesses as soon as there are any!"
                styleClass={`${styles.ability_wise_analysis} ${styles.ability_wise_analysis_red}`} />
        </>
    )
}

export default AbilityAnalysis;