import React from 'react'
import { ClassList, GenderList, ParentsRelationship, ProfileFormInitialTouched } from "../assets";
import { Avatar, Box, Button, Grid, Skeleton } from "@mui/material"
import FormikForm from "../FormikForm/FormikForm"
import FormikTextField from "../FormikTextField/FormikTextField"
import styles from './ProfileForm.module.scss'
import FormikSelect from "../FormikSelect/FormikSelect";
import PasswordField from "../PasswordField/PasswordField";
import { ChangeAvatarModal } from "..";
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../redux';
import { ProfileFormValidator, PasswordValidator } from '../assets';
import { bindActionCreators } from 'redux';
import { AUTH_CONTEXT } from '../../redux/context';
import { Form } from "formik";
import moment from 'moment';

const ProfileForm = () => {
    const dispatch = useDispatch()
    const authState = useSelector(state => state.auth)
    let { user } = authState
    user.date_of_birth &&
        (user = { ...user, date_of_birth: moment(user.date_of_birth, 'DD/MM/YYYY').format("YYYY-MM-DD") })

    const isLoading = authState.currentAction === AUTH_CONTEXT.GET_PROFILE_REQUEST
        || authState.currentAction === AUTH_CONTEXT.GET_AVATARS_REQUEST
    const updatingProfile = authState.currentAction === AUTH_CONTEXT.PUT_PROFILE_REQUEST
    const updatingPassword = authState.currentAction === AUTH_CONTEXT.PATCH_PASSWORD_REQUEST

    const { putApiProfileDetails, patchApiPasswordChange } = bindActionCreators(
        actions.auth,
        dispatch
    );

    const [openAvatarModal, setOpenAvatarModal] = React.useState(false)
    const [newAvatar, setNewAvatar] = React.useState("")

    const handleChangeAvatar = () => {
        if (!newAvatar || user.profile_pic === newAvatar) {
            return
        }
        putApiProfileDetails({ ...authState.user, "profile_pic": newAvatar })
        setOpenAvatarModal(false)
    }

    const handleChangeProfile = data => {
        Object.keys(data).forEach((k) => { if (data[k] === null || data[k] === "") { data[k] = null } });
        if (data.date_of_birth) {
            let formattedDate = moment(data.date_of_birth, 'YYYY-MM-DD').format("DD/MM/YYYY");
            data = { ...data, date_of_birth: formattedDate }
        }
        let { callback_form_filled, is_first_session, ...restOfData } = data;
        putApiProfileDetails(restOfData)
    };

    const handleChangePassword = data => {
        patchApiPasswordChange({ "password": data.password })
    }

    return (
        <Box>
            <Box className={`${styles.profile_form} ${styles.no_border_radius_bottom}`}>
                <Grid direction="row" container spacing={3}>
                    <Grid item xs={12} className={styles.avatar_box}>
                        <Avatar
                            src={user.profile_pic}
                        />
                        <Button onClick={() => setOpenAvatarModal(true)} >Change Avatar</Button>
                    </Grid>
                </Grid>
                <Grid direction="row" container >
                    <Grid item sm={12}>
                        <h1 className={styles.profile_form_title}>Edit Profile Information</h1>
                    </Grid>
                </Grid>
            </Box>
            {
                isLoading ?
                    <Box className={`${styles.profile_form} ${styles.no_border_radius_top}`}>
                        <Grid direction="row" container spacing={6} className="field-outer">
                            {Array(12).fill().map((item, index) => (
                                <Grid key={index} item xs={12} md={6} lg={4}>
                                    <Skeleton variant="rounded" height={43} />
                                </Grid>
                            ))
                            }
                        </Grid>
                    </Box>
                    :
                    <>
                        <FormikForm
                            validator={ProfileFormValidator}
                            validateOnMount={true}
                            initialTouched={ProfileFormInitialTouched}
                            onSubmit={handleChangeProfile}
                            initialValues={user}
                        >
                            {({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
                                <Form
                                    onSubmit={handleSubmit}
                                    onChange={e => {
                                        handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    onReset={resetForm}
                                >
                                    <Box className={`${styles.profile_form} ${styles.no_border_radius_top}`}>
                                        <Grid direction="row" container spacing={3} className="field-outer">
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">First Name<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="first_name"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Last Name<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="last_name"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="row" container spacing={3} className="field-outer">
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Mobile<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="mobile_number"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Email<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="email_id"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="row" container spacing={3} className="field-outer">
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Class<em>*</em></label>
                                                    <FormikSelect
                                                        items={ClassList}
                                                        placeholder="Choose your Class"
                                                        className="white-bg-field custom-select"
                                                        name="student_class"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Gender<em>*</em></label>
                                                    <FormikSelect
                                                        items={GenderList}
                                                        placeholder="Choose your Gender"
                                                        className="white-bg-field custom-select"
                                                        name="gender"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Date of Birth</label>
                                                    <FormikTextField
                                                        type="date"
                                                        className="white-bg-field"
                                                        name="date_of_birth"
                                                        label=""
                                                        inputProps={{
                                                            max: moment().subtract(10, 'years').format('YYYY-MM-DD')
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="row" container spacing={3} className="field-outer">
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">School<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="school"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Branch</label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="branch"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">City<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="city"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid direction="row" container spacing={3} className="field-outer">
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Parent Relationship</label>
                                                    <FormikSelect
                                                        items={ParentsRelationship}
                                                        placeholder="Choose Parent Relationship"
                                                        className="white-bg-field custom-select"
                                                        name="parent_relation"
                                                        label=""
                                                    />
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Parent's Full Name</label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="parent_name"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="custom-form-field">
                                                    <label className="form-label">Parent's Mobile<em>*</em></label>
                                                    <FormikTextField
                                                        className="white-bg-field"
                                                        name="parent_mobile"
                                                        label=""
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className={`${styles.save_box} bottom_btn_box`}>
                                        {updatingProfile ? <Skeleton className="bottom_btn" variant="rounded" width={218} height={52} >Updating</Skeleton> :
                                            <Button id="save_profile" type='submit' disabled={!(dirty && isValid)} className="bottom_btn">Save Profile</Button>}
                                    </Box>
                                </Form>)}
                        </FormikForm>
                    </>
            }

            <FormikForm validator={PasswordValidator} onSubmit={handleChangePassword}>
                {({ handleChange, handleBlur, handleSubmit, resetForm, isValid, dirty }) => (
                    <Form
                        onSubmit={handleSubmit}
                        onChange={e => {
                            handleChange(e);
                        }}
                        onBlur={handleBlur}
                        onReset={resetForm}
                    >
                        <Box className={styles.profile_form}>
                            <Grid direction="row" container >
                                <Grid item sm={12}>
                                    <h1 className={styles.profile_form_title}>Change Password</h1>
                                </Grid>
                            </Grid>
                            <Grid direction="row" container spacing={3} className="field-outer">
                                <Grid item xs={12} md={6} lg={4}>
                                    <div className="custom-form-field">
                                        <label className="form-label">New Password</label>
                                        <PasswordField
                                            name="n_password"
                                            className="white-bg-field"
                                            label=""
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <div className="custom-form-field">
                                        <label className="form-label">Confirm Password</label>
                                        <PasswordField
                                            name="password"
                                            className="white-bg-field"
                                            label=""
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className={`${styles.save_box} bottom_btn_box`}>
                            {updatingPassword ? <Skeleton className="bottom_btn" variant="rounded" width={274} height={52} >Updating</Skeleton> :
                                <Button id="change_password" type='submit' disabled={!(dirty && isValid)} className="bottom_btn">Change Password</Button>}
                        </Box>
                    </Form>)}
            </FormikForm>

            <ChangeAvatarModal
                open={openAvatarModal}
                handleClose={() => setOpenAvatarModal(false)}
                newAvatar={newAvatar}
                setNewAvatar={setNewAvatar}
                handleChangeAvatar={handleChangeAvatar}
            />
        </Box >
    )
}

export default ProfileForm