import React from 'react'
import { Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { MenuLayout, ProfileForm } from "../../components"
import { actions } from "../../redux";
import styles from './ProfilePage.module.scss'

const ProfilePage = () => {
    const dispatch = useDispatch()
    const authState = useSelector(state => state.auth)

    const { first_name, last_name } = authState.user || {}
    const { getApiProfileDetails, getApiAvatarsList } = bindActionCreators(
        actions.auth,
        dispatch
    );

    React.useEffect(() => {
        getApiProfileDetails()
        getApiAvatarsList() // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <MenuLayout>
            <Box className={styles.profile_page}>
                <Box className={styles.profile_header}>
                    <h1>{first_name} {last_name}’s Profile</h1>
                </Box>
                <Box>
                    <ProfileForm />
                </Box>
            </Box>
        </MenuLayout>
    )
}

export default ProfilePage