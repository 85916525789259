import { Box, Tab, Tabs } from "@mui/material";
import React from "react"
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import styles from './Suggestions.module.scss'
import SuggestionTab from "./SuggestionTab";
import { TabPanel, a11yProps } from "../../components/"
import { useSelector } from "react-redux";

const Suggestions = () => {
    const DashboardState = useSelector(state => state.dashboard)
    const { topSuggestions } = DashboardState || {}
    const { learn_again, practice, revise } = topSuggestions || {}
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box className={styles.suggestion_wrap}>
            <Box className={styles.suggestion_wrap_tab}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="suggestion_wrap_tab_box">
                    <Tab icon={<AssignmentIcon />} iconPosition="start" label="Practice" {...a11yProps(0)} />
                    <Tab icon={<LocalLibraryIcon />} iconPosition="start" label="Revise" {...a11yProps(1)} />
                    <Tab icon={<MenuBookIcon />} iconPosition="start" label="Learn Again" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <Box className={styles.suggestion_wrap_tab_content}>
                <TabPanel value={value} index={0} className="suggestion_tab_layout_outer">
                    <SuggestionTab 
                        TabTitle="Make sure you practice these" 
                        TabData={practice} 
                        message="Check our suggestions on what you need to Learn Again or Revise first. Take more practice sessions for this to be populated for you!"
                        clickable={true} />
                </TabPanel>
                <TabPanel value={value} index={1} className="suggestion_tab_layout_outer">
                    <SuggestionTab 
                        TabTitle="Make sure you revise these" 
                        message="Just revise the concepts as per our analysis or the ones you are not confident about. We do not have anything at the Subtopic level to show over here based on your performance so far."
                        TabData={revise} />
                </TabPanel>
                <TabPanel value={value} index={2} className="suggestion_tab_layout_outer">
                    <SuggestionTab 
                        TabTitle="Make sure you learn about these again" 
                        message="We do not have anything to suggest to you for learning. May be after you have done more practice sessions. For now, focus on the concept-wise analysis, and anything that you have had doubts about during your last few sessions."
                        TabData={learn_again} />
                </TabPanel>
            </Box>
        </Box>
    )
}

export default Suggestions;