import React from 'react'
import { Box, Button, Modal, Typography } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import styles from "./Overlays.module.scss"
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useNavigate } from 'react-router-dom';
import { SiteMap } from '../../Routes';

const UpgradeModal = ({ open, handleClose = () => { } }) => {
    const navigate = useNavigate()
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="confirmation_modal"
        >
            <Box className={`${styles.pause_session_modal} ${styles.upgrade_form_modal}`}>
                <Button className={styles.close_modal} onClick={handleClose}><HighlightOffOutlinedIcon /></Button>
                <Box className={styles.blurModel}>
                    <Box className={styles.upgrade_m}>
                        <Typography className={styles.modal_title}>
                            You have completed the one free practice session.
                        </Typography>
                        <Typography className={styles.modal_title}>
                            To get the full version, click below.
                        </Typography>
                    </Box>
                    <Box className='bottom_btn_box display-flex justify-content-center'>
                        <Button className={styles.starButton} onClick={() => { navigate(SiteMap.PaymentPage.path); handleClose() }}><StarBorderIcon />Upgrade to the paid version</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default UpgradeModal;